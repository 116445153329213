import { Address } from "viem";
import { VaultInterface } from "../constants/interfaces";
import { VaultsSnapshot } from "../atoms";
import { fixed2Decimals, getUSDPrice } from "./helpers";

export interface TokenBalance {
  address: Address;
  amount: string;
  symbol: string;
  priceUsd: number;
}

export const getTokenBalances  = (
  vaults: VaultInterface[],
  vaultsData: VaultsSnapshot | null
): TokenBalance[] => {
  const res: TokenBalance[] = [];
  if(vaultsData === null || vaults.length === 0) {
    return res;
  }
  vaults.forEach((vault)=> {
    const data = vaultsData[vault.address];
    if(data) {
      res.push({
        symbol: vault.uSymbol,
        address: vault.underlying,
        amount: fixed2Decimals(data.underlyingBalance, vault.uDecimals, 4),
        priceUsd: Number(getUSDPrice(data.oraclePrice, data.underlyingBalance, vault.uDecimals))
      })
    }
  });
  return res;
}