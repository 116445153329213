import { Center, Img, Modal, ModalContent, ModalOverlay, UseModalProps } from "@chakra-ui/react";
import { Supply } from "./Supply";
import { LendingVault } from "../utils/lending";
import { getTokenImage } from "../utils/image";
import { useState } from "react";
import { ApproveSpend } from "./ApproveSpend";
import { SupplySend } from "./SupplySend";

interface ModalSupplyProps extends UseModalProps {
  lendingVault: LendingVault
}

export type TxStep = 'input' | 'approval' | 'submit';

export function ModalSupply({
  isOpen,
  onClose,
  lendingVault
}: ModalSupplyProps) {
  const [amount, setAmount] = useState<string>("0");
  const [activeStep, setActiveStep] = useState<TxStep>("input");

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
      <ModalOverlay/>
      <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getTokenImage(lendingVault.symbol)}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        {activeStep === 'input' && <Supply
          {...lendingVault}
          amountSupply={amount}
          onAmountChange={(amt)=> setAmount(amt)}
          sendApproval={(amount)=> {
            setAmount(amount);
            setActiveStep('approval');
          }}
          sendSupply={(amount)=> {
            setAmount(amount);
            setActiveStep('submit');
          }}
        />}
        {activeStep === 'approval' && <ApproveSpend
          amount={amount}
          tokenAddress={lendingVault.underlying}
          spender={lendingVault.address}
          symbol={lendingVault.symbol}
          decimals={lendingVault.uDecimals}
          onCancel={()=> onClose()}
          onProceed={()=> setActiveStep('submit')}
          ctaText={`Supply to Pool`}
        />}

        {activeStep === 'submit' && <SupplySend
          vaultAddress={lendingVault.address}
          amount={amount}
          symbol={lendingVault.symbol}
          decimals={lendingVault.uDecimals}
          exchangeRate={lendingVault.exchangeRate}
          onCancel={()=> onClose()}
        />}
      </ModalContent>
    </Modal>
  )
}