import { Address, zeroAddress } from "viem";
import LoadingGif from '../images/loading.gif';
import { Box, Button, Icon, Img, Link, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useAccount, useContractRead, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { useEffect, useState } from "react";
import { divFixed, encodeDexData, fixed2Decimals, getReadableError, shortAddress } from "../utils/helpers";
import { FiExternalLink } from "react-icons/fi";
import { PiCheckCircleFill, PiWarningCircleBold, PiWarningOctagonDuotone } from "react-icons/pi";
import {getAbi, getBlockExplorer, getEventsAPIChainSlug, getContractAddress } from "../constants";
import { useAtom } from "jotai";
import { atomPendingTxWrite, atomPendingHashWrite } from "../atoms";
import moment from "moment";
import { BorrowEvent, MintEvent } from "../utils/events/interfaces";

interface BorrowSendProps {
  vaultAddress: Address;
  borrowVaultAddress: Address;
  partialAmount: string;
  borrowAmount: string;
  outAmount: string;
  outDecimals: number;
  outSymbol: string;
  fee: string;
  dexId: string;
  quoteJson: any;
  onCancel: () => void;
}

export function BorrowSend({
  vaultAddress,
  borrowVaultAddress,
  partialAmount,
  borrowAmount,
  outAmount,
  outDecimals,
  outSymbol,
  fee,
  quoteJson,
  dexId,
  onCancel
}: BorrowSendProps) {

  const { chain } = useNetwork();
  const [, setTx] = useAtom(atomPendingTxWrite);
  const [, setHash] = useAtom(atomPendingHashWrite);
  const { address } = useAccount();
  const [dexData, setDexData] = useState<null | string>(null);
  const [isTxPrepared, setIsTxPrepared] = useState(false);

  useEffect(()=> {
    const getDexData = async()=> {
      const compltrollerAddress = getContractAddress({chainId: chain?.id, contractName: 'Comptroller'});
      let encoded = await encodeDexData(chain?.id, dexId, fee, quoteJson, borrowVaultAddress, compltrollerAddress ? compltrollerAddress: borrowVaultAddress);
      setDexData(encoded);
    }
    getDexData();
  }, []);
  
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    enabled: !isTxPrepared, // this hook can re run due to dexData in state. Manually created flag to make it run only once in modal's lifecycle.
    address: dexData ? borrowVaultAddress : undefined,
    abi: getAbi({ chainId: chain?.id, abiFile: 'Vault' }),
    functionName: 'borrowAndBuy',
    args: [
      vaultAddress,
      borrowAmount,
      partialAmount,
      outAmount,
      dexData
    ],
    onSuccess: () => {
      setIsTxPrepared(true);
    }
  });

  const { data, error, isLoading, isError, write } = useContractWrite(config);

  useEffect(() => {
    if (write) {
      write();
    }
  }, [write]);

  // ToDo - Read exchange rate from VaultSnapshot.
  const { data : exchangeRate, isError: exError } : { data : any, isError: boolean } = useContractRead({
    abi: getAbi({ chainId: chain?.id, abiFile: 'Vault' }),
    address: vaultAddress,
    functionName: 'exchangeRateCurrent',
    args: []
  });
  
  useEffect(() => {
    if(data?.hash) {
      if(getEventsAPIChainSlug(chain?.id) === "") {
        setHash(data.hash);
      } else if(exchangeRate && !exError) {
        const mintTokens = divFixed(outAmount, fixed2Decimals(exchangeRate, 18));

        const eventData2: MintEvent = { // wmatic wala token
          mintAmount: outAmount,
          mintTokens: mintTokens,
          minter: address ? address : zeroAddress
        }
        const eventData: BorrowEvent = { 
          borrower: address ? address : zeroAddress,
          borrowAmount: borrowAmount,
          accountBorrows: "",
          totalBorrows: "",
          paidAmount: partialAmount,
          boughtAsset: vaultAddress,
          boughtAssetAmount: outAmount
        }
  
        setTx([
          {
            eventAddress: vaultAddress,
            eventName: "Mint", 
            transactionHash: data?.hash,
            timestamp: moment().unix().toString(),
            __typename: "",
            data: "",
            eventData: eventData2
          },
          {
            eventAddress: borrowVaultAddress,
            eventName: "Borrow",
            transactionHash: data?.hash,
            timestamp: moment().unix().toString(),
            __typename: "",
            data: "",
            eventData: eventData
          }
        ]);
      }
    }
  }, [data?.hash, exchangeRate])

  const { data: txData, isLoading: isTxLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <Box textAlign='center'>
      {(isLoading || isTxLoading) && <Img
        display='inline-block'
        height='96px'
        width='96px'
        src={LoadingGif}
      />}

      {(isPrepareError || isError) && <VStack spacing='20px' mt='20px'>
        <Icon as={PiWarningOctagonDuotone} fontSize={'5xl'} color='orange.300' />
        <Box>
          <Text color='primary.900' textStyle='body-bold-1'>
            Failed to initiate transaction
          </Text>
          <Text wordBreak='break-word' color='orange.400' textStyle='body-bold-3'>
            {(prepareError || error)?.name}
          </Text>
          <Text wordBreak='break-word' color='primary.900' mt={4} textStyle='body-2'>
            {getReadableError((prepareError || error)?.message)}
          </Text>
        </Box>
        <Button onClick={() => onCancel()}>Close</Button>
      </VStack>}

      {isLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting Txn to initiate.
        </Text>
        <Text textStyle="body-bold-3" color="gray.500">
          Please go to your wallet and <br /> allow this transaction to continue.
        </Text>
      </Box>}

      {isTxLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting transaction to complete.
        </Text>
        <Link
          textStyle="body-bold-3"
          color="gray.500"
          isExternal
          href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}
        >
          View in Explorer {shortAddress(data?.hash)} <Icon as={FiExternalLink} />
        </Link>
      </Box>}

      {isSuccess && <VStack spacing="32px" mt='20px'>
        <Box>
          <Text textStyle='body-bold-1' color='primary.900'>{fixed2Decimals(outAmount, outDecimals)} {outSymbol}</Text>
          {txData?.status === 'success' ? <Text textStyle='body-bold-3' color='green.500'>
            Invested Successfully <Icon as={PiCheckCircleFill} />
          </Text> : <Text textStyle='body-bold-3' color='red.500'>
            Txn Reverted <Icon as={PiWarningCircleBold} />
          </Text>}
        </Box>
        <Box w='100%'>
          <SimpleGrid
            columns={2} spacing='20px'
            borderBottom='1px solid'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Vault Name
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              {outSymbol} Vault
            </Text>
          </SimpleGrid>
          <SimpleGrid
            columns={2} spacing='20px'
            borderBottom='1px solid'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Fees Paid
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              0.00
            </Text>
          </SimpleGrid>
          <SimpleGrid
            columns={2} spacing='20px'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Txn Hash
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              <Link isExternal href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}>
                {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
              </Link>
            </Text>
          </SimpleGrid>
        </Box>
        <Box w='100%'>
          {txData?.status === 'success' ? <Button
            size="lg"
            w="100%"
            colorScheme='primary'
            mb="16px"
            onClick={()=> onCancel()}
          >Done</Button> :
          <Button
            size="lg"
            w="100%"
            onClick={()=> onCancel()}
          >
            Close
          </Button>}
        </Box>
      </VStack>}
    </Box>
  )
}