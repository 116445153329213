import { Flex } from "@chakra-ui/react";
import { WeFiBalance } from "../../components/stake/WeFiBalance";
import { Tabs } from "../../components/stake/Tabs";
import { NotConnected } from "../../components/NotConnected";
import { useAccount, useNetwork } from "wagmi";
import { UnsupportedNetwork } from "../../components/UnsupportedNetwork";

export default function VeStakingPage() {
  const {isConnected} = useAccount();
  const {chain} = useNetwork();
  if (!isConnected) {
    return <NotConnected/>
  } else if([80001, 137].includes(chain ? chain.id : 0)) {
      return  <Flex direction='column' gap='40px'>
      <WeFiBalance/>
      <Tabs/>
  </Flex>
  } else {
      return <UnsupportedNetwork
        text1={`WEFI Staking is available on Polygon Network.`}
        text2='Please switch to Polygon Network.'
      />
  }
 
}