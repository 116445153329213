import { Box, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import ActiveInvestments from "../ActiveInvestments";
import { useAccount } from "wagmi";
import { NotConnected } from "../NotConnected";
import { VaultInterface } from "../../constants/interfaces";
import { VaultsSnapshot, atomUserEvents } from "../../atoms";
import ActiveInvestmentsCompact from "../ActiveInvestmentsCompact";
import { fonts } from "../../theme/fonts";
import TransactionHistory from "../TransactionHistory";
import { useMemo } from "react";
import { getTransactions } from "../../utils/events";
import { useAtom } from "jotai";


export function PositionTabs ({
  vaults, snapshot, chainId
}: {vaults: VaultInterface[], snapshot: VaultsSnapshot, chainId: number }) {
  const {isConnected} = useAccount();
  const [events] = useAtom(atomUserEvents);
  const txns = useMemo(()=> getTransactions(vaults, events), [vaults, events]);
  return <Tabs
    isLazy={true}
    mt={5}
    position="relative"
    variant='enclosed'
    colorScheme="primary"
    borderColor='gray.150'
    color='gray.400'
  >
    <TabList 
      borderRadius='16px'
    >
      <Tab textStyle='body-bold-3' fontSize='12px'>OPEN POSITIONS</Tab>
      <Tab textStyle='body-bold-3' fontSize='12px'>TRANSACTION HISTORY</Tab>
    </TabList>
    {/* <TabIndicator
      height="2px"
      bg="blue.500"
      borderRadius="32px"
    /> */}
    <TabPanels border='1px' borderColor='gray.150' borderBottomRadius='16px'>
      <TabPanel>
        {isConnected ? <ActiveInvestmentsCompact 
          vaults={vaults}
          vaultsSnapshotData={snapshot}
        /> : <NotConnected/>}
      </TabPanel>
      <TabPanel>
        <Box 
          maxHeight='250'
          overflowY='auto'
        >
          <TransactionHistory
            txns={txns}
            chainId={chainId}
            isCompact
          />
        </Box>
      </TabPanel>
    </TabPanels>
  </Tabs>
}
