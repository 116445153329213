import { Icon } from "@chakra-ui/react";
import { PiPlusCircleBold } from "react-icons/pi";
import { useAccount } from "wagmi";

interface AddTokenProps {
  token: {
    address: string;
    decimals: number;
    symbol: string;
  }
}
export default function AddToken(props: AddTokenProps) {
  const {isConnected} = useAccount();
  const handleTokenAdd = ()=> {
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: props.token.address, // The address of the token.
          symbol: props.token.symbol, // A ticker symbol or shorthand, up to 5 characters.
          decimals: props.token.decimals // The number of decimals in the token.
          // image: tokenImage, // A string URL of the token logo.
        },
      },
    }).then((status: any) => {
      console.log("Token add res", status);
    }).catch((err: any)=> {
      console.log("error adding token to wallet", err);
    });    
  }
  if (isConnected && window && window.ethereum) {
    return <Icon title={`Add ${props.token.symbol} to your wallet`} color='gray.400' onClick={()=> handleTokenAdd()} cursor='pointer' as={PiPlusCircleBold} />
  } else {
    return null;
  }
}