import { Center, Img, Modal, ModalContent, ModalOverlay, UseModalProps } from "@chakra-ui/react";
import { getTokenImage } from "../utils/image";
import { useState } from "react";
import { Withdraw } from "./Withdraw";
import { WithdrawSend } from "./WithdrawSend";
import { Address } from "viem";

export interface WithdrawVault {
  address: Address;
  balanceTokens: string,
  balanceAmount: string,
  uSymbol: string,
  uDecimals: number,
  exchangeRate: string,
}

interface ModalWithdrawProps extends UseModalProps {
  vault: WithdrawVault
}

export type TxStep = 'input' | 'approval' | 'submit';

export function ModalWithdraw({
  isOpen,
  onClose,
  vault
}: ModalWithdrawProps) {
  const [amount, setAmount] = useState<string>("0");
  const [tokens, setTokens] = useState<string>("0");
  const [activeStep, setActiveStep] = useState<TxStep>("input");

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
      <ModalOverlay/>
      <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getTokenImage(vault.uSymbol)}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        {activeStep === 'input' && <Withdraw
          {...vault}
          sendWithdraw={(amount, tokens)=> {
            setAmount(amount)
            setTokens(tokens);
            setActiveStep('submit');
          }}
        />}
        {activeStep === 'submit' && <WithdrawSend
          vaultAddress={vault.address}
          balanceTokens={tokens}
          balanceAmount={amount}
          symbol={vault.uSymbol}
          decimals={vault.uDecimals}
          onCancel={()=> onClose()}
        />}
      </ModalContent>
    </Modal>
  )
}