import {
  Box,
  useColorModeValue,
  useDisclosure,
  Drawer,
  DrawerContent,
  Text,
} from '@chakra-ui/react';

import SideBar from './components/SideBar';
import Header from './components/Header';
import { Outlet, useLocation } from 'react-router-dom';
import useSnapshotRead from './hooks/useSnapshotRead';
import {atomMenuCollapsed} from './atoms';
import { useEffect } from 'react';
import { useAtom } from 'jotai';

export default function AppLayout() {
  useSnapshotRead();
  const {isOpen, onOpen, onClose}= useDisclosure();
  const [collapsed, setCollapsed] = useAtom(atomMenuCollapsed);
  const {pathname} = useLocation();

  useEffect(() => {
    if(pathname.includes('leverage-vaults')) {
      setCollapsed(true);
    }
  }, [pathname]);

  return (
    <Box minH='100vh' bg={useColorModeValue('white', 'gray.900')}>
      <SideBar
        setCollapsed={(v)=> setCollapsed(v)}
        pathname={pathname}
        collapsed={collapsed}
        mobileView={false}
        onClose={()=> onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'>
        <DrawerContent>
          <SideBar
            pathname={pathname}
            collapsed={false}
            mobileView={true}
            onClose={onClose}
          />
        </DrawerContent>
      </Drawer>
      <Box bg='gray.100'>
        <Header collapsed={collapsed} onOpen={onOpen} />
      </Box>
      <Box
        bg={useColorModeValue('white', 'gray.700')}
        ml={{ base: 0, md: collapsed ? 20 : 60 }}
        px={{ base: 5, md: collapsed ? 5 :128 }}
        py='24px'
        transition='1s ease'
      >
        <Outlet/>
        <Box mt="130px" textAlign="right" opacity="0.6399999856948853">
          <Text textStyle="body-3">Version 2.0.1</Text>
        </Box>
      </Box>
    </Box>
  )
}