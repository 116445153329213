import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0x9358138473ea07ad3954a2f7af0872590cc0b13e",
    decimals: 8,
    underlying: "0xedad65e4a6fc6295fd298c58d8ecee8eb522a192",
    uSymbol: "USDT",
    uName: "USDT",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0x4418c7f99b5c499846665b4691a436ceb38f87ae"]
  },
 
  {
    address: "0x4418c7f99b5c499846665b4691a436ceb38f87ae",
    decimals: 8,
    underlying: "0x1996aadd7b156bf329cac574a7182f35a885c131",
    uSymbol: "WETH",
    uName: "WETH",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x9358138473ea07ad3954a2f7af0872590cc0b13e"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  }
];

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0xB0e51dE0F33894ebC367d133aE026Ac9D4B91a2C",
  'Comptroller': "0xEA8256b4df273450A31eC52f5ecCbF89ce3b5622"
}

export const defaultTradePair  = "usdt-weth";


export const blockExplorer = 'https://goerli.explorer.zksync.io/';

export const blocksPerDay=7142;

export const DexQuoters : DexQuoter[] = [
  {
    id: '1',
    name: 'Mute.io',
    slug: 'mute-io',
    type: 'Contract',
    address: "0x96c2Cf9edbEA24ce659EfBC9a6e3942b7895b5e8",
    functionName: 'getAmountOut'
  }
]

export const eventsApiChainSlug='zksync-testnet';

export const faucetURL = "https://portal.zksync.io/transaction/zksync/era/faucet";
export const faucetAddress = "0xd6030E6f773df42ABa6EAe12833003fB2F21d02c";