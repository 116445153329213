import { Box, Button, SimpleGrid, Text } from '@chakra-ui/react';
import { LoanPositions } from '../utils/loanPositions';
import { useState } from 'react';
import { ModalRepay } from './ModalRepay';

export default function OpenLoanPositionsStats({
  data
}: {data: LoanPositions}) {
  const [open, setOpen] = useState(false);
  return (
    <SimpleGrid
      px='23px'
      py='24px'
      columns={[2, null, 5]}
      borderRadius='20px'
      border='1px solid'
      borderColor='gray.150'
    > 
    <StatItem
      title="Total Borrowed"
      value={data.totalBorrowedUsd ? `$${data.totalBorrowedUsd.toFixed(2)}` : '---'}
    />
    <StatItem
      title="Total Repaid"
      value={data.totalRepaidUsd ? `$${data.totalRepaidUsd.toFixed(2)}` : '---'}
    />
    <StatItem
      title="Interest Payable"
      value={data.totalInterestUsd ? `$${data.totalInterestUsd.toFixed(2)}` : '---'}
    />
    <StatItem
      title="Total Outstanding"
      value={`$${data.totalOutstandingUsd.toFixed(2)}`}
    />
    <Box textAlign={{base: 'left', md:'right'}} mt={{base: '10px', md: '0px'}}>
      <Button 
        isDisabled={data.open.length === 0}
        colorScheme='primary'
        size='sm'
        onClick={()=> setOpen(true)}
        px='20px'
      >Repay</Button>
    </Box>
    {open !== null && <ModalRepay
      isOpen={open}
      onClose={()=> setOpen(false)}
      loanPositions={data.open}
    />}
    </SimpleGrid>
  )
}

function StatItem ({
  title, value
}: {title: string, value: string}) {
  return <Box>
    <Text textStyle='body-3' color='gray.500'>{title}</Text>
    <Text textStyle='subheading-1' color='primary.900' mt="8px">
      {value}
    </Text>
  </Box>
}