import {
  Box,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  HStack,
  Button,
  Icon
} from '@chakra-ui/react';
import ImageWEFI from '../images/tokens/wefi.svg';
import { IconInfo } from './CustomIcons';
import StackedImages from './StackedImages';
import { VaultInterface } from '../constants/interfaces';
import { VaultsSnapshot, atomDefaultChainID, atomWeFiPrice } from '../atoms';
import { LendingVault, getLendingVaults } from '../utils/lending';
import { formatVolume } from '../utils/helpers';
import { getRewardTokenIcon, getTokenImage } from '../utils/image';
import { useState } from 'react';
import { ModalSupply } from './ModalSupply';
import { useAccount, useNetwork } from 'wagmi';
import { useAtom } from 'jotai';
import AddToken from './AddToken';

export default function LendingPools({
  vaultsSnapshotData,
  vaults,
  blocksPerDay
}:
{
  vaults: VaultInterface[];
  vaultsSnapshotData: VaultsSnapshot | null;
  blocksPerDay: number
}) {
  const {chain} = useNetwork();
  const [defaultChainId] = useAtom(atomDefaultChainID);
  const {isConnected} = useAccount();
  const [wefiPrice] = useAtom(atomWeFiPrice);
  const [activeVault, setActiveVault] = useState<null | LendingVault>(null);
  const lendingVaults = getLendingVaults(vaults, vaultsSnapshotData, blocksPerDay, (chain?.id || defaultChainId) === 50 ? 1 : wefiPrice);
	return (
		<Box>
			<Text textStyle='heading-3' color='primary.900'>
				ALL POOLS
			</Text>
			<TableContainer>
				<Table>
					<Thead textStyle='body-2'>
						<Tr>
							<Th>Pool</Th>
							<Th>Markets</Th>
							<Th>APY</Th>
							<Th>Rewards</Th>
							<Th>TVL</Th>
							<Th>Utilization</Th>
              {isConnected && <Th position='sticky' right='0px' bg='white' zIndex='0'></Th>}
						</Tr>
					</Thead>
					<Tbody>
						{lendingVaults.map((row, i)=> (
              <LendingPoolsItem
                key={i}
                {...row}
                isConnected={isConnected}
                onSuplyClick={()=> setActiveVault(row)}
                chainId={chain?.id}
              />
            ))}
					</Tbody>
				</Table>
			</TableContainer>
      {activeVault !== null && <ModalSupply
        isOpen={activeVault !== null}
        onClose={()=> setActiveVault(null)}
        lendingVault={activeVault}
      />}
		</Box>        
	)
}

interface LendingPoolsItemProps extends LendingVault {
  isConnected: boolean,
  onSuplyClick: () => void,
  chainId: number | undefined
}

function LendingPoolsItem(props:LendingPoolsItemProps) {
  return (
    <Tr>
      <Td>
        <HStack>
          <AddToken
            token={{
              address: props.underlying,
              symbol: props.symbol,
              decimals: props.uDecimals
            }}
          />
          <Image h='24px' w='24px' src={getTokenImage(props.symbol)} />
          <Text>{props.symbol} Pool</Text>
        </HStack>
      </Td>
      <Td>
        <StackedImages
          images={props.marketsSymbols.map((symbol)=> getTokenImage(symbol))}
          leftUnits='-10px'
          h='24px'
          w='24px'
          border='2px solid white'
          borderRadius='100px'
        />
      </Td>
      <Td>
        {props.poolApy ? `${props.poolApy.toFixed(2)}%` : '---'}
      </Td>
      <Td>
        <HStack>
          {props.rewardsApr && props.rewardsApr.value && <Image src={getRewardTokenIcon(props.chainId)} h='16px' w='16px'/>}
          <Text color='green.500'>{props.rewardsApr && props.rewardsApr.value ? `${props.rewardsApr.value.toFixed(0)}${props.rewardsApr.suffix}` : '---'}</Text>
        </HStack>
      </Td>
      <Td>
        {props.tvlUsd ? `$${formatVolume(props.tvlUsd)}` : '---'}
      </Td>
      <Td>
        {props.utilization ? `${props.utilization.toFixed(2)}%` : '---'}
      </Td>
      {props.isConnected && <Td position='sticky' right='0px' bg='white' zIndex='0'>
          <Button
            onClick={()=> props.onSuplyClick()}
            size='sm'
            variant='outline'
            colorScheme='primary'
          >Supply</Button>
      </Td>}
    </Tr>
  )
}