import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';

import { ProtocolStats } from '../../components/ProtocolStats';
import { RewardStats } from '../../components/RewardStats';
import LendingPositions from '../../components/LendingPositions';
import CrossChainBalance from '../../components/CrossChainBalance';
import LendingPools from '../../components/LendingPools';

import { useAccount, useNetwork } from 'wagmi';
import { getBlocksPerDay, getVaults, protocolSupportedChainIds } from '../../constants';
import { atomDefaultChainID, atomVaultsSnapshot } from '../../atoms';
import { UnsupportedNetwork, UnsupportedNetworkProtocolOnly } from '../../components/UnsupportedNetwork';
import Faucet from '../../components/Faucet';
import XdcGas from '../../components/XdcGas';
import { XdcBanner } from '../../components/XdcBanner';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function LendingVaultsPage() {
  const [vaultsSnapshot] = useAtom(atomVaultsSnapshot);
  const [defaultChainId, setDefaultChainId] = useAtom(atomDefaultChainID);
  const {chain,} = useNetwork();
  const {isConnected} = useAccount();

  const [searchParams] = useSearchParams();
  const blocksPerDay = getBlocksPerDay(isConnected ? chain?.id: defaultChainId);
  const vaults = getVaults(isConnected ? chain?.id: defaultChainId);

  useEffect(()=> {
    let c = searchParams.get("chain");
    if(!isConnected && c !== null && protocolSupportedChainIds.includes(Number(c))) {
      setDefaultChainId(Number(c));
    }
  }, [])

  if(isConnected) {
    if(chain?.unsupported) {
      return <UnsupportedNetwork/>
    } else if(!protocolSupportedChainIds.includes(chain ? chain.id : 0)) {
      return <UnsupportedNetworkProtocolOnly/>
    }
  } else if(!protocolSupportedChainIds.includes(defaultChainId)) {
    return <UnsupportedNetworkProtocolOnly/>
  }

  return (
    <Flex direction='column' gap='80px'>
      {/* <VoyageBanner chainId={isConnected ? chain?.id : defaultChainId}/> */}
      <XdcBanner chainId={isConnected ? chain?.id : defaultChainId}/>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Ld201MpAAAAAM2lk8z3T-DwSKu8RIpPNq3Zjdj9"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >
        { isConnected && chain?.id === 50 && <XdcGas/>}
      </GoogleReCaptchaProvider>
      { isConnected && (chain?.testnet || chain?.id === 51) && <Faucet/> }
      <Flex direction='column' bgColor='gray.100'  borderRadius='16px'>
        <ProtocolStats vaults={vaults} vaultsSnapshot={vaultsSnapshot}/>
        {isConnected && <RewardStats/>}
      </Flex>
      {isConnected && <LendingPositions
        vaultsSnapshotData={vaultsSnapshot}
        vaults={vaults.filter((item)=> item.lendEnabled)}
        blocksPerDay={blocksPerDay}
      />}
      { isConnected && <CrossChainBalance/> }
      <LendingPools
        vaultsSnapshotData={vaultsSnapshot}
        vaults={vaults}
        blocksPerDay={blocksPerDay}
      />
    </Flex>
  )
}