import { Center, Divider, Flex, useBreakpointValue } from "@chakra-ui/react";
import PortfolioStatsBlock from "./PortfolioStatsBlock";
import { useAtom } from "jotai";
import { atomRewardsAccrued } from "../atoms";
import { fixed2Decimals } from "../utils/helpers";
import { useNetwork } from "wagmi";

export default function PortfolioStats({
  totalValue, totalBorrows, pnlUsd
}: {totalValue: number, totalBorrows: number, pnlUsd: number}) {
  const [rewardsAccrued] = useAtom(atomRewardsAccrued);
  const {chain} = useNetwork(); 
  let isMobile = useBreakpointValue({base: true, md: false});
  return (
    <Flex 
      gap='24px' 
      flexDirection={useBreakpointValue({base: 'column', md: 'row'})}
      px={{base: '20px', md:'56px'}}
      py='21px'
    >
      <PortfolioStatsBlock
        w={{base: 'full', md:'33%', sm: '100%'}}
        title='Overall Position Value'
        value={`$${totalValue.toFixed(2)}`}
      />
      <Center height={isMobile ? '0px': '50px'}>
        <Divider orientation={isMobile ? 'horizontal': 'vertical'} />
      </Center>
      <PortfolioStatsBlock
        w={{base: 'full', md:'33%', sm: '100%'}}
        title='Overall Position P&L'
        value={pnlUsd ? pnlUsd.toFixed(2) : "---" }
        isIndicative={pnlUsd? true : false}
        display="flex"
        flexDirection="column"
        alignItems={{base: 'flex-start', md: 'center'}}
      />
      <Center height={isMobile ? '0px': '50px'}>
        <Divider orientation={isMobile ? 'horizontal': 'vertical'} />
      </Center>
      <PortfolioStatsBlock
        w={{base: 'full', md:'33%', sm: '100%'}}
        title='Rewards Earned'
        value={`${fixed2Decimals(rewardsAccrued, 18, 2)} ${chain?.id === 50 ? 'FXD' : 'WEFI'}`}
        display="flex"
        flexDirection="column"
        alignItems={{base: 'flex-start', md: 'flex-nd'}}
      />
    </Flex>
  )
}