import { useEffect, useRef, useState } from "react";
import { DexQuoter, DexSlug } from "../constants/interfaces";
import { Address, useBlockNumber } from "wagmi";
import { useInterval } from "./useInterval";
import { error } from "console";
import { getXswapRoute } from "../utils/xswapQuotes";
import { slippage } from "../constants/xdc";
import { isEqual } from "../utils/helpers";

export interface QuoteApiParams {
  tokenIn: string;
  tokenOut: string;
  amountIn: string;
  chainSlug: string;
  recipient: Address;
  apiQuoters: DexQuoter[];
}

export interface ApiQuote {
  slug: DexSlug;
  status: 'success' | 'error'
  quote: string;
  quoteJson: any;
}
export function useApiQuoters({
  params,
  onSuccess
} : {
  params: QuoteApiParams,
  onSuccess: (quote: ApiQuote[]) => void
}) {
  const [isLoading, setLoading] = useState(false);
  const [signal, setSignal] = useState<null | AbortSignal>(null);
  const latestAmountIn = useRef(params.amountIn);
  
  // useBlockNumber({
  //   enabled: params.apiQuoters.length > 0,
  //   watch: params.apiQuoters.length > 0,
  //   onBlock: ()=> {
  //     // console.log('fetching quotes on block');
  //     if(!isLoading) {
  //       fetchQuotes();
  //     }
  //   }
  // });

  useEffect(()=> {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setSignal(signal);
    return () => {
      abortController.abort();
    };
  }, [])

  useEffect(()=> {
    // console.log('fetching on amount change');
    latestAmountIn.current = params.amountIn;
    fetchQuotes();
    
  }, [params.amountIn, params.tokenIn, params.tokenOut]);

  
  
  const fetchQuotes = ()=> {
    if(params.apiQuoters.length === 0) {
      // console.log('No Api Quoters');
      setLoading(false);
      return;
    }
    if (Number(params.amountIn) ===0) {
      // console.log('Not fetching.amount is zero');
    };

    const amountIn = params.amountIn;

    setLoading(true);
    const quotes: ApiQuote[] = [];
    params.apiQuoters.forEach((quoter) => {
      const api = getApi(quoter.slug);
      const headers:any = {};
      if(quoter.slug === 'xswap-smart-order'){
        getXswapRoute({tokenIn: params.tokenIn, tokenOut: params.tokenOut, amountIn: params.amountIn, recipient: params.recipient, slippage: slippage})
        .then((resJson: any) => {
          const quoteRes = processApiResponse(quoter.slug, resJson);
          if (!quoteRes.quote) {
            throw new Error("Xswap quote response error");
          } else {
            quotes.push(quoteRes);
          }
        })
        .catch((err)=> {
          if(err.name !== 'AbortError') {
            quotes.push({
              slug: quoter.slug,
              status: 'error',
              quote: "0",
              quoteJson: null
            })
          }
          console.log("error while fetching xswap quote", quoter.slug, err);
        }).finally(()=> {
          if(quotes.length === params.apiQuoters.length) {
            if(isEqual(amountIn, latestAmountIn.current)) {
              onSuccess(quotes);
              setLoading(false);
            } else {
              // console.log('skip quote update for stale input', amountIn, 'current', latestAmountIn.current);
            }
          }
          
        })
      } else {
        if (quoter.slug === 'kyberswap-aggregator') {
          headers['x-client-id'] = 'wefi.xyz';
        }
  
        fetch(api, {
          headers,
          signal
        })
        .then((res)=> res.json())
        .then((resJson) => {
          const quoteRes = processApiResponse(quoter.slug, resJson);
          if (!quoteRes.quote) {
            throw new Error("Api response error");
          } else {
            quotes.push(quoteRes);
          }
        })
        .catch((err)=> {
          if(err.name !== 'AbortError') {
            quotes.push({
              slug: quoter.slug,
              status: 'error',
              quote: "0",
              quoteJson: null
            })
          }
          console.log("error while fetching api quote", quoter.slug, err);
        }).finally(()=> {
          if(quotes.length === params.apiQuoters.length) {
            if(isEqual(amountIn, latestAmountIn.current)) {
              onSuccess(quotes);
              setLoading(false);
            } else {
              console.log('skip quote update for stale input', amountIn, 'current', latestAmountIn.current);
            }
           
          }
          
        })
      }
      
    })
  }

  const getApi = (slug: DexSlug): string => {
    if(slug === 'kyberswap-aggregator') {
      return `https://aggregator-api.kyberswap.com/${params.chainSlug}/api/v1/routes?tokenIn=${params.tokenIn}&tokenOut=${params.tokenOut}&amountIn=${params.amountIn}&saveGas=false&gasInclude=true&source=wefi.xyz`
    } 
    // else if (slug === 'xswap-smart-order') {
    //   return `https://api.wefi.xyz/xswap-routes?tokenIn=${params.tokenIn}&tokenOut=${params.tokenOut}&amountIn=${params.amountIn}&recipient=${params.recipient}&slippage=5000`
    // }
     else {
      return '';
    }
  }

  const processApiResponse = (slug: DexSlug, resJson: any) : ApiQuote => {
    const data: ApiQuote = {
      slug: slug,
      status: 'error',
      quote: "0",
      quoteJson: null
    }
    if(slug === 'kyberswap-aggregator') {
      if(resJson.code === 0) {
        data.status = 'success';
        data.quote = resJson.data.routeSummary.amountOut;
        data.quoteJson = resJson.data;
      }
    } else if (slug === 'xswap-smart-order') {
      data.status = 'success';
      data.quote= resJson.quoteAmount;
      data.quoteJson = {
        routes: resJson.routes,
        calldata: resJson.calldata
      }
    }
    return data;
  } 
 
  useInterval(fetchQuotes, 45000);
  
  return {isLoading, fetchQuotes}
}