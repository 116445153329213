import { VaultsSnapshot } from "../atoms";
import { VaultInterface } from "../constants/interfaces";
import { addFixed, getUSDPrice, isZero } from "./helpers";

interface ProtocolStats {
  tvlUsd: number;
  borrowedUsd: number;
}

export function getProtocolStats(
  vaults: VaultInterface[],
  snapshot: VaultsSnapshot | null
): ProtocolStats {
  const stats: ProtocolStats = {
    tvlUsd: 0,
    borrowedUsd: 0
  }
  if(vaults.length > 0 && snapshot !== null) {
    vaults.forEach((vault)=> {
      const data = snapshot[vault.address.toLocaleLowerCase()];
      if(data) {
        const tvl = addFixed(data.totalCash, data.totalBorrows);
        if(!isZero(tvl)) {
          stats.tvlUsd += Number(getUSDPrice(data.oraclePrice, tvl, vault.uDecimals));
        }
        if(!isZero(data.totalBorrows)) {
          stats.borrowedUsd += Number(getUSDPrice(data.oraclePrice, data.totalBorrows, vault.uDecimals));
        }
      }
    })
  }
  return stats;
}