import { Flex } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { atomDefaultChainID } from "../../atoms";
import { useAccount, useNetwork } from "wagmi";
import { getBlocksPerDay, getDefaultTradePair, getVaults, protocolSupportedChainIds } from "../../constants";
import { UnsupportedNetwork, UnsupportedNetworkProtocolOnly } from "../../components/UnsupportedNetwork";
import { LeverageTrade } from "../../components/leverage/LeverageTrade";
import { useParams } from "react-router-dom";
import { VaultInterface } from "../../constants/interfaces";
import { useEffect } from "react";

export interface TradePair {
  pair: string;
  borrowVault: VaultInterface,
  investmentVault: VaultInterface,
}

export default function LeverageVaultsPageNew() {
  const [defaultChainId] = useAtom(atomDefaultChainID);
  const {chain} = useNetwork();
  const {isConnected} = useAccount();

  const vaults = getVaults(isConnected ? chain?.id: defaultChainId);
  const blocksPerDay = getBlocksPerDay(isConnected ? chain?.id: defaultChainId);
  const {pair: paramPair} = useParams();

  const getTradePair = (): null | TradePair => {
    const pair = paramPair ? paramPair : getDefaultTradePair(chain?.id ? chain.id : defaultChainId);
    if (pair) {
      let symbols = pair.split("-");
      if (symbols.length === 2) {
        let borrowSymol = symbols[0].toLowerCase();
        let investSymbol = symbols[1].toLowerCase();
        let {borrowVault, investmentVault} = getPairVaults(borrowSymol, investSymbol);
        if (borrowVault && investmentVault) {
          return {
            pair: pair,
            borrowVault,
            investmentVault
          }
        }
      }
    } 
    let defaultPair = getDefaultTradePair(chain?.id || defaultChainId);
    let symbols = defaultPair.split("-");
    let {borrowVault, investmentVault} = getPairVaults(symbols[0].toLowerCase(), symbols[1].toLowerCase());
    if (borrowVault && investmentVault) {
      return {
        pair: defaultPair,
        borrowVault,
        investmentVault
      }
    } else {
      return null;
    }
  
  }

  const getPairVaults = (borrowSymbol:string, investSymbol:string)=> {
    return {
      borrowVault: vaults.find((v)=> v.uSymbol.toLowerCase() === borrowSymbol && v.borrowEnabled),
      investmentVault: vaults.find((v)=> v.uSymbol.toLowerCase() === investSymbol && v.buyEnabled)
    }
  }

  if(isConnected) {
    if(chain?.unsupported) {
      return <UnsupportedNetwork/>
    } else if(!protocolSupportedChainIds.includes(chain ? chain.id : 0)) {
      return <UnsupportedNetworkProtocolOnly/>
    }
  } else if(!protocolSupportedChainIds.includes(defaultChainId)) {
    return <UnsupportedNetworkProtocolOnly/>
  }

  const tradePair  = getTradePair();
  return (
    <Flex direction='column' gap='80px' key={paramPair ? paramPair : 'default'}>
      {/* <XdcBanner chainId={isConnected ? chain?.id : defaultChainId}/> */}
      {/* { isConnected && (chain?.testnet || chain?.id === 51) && <Faucet/> } */}
      {tradePair === null ? <h3>
        Trade for Pair {paramPair ? paramPair : 'Unknown'} does not exist
      </h3> :<LeverageTrade
        chainId={chain?.id ? chain.id : defaultChainId}
        blocksPerDay={blocksPerDay}
        tradePair={tradePair}
        vaults={vaults}
      />}
      {/* {isConnected && <ActiveInvestments
        vaultsSnapshotData={vaultsSnapshot}
        vaults={vaults}
      />} */}
      {/* { isConnected && <CrossChainBalance/> } */}
      {/* <LeverageVaults
        vaultsSnapshotData={vaultsSnapshot}
        vaults={vaults}
        blocksPerDay={blocksPerDay}
      /> */}
    </Flex>
  )
}