import { Address } from "viem";
import LoadingGif from '../images/loading.gif';
import { Box, Button, Icon, Img, Link, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { erc20ABI, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { useEffect } from "react";
import { decimal2Fixed, shortAddress } from "../utils/helpers";
import { FiExternalLink } from "react-icons/fi";
import { PiCheckCircleFill, PiWarningCircleBold, PiWarningOctagonDuotone } from "react-icons/pi";
import { getBlockExplorer } from "../constants";
import { atomPendingHashWrite } from "../atoms";
import { useAtom } from "jotai";

interface ApproveSpendProps {
  tokenAddress: Address | undefined;
  spender: Address;
  amount: string;
  symbol: string;
  decimals: number;
  onProceed: ()=> void;
  onCancel: ()=> void;
  ctaText: string;
}

export function ApproveSpend({
  tokenAddress,
  spender,
  amount,
  symbol,
  decimals,
  onProceed,
  onCancel,
  ctaText
}: ApproveSpendProps) {
  const {chain} = useNetwork();
  const [, setHash] = useAtom(atomPendingHashWrite);
  const {
    config,
    error: prepareError,
    isError: isPrepareError
  } = usePrepareContractWrite({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'approve',
    args: [spender, BigInt(decimal2Fixed(amount, decimals))]
  });

  const { data, error, isLoading, isError, write } = useContractWrite(config);

  useEffect(()=> {
    if (write) {
      write();
    }
  }, [write]);

  useEffect(()=> {
    if(data?.hash) {
      setHash(data.hash);
    }
  }, [data?.hash]);

  const {data: txData, isLoading: isTxLoading, isSuccess}  = useWaitForTransaction({hash: data?.hash});
  return (
    <Box textAlign='center'>
      {(isLoading || isTxLoading) && <Img
        display='inline-block'
        height='96px'
        width='96px'
        src={LoadingGif}
      />}
      {( isPrepareError || isError) && <VStack spacing='20px' mt='20px'>
        <Icon as={PiWarningOctagonDuotone} fontSize={'5xl'} color='orange.300'/>
        <Box>
          <Text color='primary.900' textStyle='body-bold-1'>
            Failed to initiate transaction
          </Text>
          <Text wordBreak='break-word' color='orange.400' textStyle='body-bold-3'>
            { (prepareError || error)?.name }
          </Text>
        </Box>
        <Button onClick={()=> onCancel()}>Close</Button>
      </VStack>}
      {isLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting spending from wallet
        </Text>
        <Text textStyle="body-bold-3" color="gray.500">
          Please go to your wallet and<br/>approve spending of {amount} {symbol}
        </Text>
      </Box>}
      {isTxLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting transaction to complete.
        </Text>
        <Link
          textStyle="body-bold-3"
          color="gray.500"
          isExternal
          href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}
        >
          View in Explorer {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
        </Link>
      </Box>}

      {isSuccess && <VStack spacing="32px" mt='20px'>
        <Box>
          <Text textStyle='body-bold-1' color='primary.900'>{amount} {symbol}</Text>
          {txData?.status === 'success' ? <Text textStyle='body-bold-3' color='green.500'>
            Approved Successfully <Icon as={PiCheckCircleFill}/>
          </Text> : <Text textStyle='body-bold-3' color='red.500'>
            Txn Reverted <Icon as={PiWarningCircleBold}/>
          </Text>}
        </Box> 
        <SimpleGrid
          columns={2} spacing='20px'
          borderBottom='1px solid'
          py="12px"
          borderColor='gray.150'
          w="100%"
        >
          <Text
            textStyle="body-3"
            color='primary.900'
            opacity='0.6399999856948853'
            textAlign='left'
          >
            Txn Hash
          </Text>
          <Text
            textStyle="body-3"
            color='primary.900'
            opacity='0.6399999856948853'
            justifySelf='flex-end'
          >
            <Link isExternal href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}>
              {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
            </Link>
          </Text>
        </SimpleGrid>
        <Box w='100%'>
          {txData?.status === 'success' && <Button
            size="lg"
            w="100%"
            colorScheme='primary'
            mb="16px"
            onClick={()=> onProceed()}
          >{ctaText}</Button>}
          <Button
            size="lg"
            w="100%"
            onClick={()=> onCancel()}
          >
            {txData?.status === 'success' ? 'Cancel' : 'Close'}
          </Button>
        </Box>
      </VStack>}
    </Box>
  )
}