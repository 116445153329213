import { Address } from "viem";
import LoadingGif from '../images/loading.gif';
import { Box, Button, Icon, Img, Link, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { useEffect } from "react";
import { shortAddress } from "../utils/helpers";
import { FiExternalLink } from "react-icons/fi";
import { PiCheckCircleFill, PiWarningCircleBold, PiWarningOctagonDuotone } from "react-icons/pi";
import { getFaucetABI, getBlockExplorer } from "../constants";
import { useAtom } from "jotai";
import { atomRefetchSnapshot } from "../atoms";

interface SupplySendProps {
  faucetAddress: Address;
  onCancel: ()=> void;
}

export function FaucetSend({
  faucetAddress,
  onCancel
}: SupplySendProps) {
  
  const {chain} = useNetwork();
  const [, setRefetchSnapshot] = useAtom(atomRefetchSnapshot);

  const {
    config,
    error: prepareError,
    isError: isPrepareError
  } = usePrepareContractWrite({
    address: faucetAddress,
    abi: getFaucetABI(),
    functionName: 'getTestTokens',
  });

  // console.log("error : ", prepareError?.cause);
  
  const { data, error, isLoading, isError, write } = useContractWrite(config);

  useEffect(()=> {
    if (write) {
      write();
    }
  }, [write]);

  const {data: txData, isLoading: isTxLoading, isSuccess}  = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: ()=> {
      setRefetchSnapshot(true);
    }
  });

  return (
    <Box textAlign='center'>
      {(isLoading || isTxLoading) && <Img
        display='inline-block'
        height='96px'
        width='96px'
        src={LoadingGif} 
      />}
      
      {( isPrepareError || isError) && <VStack spacing='20px' mt='20px'>
        <Icon as={PiWarningOctagonDuotone} fontSize={'5xl'} color='orange.300'/>
        <Box>
          <Text color='primary.900' textStyle='body-bold-1'>
            Failed to initiate transaction
          </Text>
          <Text wordBreak='break-word' color='orange.400' textStyle='body-bold-3'>
            { (prepareError || error)?.name }
          </Text>
        </Box>
        <Button onClick={()=> onCancel()}>Close</Button>
      </VStack>}

      {isLoading &&  !isPrepareError && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting Txn to initiate.
        </Text>
        <Text textStyle="body-bold-3" color="gray.500">
          Please go to your wallet and <br/> allow this transaction to continue.
        </Text>
      </Box>}

      {isTxLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting transaction to complete.
        </Text>
        <Link
          textStyle="body-bold-3"
          color="gray.500"
          isExternal
          href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}
        >
          View in Explorer {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
        </Link>
      </Box>}

      {isSuccess && <VStack spacing="32px" mt='20px'>
        <Box>
          <Text textStyle='body-bold-1' color='primary.900'> Tokens </Text>
          {txData?.status === 'success' ? <Text textStyle='body-bold-3' color='green.500'>
            Sent Successfully <Icon as={PiCheckCircleFill}/>
          </Text> : <Text textStyle='body-bold-3' color='red.500'>
            Txn Reverted <Icon as={PiWarningCircleBold}/>
          </Text>}
        </Box>
        <Box w='100%'>
          <SimpleGrid
            columns={2} spacing='20px'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Txn Hash
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              <Link isExternal href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}>
                {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
              </Link>
            </Text>
          </SimpleGrid>
        </Box>
        <Box w='100%'>
          {txData?.status === 'success' ? <Button
            size="lg"
            w="100%"
            colorScheme='primary'
            mb="16px"
            onClick={()=> onCancel()}
          >Done</Button> :
          <Button
            size="lg"
            w="100%"
            onClick={()=> onCancel()}
          >
            Close
          </Button>}
        </Box>
      </VStack>}
    </Box>
  )
}