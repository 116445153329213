import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0x8c850634c8b416c3450ec8d39eede189fc0a89da",
    decimals: 8,
    underlying: "0x551447a0dfcb8233ade8ecb748d4cd822b081e5d",
    uSymbol: "xUSDT",
    uName: "xUSDT",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0xc958bd111171c7c78013802587587721c8138642"]
  },
 
  {
    address: "0xc958bd111171c7c78013802587587721c8138642",
    decimals: 8,
    underlying: "0x1c2120e25292a38229717ef56307b686f62d52fc",
    uSymbol: "WXDC",
    uName: "WXDC",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x8c850634c8b416c3450ec8d39eede189fc0a89da"],
    tradingWidgetConfig: {
      source: 'BITFINEX',
      pair: 'XDCUSD'
    }
  }
];

export const defaultTradePair  = "xusdt-wxdc";


export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0xfffabb83f923e0f0f706de79b7e748c3c68b2753",
  'Comptroller': "0xf3bf41ccaddd3117a3d93293cc6f2f111dbf9e35"
}

export const blockExplorer = 'https://explorer.apothem.network';

export const blocksPerDay = 43200; // 2sec per block as per apothem explorer

export const DexQuoters : DexQuoter[] = [
  {
    id: '2',
    name: 'xswap',
    slug: 'xswap',
    type: 'Contract',
    address: "0x3F11A24EB45d3c3737365b97A996949dA6c2EdDf",
    functionName: 'getAmountsOut'
  }
]

export const eventsApiChainSlug='xdc-testnet';
export const faucetURL = "https://faucet.apothem.network/";
export const faucetAddress = "0x2cE073A6731E33D58079f0e60f596B585B70Ec31";