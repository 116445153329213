import { Box, BoxProps, Button, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import IndicativeNumber from "./IndicativeNumber";
import { useNetwork } from "wagmi";
import { getVaults } from "../constants";
import { useAtom } from "jotai";
import { atomUserEvents, atomVaultsSnapshot } from "../atoms";
import { getInvestmentDetails } from "../utils/investments";
import { VaultInterface } from "../constants/interfaces";
import { fixed2Decimals } from "../utils/helpers";
import { ModalWithdraw } from "./ModalWithdraw";
import { useState } from "react";

export function InvestStats({
  vault
}: { vault: VaultInterface }) {
  const [open, setOpen] = useState(false);
  const { chain } = useNetwork();
  const [snapshot] = useAtom(atomVaultsSnapshot);
  const vaults = getVaults(chain?.id);
  const [events] = useAtom(atomUserEvents);
  const details = getInvestmentDetails([vault, ...vaults.filter((v) => v.borrowEnabled)], snapshot, events);
  const detail = details.length === 1 ? details[0] : null;
  const vaultSnapshot = snapshot ? snapshot[vault.address] : null;
  if (detail === null || vaultSnapshot == null) {
    return null;
  }
  return (
    <VStack w='100%' px='12px' alignItems='flex-start'>
      <SimpleGrid w='100%' columns={3} spacing='15px' borderBottom='1px solid' borderColor='gray.150'>
        <InvestStatsBlock
          title='Current Investment'
          value={`${fixed2Decimals(detail.balance.amount, detail.decimals, 4, 1)} ${detail.symbol}`}
        />
        <InvestStatsBlock
          title='Unrealized P&L'
          value={detail.pnl ? detail.pnl.toFixed(2) : '---'}
          valueIndicative={detail.pnl ? true : false}
          prefix="$"
        />
        <Box py='32px' display='flex' justifyContent='flex-end'>
          <Button
            size='sm'
            variant='outline'
            colorScheme='primary'
            onClick={() => setOpen(true)}
          >Withdraw</Button>
        </Box>
      </SimpleGrid>
      <SimpleGrid w='100%' columns={3} spacing='15px' borderBottom='1px solid' borderColor='gray.150'>
        <InvestStatsBlock
          title='Present Value'
          value={`$ ${detail.balance.usd}`}
        />
        <InvestStatsBlock
          title='Invested Amount'
          value={detail.borrowedAssets.map((row) => `${fixed2Decimals(row.downpayment.amount, row.decimals, 4, 1)} ${row.symbol}`).join(', ')}
        />
        <InvestStatsBlock
          title='Withdrawals'
          value={`${fixed2Decimals(detail.redeemed, detail.decimals, 4, 1)} ${detail.symbol}`}
        />
      </SimpleGrid>
      <SimpleGrid w='100%' columns={3} spacing='15px'>
        <InvestStatsBlock
          title='Average Cost'
          value={detail.borrowedAssets.map((row) => `${row.avgCost} ${row.symbol}`).join(', ')}
        />
      </SimpleGrid>
      {open && <ModalWithdraw
        isOpen={open}
        onClose={() => setOpen(false)}
        vault={{
          address: vault.address,
          balanceTokens: vaultSnapshot.lendTokens,
          balanceAmount: vaultSnapshot.lendBalance,
          uSymbol: vault.uSymbol,
          uDecimals: vault.uDecimals,
          exchangeRate: vaultSnapshot.exchangeRateStored
        }}
      />}
    </VStack>

  )
}

interface InvestStatBlockProps extends BoxProps {
  title: string;
  value: string | number;
  valueColor?: string;
  valueIndicative?: boolean;
}

function InvestStatsBlock({
  title, value, valueIndicative, ...rest
}: InvestStatBlockProps) {
  return <Box
    py='32px'
    {...rest}
  >
    <Text
      mb='8px'
      textStyle='labelBold'
      color='primary.900'
      opacity='0.64'
    >{title.toUpperCase()}</Text>
    {valueIndicative ? <IndicativeNumber
      number={typeof value === 'string' ? Number(value) : value}
      textStyle='subheading-1'
      prefix='$'
    /> : <Text
      textStyle='subheading-1'
      color='primary.900'
    >{value}</Text>}
  </Box>
}