import {Box, Center, Img, Icon, Text, VStack, SimpleGrid, Button} from '@chakra-ui/react';
import { PiNotePencil } from 'react-icons/pi';
import { getTokenImage } from '../utils/image';
import { SellProps } from './Sell';
import { useAccount, useContractRead, useNetwork } from 'wagmi';
import { getAbi, getContractAddress } from '../constants';
import { zeroAddress } from 'viem';
import { fixed2Decimals, isZero } from '../utils/helpers';
import { SellPreviewData } from './Sell';

export function SellPreview({
  onEdit,
  onSubmit,
  handlePreviewData,
  data
}: {data: SellProps, onEdit: ()=> void, onSubmit: ()=> void, handlePreviewData: (data: SellPreviewData)=> void} ) {
  const {address: userAddress} = useAccount();
  const {chain} = useNetwork();
  
  const {data: settleRes, isError, isLoading} :  {data: any, isError: boolean, isLoading: boolean} = useContractRead({
    abi: getAbi({chainId: chain?.id, abiFile: 'Comptroller'}),
    address: getContractAddress({chainId: chain?.id, contractName: 'Comptroller'}),
    functionName: 'swapAndSettleCalculateRepayAmount',
    args: [
      userAddress || zeroAddress,
      data.inAddress,
      data.outAddress,
      data.inTokens,
      data.quote? data.quote.quote : "0"
    ],
    onSuccess: (data)=> {
      if(data) {
        handlePreviewData(data);
      }
    }
  });

  // settleRes && ! isError && handlePreviewData(settleRes);
  
  return <Box>
    <Center>
      <Img
        src={getTokenImage(data.inSymbol)}
        width='80px'
        height='80px'
        border='6px solid'
        borderColor='white'
        backgroundColor="white"
        borderRadius="100%"
        mt='-40px'
      />
    </Center>
    <Box textAlign='center'>
      <Text textStyle='body-3' color='primary.900' opacity='0.64'>Selling position from</Text>
      <Text textStyle='body-bold-1'>{data.inSymbol} Vault</Text>
    </Box>
    <Box mt='24px' borderRadius='12px' bgColor='#F4F5FB'>
      <SimpleGrid columns={2} px='20px' py='16px' border='1px solid' borderColor='gray.150' borderRadius='12px' bgColor='white'>
        <Text textStyle='body-3' color='primary.900' opacity='0.64'>Selling Amount</Text>
        <Text textStyle='body-bold-1' textAlign='right'>{data.inAmount} {data.inSymbol}</Text>
      </SimpleGrid>
      <Box py='12px' borderBottomRadius='12px'>
        <Text cursor='pointer' onClick={()=> onEdit()} textAlign='center' textStyle='labelBold' color='primary.500'>
          <Icon as={PiNotePencil} /> EDIT AMOUNT
        </Text>
      </Box>
    </Box>

    {settleRes && ! isError && <VStack mt='28px'>
      {!isZero(settleRes.estimatedShortfall) && <SimpleGrid
        columns={2} spacing='20px'
        borderBottom='1px solid'
        py="12px"
        borderColor='gray.150'
        w="100%"
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          You Repay to protocol
        </Text>
        <Text textStyle="body-bold-2"
          color='primary.900'
          justifySelf='flex-end'
        >
          {fixed2Decimals(settleRes.repayAmountRequired, data.outDecimals)} {data.outSymbol}
        </Text>
      </SimpleGrid>}
      {!isZero(settleRes.estimatedShortfall) && <SimpleGrid
        columns={2} spacing='20px'
        borderBottom='1px solid'
        py="12px"
        borderColor='gray.150'
        w="100%"
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          You Receive
        </Text>
        <Text textStyle="body-bold-2"
          color='primary.900'
          justifySelf='flex-end'
        >
           {fixed2Decimals(settleRes.totalReceivable, data.outDecimals)} {data.outSymbol}
        </Text>
      </SimpleGrid>}
      {isZero(settleRes.estimatedShortfall) && <>
      <SimpleGrid
        columns={1}
        borderBottom='1px solid'
        py="12px"
        borderColor='gray.150'
        w="100%"
      >
        <Text
          textStyle="labelBold"
          color='primary.900'
          textTransform='uppercase'
        >
          Portfolio after withdrawl
        </Text>
      </SimpleGrid>
      
      <SimpleGrid
        columns={2} spacing='20px'
        py="12px"
        w="100%"
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          Account Liquidity
        </Text>
      
        {isZero(settleRes.estimatedShortfall) ? <Text textStyle="body-bold-2"
          color='primary.900'
          justifySelf='flex-end'
        >${fixed2Decimals(settleRes.estimatedLiquidity, 18 ,2)}</Text> : <Text textStyle="body-bold-2"
          color='red.500'
          justifySelf='flex-end'
        >-${fixed2Decimals(settleRes.estimatedShortfall, 18 ,2)}</Text>}
      </SimpleGrid>
      </>}
    </VStack>}

    <Button
      isDisabled={
        isError ||
        isLoading
      }
      mt='24px'
      w='full'
      size='lg'
      colorScheme='primary'
      onClick={()=> {
        onSubmit();
      }}
    >
      Sell Position
    </Button>
  </Box>
}