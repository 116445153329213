import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0x54ecc8832b65c2db0c53235cde94991c5cdcabf9",
    decimals: 8,
    underlying: "0x49d3f7543335cf38fa10889ccff10207e22110b5",
    uSymbol: "FXD",
    uName: "FXD",
    uDecimals: 18,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e", "0xb7c3b74f897adce04db33d9438d60a8fcf70cbc1"]
  },
  {
    address: "0x98f99c3f405a828188c88e8ea9fd02393b438217",
    decimals: 8,
    underlying: "0x1ebb2c8a71a9ec59bf558886a8adf8f4a565814f",
    uSymbol: "EURS",
    uName: "STASIS EURO",
    uDecimals: 2,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 0,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e", "0xfc839b46019606b425f8b4c65b4479c3315de5c6"]
  },
  {
    address: "0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e",
    decimals: 8,
    underlying: "0x951857744785e80e2de051c32ee7b25f9c458c42",
    uSymbol: "WXDC",
    uName: "Wrapped XDC",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    isStable: false,
    borrowAssets: ["0x54ecc8832b65c2db0c53235cde94991c5cdcabf9", "0x98f99c3f405a828188c88e8ea9fd02393b438217"],
    tradingWidgetConfig: {
      source: 'CRYPTO',
      pair: 'XDCUSD'
    }
  },
  {
    address: "0xb7c3b74f897adce04db33d9438d60a8fcf70cbc1",
    decimals: 8,
    underlying: "0x1ebb2c8a71a9ec59bf558886a8adf8f4a565814f",
    uSymbol: "EURS",
    uName: "STASIS Euro",
    uDecimals: 2,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    isStable: true,
    borrowAssets: ["0x54ecc8832b65c2db0c53235cde94991c5cdcabf9"],
    tradingWidgetConfig: {
      source: 'FOREXCOM',
      pair: 'EURUSD'
    }
  },
  {
    address: "0xfc839b46019606b425f8b4c65b4479c3315de5c6",
    decimals: 8,
    underlying: "0x49d3f7543335cf38fa10889ccff10207e22110b5",
    uSymbol: "FXD",
    uName: "FXD",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    isStable: true,
    borrowAssets: ["0x98f99c3f405a828188c88e8ea9fd02393b438217"],
    tradingWidgetConfig: {
      source: 'CURVE',
      pair: 'USDCEURS_98A7F1'
    }
  }
];

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0x2588c9214376dDb524DCfa72859B522E745283Bf",
  'Comptroller': "0x301C76e7b60e9824E32991B8F29e1c4a03B4F65b"
}

export const defaultTradePair  = "fxd-wxdc";


export const blockExplorer = 'https://xdc.blocksscan.io';

export const blocksPerDay=43200;
export const slippage = 50;
export const rpc = "https://rpc.ankr.com/xdc/";
export const DexQuoters : DexQuoter[] = [
  // {
  //   id: '2',
  //   name: 'Fathom DEX',
  //   slug: 'quickswap-v2',
  //   type: 'Contract',
  //   address: "0x7e5b4c238A904329596c4094877D48868d739963",
  //   functionName: 'getAmountsOut'
  // },
  {
    id: '5',
    name: 'XSwap',
    slug: 'xswap-smart-order',
    type: 'API',
    address: "0x3b9edecc4286ba33ea6e27119c2a4db99829839d",
    quoteApiUrl: "https://api.wefi.xyz/xswap-routes?tokenIn={{tokenIn}}&tokenOut={{tokenOut}}&amountIn={{amountIn}}&&recipient={{recipient}}&&slippage={{slippage}}",
  }
]

export const eventsApiChainSlug='xdc-mainnet';
export const oraclePriceDecimals='16';