import { Box, Button, Center, Divider, Flex, HStack, Icon, Img, Spinner, Text, useBreakpointValue, useToast } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useAccount, useBalance } from "wagmi";
import { atomIsXdcGasSent } from "../atoms";
import { useEffect, useState } from "react";
import { getTokenImage } from "../utils/image";
import { PiCheckCircleFill } from "react-icons/pi";

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function XdcGas() {
  const {address} = useAccount();
  const [isSent, setIsSent] = useAtom(atomIsXdcGasSent);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(()=> {
    setShowSuccess(false);
    fetch('https://api.wefi.xyz/misc/xdc-check-gas-sent', {
      method: 'POST',
      body: JSON.stringify({
        account: address
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      } 
    })
    .then(function(response){ 
      return response.json()})
    .then(function(json: any){
      setIsSent(json.data.status === 0);
    }).catch(error => console.error('Error:', error)); 
  }, [address])

  const {data} = useBalance({
    address: address,
    watch: !isSent || showSuccess
  })

  
  if ((isSent === null || isSent === true) && !showSuccess) {
    return null;
  }

  const handleRequest = ()=> {
    setIsLoading(true);
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      setIsLoading(false);
      return;
    }
    executeRecaptcha("xdcGasDistribute").then((gReCaptchaToken) => {
      fetch('https://api.wefi.xyz/misc/xdc-send-gas', {
        method: 'POST',
        body: JSON.stringify({
          account: address,
          token: gReCaptchaToken
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        } 
      })
      .then(function(response){ 
        return response.json()})
      .then(function(json: any){
        if (json.data.status ===2) {
          throw new Error("error response 2");
        } else {
          setIsSent(true);
          setShowSuccess(true);
        }
      }).catch(error => {
        console.error('Error:', error);
        toast({
          title: 'There was an error processing your request for XDC Tokens. Please retry.',
          description: "",
          status: 'warning',
          duration: 9000,
          isClosable: true,
          position: 'top-right'
        })
      }).finally(()=> {
        setIsLoading(false);
      });
    });
  }

  return <Flex
    px='56px'
    py='21px'
    border="1px solid"
    borderColor="gray.100"
    borderRadius="16px"
    alignItems='center'
    flexDirection={{base: 'column', sm: 'column', md: 'row'}}
  >
    <Box w={{base: '100%', sm: '100%', md: '60%'}}>
      <HStack spacing={4}>
        <Img src={getTokenImage('txdc')} height='50px'/>
        <Box>
          <Text
            textStyle="cta-2"
            color= "primary.900"
          >
            Get XDC Tokens for Gas.
          </Text>
          <Text
            mt={1}
            textStyle="body-3"
            color= "gray.400"
          >
            Click on the request button to get XDC token for Gas. The tokens you receive will be sufficient for several transactions.
          </Text>
        </Box>
      </HStack>
    </Box>
    <Center mx='25px' height={{base: '0px', sm: '0px', md: '50px'}}>
      <Divider orientation={"vertical"} />
    </Center>
    <Box w={{base: '100%', sm: '100%', md: '40%'}}>
      <Text
        textStyle="body-3"
        color= "gray.500"
      >XDC Balance:&nbsp;{data?.formatted}</Text>
      {isLoading && <Box mt='4px'>
        <Spinner
          float='left'
           speed='1s'
           emptyColor='primary.100'
           color='white'
           size='sm'
           me={2}
        />
        <Text
          float='left'
          textStyle="body-3"
          color= "gray.500"
        >Your request is being processed.</Text>
      </Box>}

      {showSuccess && <Box mt='4px'>
        <Icon
          as ={PiCheckCircleFill}
          color='green.500'
          me={1}
          float='left'
        />
        <Text
          float='left'
          textStyle="body-3"
          color= "gray.500"
        >Your request is processed successfully.</Text>
      </Box>}

      {!isLoading && !showSuccess && <Button
        mt='4px'
        size='sm'
        isDisabled={isLoading}
        variant='outline'
        colorScheme="primary"
        onClick={()=> handleRequest()}
      >
        Request XDC Tokens
      </Button>}
    </Box>
  </Flex>
}