import { Box, BoxProps, Text } from "@chakra-ui/react";
import IndicativeNumber from "./IndicativeNumber";

interface PortfolioStatsBlockProps extends BoxProps {
  value: string,
  valueColor?: string,
  title: string,
  isIndicative?: boolean
}

export default function PortfolioStatsBlock({
  value,
  valueColor,
  title,
  isIndicative,
  ...rest
}: PortfolioStatsBlockProps) {
  return (
    <Box
      {...rest}
    >
      {isIndicative ? <IndicativeNumber
        textStyle="heading-2"
        number={Number(value)}
        prefix='$'
      /> :<Text
        textStyle="heading-2"
        color={valueColor ? valueColor : "primary.900"}
      >
        {value}
      </Text>}
     
      <Text
        textStyle="body-2"
        color= "gray.500"
        mt="8px"
      >
        {title}
      </Text>
    </Box>
  )
}