import * as React from 'react'
import {
  ChakraProvider,
} from '@chakra-ui/react';

import {
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';

import { WagmiConfig } from 'wagmi';
import { config } from './web3/config';

import {Provider as JotaiProvider} from 'jotai';

import './app.css';
import {theme} from './theme/theme';
import LendingVaultsPage from './routes/lendingVaults/LendingVaultsPage';
// import LeverageVaultsPage from './routes/leverageVaults/LeverageVaultsPage';
import AppLayout from './AppLayout';
import PortfolioPage from './routes/portfolio/PortfolioPage';
// import RewardsPage from './routes/rewards/RewardsPage';
// import { VaultPage } from './routes/leverageVaults/VaultPage';
import { BridgePage } from './routes/bridge/BridgePage';
// import PredictionPage from './routes/games/PredictionPage';
import VeStakingPage from './routes/staking/VeStakingPage';
import LeverageVaultsPageNew from './routes/leverageVaults/LeverageVaulltsPageNew';
import LeaderboardPage from './routes/leaderboard/LeaderboardPage';


const router = createBrowserRouter([
  {
    element: <AppLayout/>,
    children: [
      {
        path: '/',
        element: <LendingVaultsPage/>
      },
      {
        path: 'leverage-vaults',
        element: <LeverageVaultsPageNew/>
      },
      {
        path: 'leverage-vaults/:pair',
        element: <LeverageVaultsPageNew/>
      },
      {
        path: 'portfolio',
        element: <PortfolioPage/>
      },
      {
        path: 'leaderboard',
        element: <LeaderboardPage/>
      },
      // {
      //   path: 'prediction',
      //   element: <PredictionPage/>
      // },
      // {
      //   path: 'prediction/history',
      //   element: <UserRounds/>
      // },
      // {
      //   path: 'rewards',
      //   element: <RewardsPage/>
      // },
      // {
      //   path: 'swap',
      //   element: <SwapPage/>
      // },
      {
        path: '/bridge',
        element: <BridgePage/>
      },
      {
        path: '/stake-wefi',
        element: <VeStakingPage />
      }
    ]
  },
]);

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <WagmiConfig config={config}>
        <JotaiProvider>
          <RouterProvider router={router}/>
        </JotaiProvider>
      </WagmiConfig>
    </ChakraProvider>
  )
}
