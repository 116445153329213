import {
  Box,
  Flex,
  HStack,
  Img,
  SimpleGrid,
  Text,
  VStack
} from "@chakra-ui/react";

import { getTokenImage } from "../utils/image";
import { LeverageSlider2 } from "./LeverageSlider";
import { BorrowVault, InvestmentVault } from "../utils/investments";
import { useEffect, useState } from "react";
import {
  decimal2Fixed,
  fixed2Decimals,
  isGreaterThan,
  isZero,
  minusFixed,
  mulFixed
} from "../utils/helpers";
import { DexQuotes } from "./DexQuotes";
import { VaultInterface } from "../constants/interfaces";
import { Quote } from "../hooks/useDexQuotesRead";
import { BorrowAndBuy } from "./Borrow";
import { getContractAddress } from "../constants";
import { useNetwork } from "wagmi";
import { DebouncedInput2 } from "./DebouncedInput"

export function BuyTab({
  vault,
  vaultData,
  borrowVaults
}: {vault: VaultInterface, vaultData: InvestmentVault, borrowVaults: BorrowVault[]}) {
  const [activeBorrowVault, setActiveBorrowVault] = useState<BorrowVault | undefined>(borrowVaults[0]);
  const [amountIn, setAmountIn] = useState<string>("0");
  const [leverage, setLeverage] = useState<number>(1.1);
  const [quote, setQuote] = useState<Quote | null>(null);
  const {chain} = useNetwork();
  
  useEffect(()=> {
    if(!activeBorrowVault && borrowVaults.length > 0) {
      setActiveBorrowVault(borrowVaults[0])
    }
  },[borrowVaults, activeBorrowVault]);

  if(!activeBorrowVault) {
    return null;
  }

  const balanceIn = fixed2Decimals(activeBorrowVault.underlyingBalance, activeBorrowVault.uDecimals);

  let partialAmount = decimal2Fixed(amountIn, activeBorrowVault.uDecimals);
  let borrowAmount = "0";
  let totalAmountIn = "0";
  
  if(!isZero(amountIn)) {
    totalAmountIn = mulFixed(partialAmount, leverage);
    borrowAmount = minusFixed(totalAmountIn, partialAmount);
  }

  const comptrollerAddress = getContractAddress({chainId: chain?.id , contractName: 'Comptroller'});

  return (
    <Box
    >
      <Box mb={2} bgColor='#F4F5FB' p={4} borderRadius='8px'>
        <Flex mb={1}>
          <Box w='30%'>
            <Text textStyle='label' color='gray.400'>Pay</Text>
          </Box>
          <Box w='70%' >
            <Text textAlign='right' color='gray.400' textStyle='label'>Balance: {balanceIn}</Text>
          </Box>
        </Flex>
        <DebouncedInput2 
          variant='unstyled'
          type='number'
          value={amountIn}
          balanceIn={balanceIn}
          onChange={(e)=> setAmountIn(e)}
          symbol={activeBorrowVault.symbol}
        />
      </Box>
      <Box mb={5} bgColor='#F4F5FB' p={4} borderRadius='8px'>
        <Flex mb={1}>
          <Box w='30%'>
            <Text textStyle='label' color='gray.400'>{!isZero(totalAmountIn) && quote && quote.quote && vaultData.priceUsd ? `~ $${(vaultData.priceUsd * Number(fixed2Decimals(quote.quote, vault.uDecimals))).toFixed(4)}` : '~' }</Text>
          </Box>
          <Box w='70%' >
            {(isZero(amountIn) || amountIn === "" || !comptrollerAddress) ? '' : <DexQuotes
                amountIn={totalAmountIn}
                tokenIn={activeBorrowVault.underlying}
                tokenOut={vault.underlying}
                decimalsIn={activeBorrowVault.uDecimals}
                decimalsOut={vault.uDecimals}
                symbolOut={vault.uSymbol}
                recipient={comptrollerAddress}
                onQuoteUpdate={(quote)=> {
                  setQuote(quote);
                }}
              />}
          </Box>
        </Flex>
        <Flex>
          <Box w='60%'>
            <Text fontWeight={700} color='primary.900'>
              {Number(amountIn) > 0 && quote ? fixed2Decimals(quote.quote, vault.uDecimals, 8) : ''}
            </Text>
          </Box>
          <HStack w='40%' justifyContent='flex-end'>
            <Img h='16px' src={getTokenImage(vault.uSymbol)} />
            <Text textStyle='labelBold' color='primary.900'>{vault.uSymbol}</Text>
            {/* <Icon as={IconAngleDown} color='gray.300'/> */}
          </HStack>
        </Flex>
      </Box>
      <Box px={4}>
        <Box mb={5}>
          <Text textStyle='label' color='gray.400'>
            Leverage
          </Text>
          <LeverageSlider2
            max={activeBorrowVault.leverage}
            value={leverage}
            onSliderChange={(val)=> setLeverage(val)}
          />
        </Box>
        <Box w='100%' mb={5}>
          <SimpleGrid
            columns={2}
            py="8px"
            borderBottom='1px solid'
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="label"
              color='gray.400'
            >
              Borrow
            </Text>
            <Text textStyle="labelBold"
              color='primary.900'
              justifySelf='flex-end'
            >
              {fixed2Decimals(borrowAmount, activeBorrowVault.uDecimals)} {activeBorrowVault.symbol}
            </Text>
          </SimpleGrid>

          <SimpleGrid
            columns={2}
            py="8px"
            borderBottom='1px solid'
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="label"
              color='gray.400'
            >
                Borrow APY
            </Text>
            <Text textStyle="labelBold"
              color='primary.900'
              justifySelf='flex-end'
            >
              {activeBorrowVault.borrowApy ? `${activeBorrowVault.borrowApy.toFixed(2)}%` : '---'}
            </Text>
          </SimpleGrid>

          <SimpleGrid
            columns={2}
            py="8px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="label"
              color='gray.400'
            >
              Fees
            </Text>
            <Text textStyle="labelBold"
              color='primary.900'
              justifySelf='flex-end'
            >
              $0.00
            </Text>
          </SimpleGrid>
        </Box>
        <VStack
          spacing='20px'
          mt='20px'
          alignItems='flex-start'
          padding='20px'
          w='100%'
        >
          <BorrowAndBuy
            isDisabled={(
              quote === null ||
              isZero(partialAmount) ||
              isZero(borrowAmount) ||
              isGreaterThan(partialAmount, activeBorrowVault.underlyingBalance)
            )}
            borrowAmount={borrowAmount}
            partialAmount={partialAmount}
            underlying={activeBorrowVault.underlying}
            uDecimals={activeBorrowVault.uDecimals}
            vaultAddress={vault.address}
            borrowVaultAddress={activeBorrowVault.address}
            uSymbol={activeBorrowVault.symbol}
            outAmount={quote === null ? '0' : quote.quote}
            outSymbol={vault.uSymbol}
            outDecimals={vault.uDecimals}
            quote = {quote}
          />
        </VStack>
      </Box>
    </Box>
  )
}

