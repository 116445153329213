import {
  Box,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  HStack,
  Button,
  Icon
} from '@chakra-ui/react';
import { IconInfo } from './CustomIcons';
import { VaultInterface } from '../constants/interfaces';
import { VaultsSnapshot, atomUserEvents } from '../atoms';
import { ActiveInvestmentVault, getActiveInvestmentVaults } from '../utils/investments';
import { getTokenImage } from '../utils/image';
import IndicativeNumber from './IndicativeNumber';
import { useAtom } from 'jotai';
import AddToken from './AddToken';
import { useState } from 'react';
import { InvestStats } from './InvestStats';
import { Address } from 'viem';
import { PiArrowLeftBold } from 'react-icons/pi';

export default function ActiveInvestmentsCompact ({
	vaults,
	vaultsSnapshotData
}: {
	vaults: VaultInterface[],
	vaultsSnapshotData: VaultsSnapshot | null
}) {
  const [events] = useAtom(atomUserEvents);
	const [activeVaultAddress, setActiveVaultAddress] = useState<Address | null>(null);
	const activeVaults = getActiveInvestmentVaults(vaults, vaultsSnapshotData, events);
	const activeVault = vaults.find((v)=> v.address === activeVaultAddress);
	return (
		<Box>
			{activeVault ? <Box>
					<Button leftIcon={<Icon as={PiArrowLeftBold} />} onClick={()=> setActiveVaultAddress(null)}>Back</Button>
					<InvestStats
						vault={activeVault}
					/>
				</Box> : <TableContainer>
				<Table variant='striped'>
					<Thead textStyle='body-3'>
						<Tr>
							<Th>Pool</Th>
							<Th>Current Value <Icon as={IconInfo} /></Th>
							<Th>Avg Cost <Icon as={IconInfo} /></Th>
							<Th>Invested Amt. <Icon as={IconInfo} /></Th>
							<Th>Unrealized P&L. <Icon as={IconInfo} /></Th>
						</Tr>
					</Thead>
					<Tbody textStyle='body-3'>
						{activeVaults.map((vault, i)=>(
							<ActiveInvestmentItem
								key={i}
								onViewVault={()=> setActiveVaultAddress(vault.address) }
								{...vault}
							/>
						))}
					</Tbody>
				</Table>
			</TableContainer>}
			{activeVaults.length === 0 && <Text mt={4} textAlign='center' color='gray.400' textStyle='body-3'>No Positions.</Text>}
		</Box>        
	)
}

interface ActiveItemRowProps extends ActiveInvestmentVault {
	onViewVault: ()=> void
}
function ActiveInvestmentItem({
	underlying, uDecimals, symbol, amountUsd, avgCost, amount, pnl, onViewVault
}: ActiveItemRowProps) {
	return (
		<Tr cursor='pointer' onClick={()=> onViewVault()} color='primary.900'>
		<Td>
			<HStack>
				<AddToken
					token={{
						address: underlying,
						symbol: symbol,
						decimals: uDecimals
					}}
				/>
				<Image h='16px' src={getTokenImage(symbol)} />
				<Text>{symbol}</Text>
			</HStack>
		</Td>
		<Td>
			${amountUsd.toFixed(2)}
		</Td>
		<Td>
			{avgCost.length > 0 ? avgCost.join(', ') : '---'}
		</Td>
		<Td>
			{amount.toFixed(4)} {symbol}
		</Td>
		<Td>
			{pnl ? <IndicativeNumber
				prefix='$'
				number={Number(pnl.toFixed(2))}
				spacing="0px"
			/> : '---'}
		</Td>
		{/* <Td position='sticky' right='0px' bg='white' zIndex='0'>
				<Button
					size='sm'
					variant='outline'
					colorScheme='primary'
					onClick={()=> onViewVault()}
				>View Vault</Button>
		</Td> */}
	</Tr>
	)
}
