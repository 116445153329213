import {
  Box,
  Text,
  SimpleGrid,
  HStack,
  VStack,
  Img
} from '@chakra-ui/react';
import { getTokenImage } from '../utils/image';
import { useNetwork } from 'wagmi';
import { getVaults } from '../constants';
import { useAtom } from 'jotai';
import { atomVaultsSnapshot } from '../atoms';
import { TokenBalance, getTokenBalances } from '../utils/balances';

export function WalletTokenBalances() {
  const {chain} = useNetwork();
  const [snapshot] = useAtom(atomVaultsSnapshot);
  const vaults = getVaults(chain?.id, 'lending');
  const tokenBalances = getTokenBalances(vaults, snapshot);
  return (
    <Box px='32px' py='28px'>
      <Text textStyle='subheading-3' color='gray.500'>
        TOKENS
      </Text>
      {tokenBalances.map((row)=> (
        <Balance
          {...row}
        />
      ))}
     
    </Box>
  )
}


function Balance({symbol, amount, priceUsd}: TokenBalance ) {
  return (
    <SimpleGrid columns={2} spacing='20px'
      borderTop='1px solid'
      py='12px'
      mt='12px'
      borderColor='gray.150'
    >
      <HStack spacing='12px'>
        <Img src={getTokenImage(symbol)} height='32px' width='32px' />
        <VStack spacing='4px' alignItems="flex-start">
          <Text textStyle='cta-2' color='gray.800'>{symbol}</Text>
          <Text textStyle='body-bold-3' color="gray.500">{amount}</Text>
        </VStack>
      </HStack>
      <Box textAlign='right' textStyle='subheading-1'>
        ${priceUsd.toFixed(2)}
      </Box>
    </SimpleGrid>
  )
} 