import { Address } from "viem";
import { VaultsSnapshot } from "../atoms";
import { VaultInterface } from "../constants/interfaces";
import { addFixed, calculateApy, decimal2Fixed, fixed2Decimals, getUSDPrice, isZero, minusFixed } from "./helpers";
import { AmountWithUSD } from "./loanPositions";
import { ContractEvent, MintEvent, RedeemEvent } from "./events/interfaces";

export interface RewardsAPR {
  value: number;
  suffix: '%' | '/Day';
}

export interface ActiveLendingVault {
  address: Address,
  underlying: Address,
  uSymbol: string;
  uDecimals: number;
  balanceTokens: string;
  balanceAmount: string;
  poolApy: number;
  earnings: number;
  exchangeRate: string;
}
export const getActiveLendingVaults = (
  vaults: VaultInterface[],
  data: VaultsSnapshot | null,
  blocksPerDay: number,
  events: ContractEvent[]
): ActiveLendingVault[] => {
  const res: ActiveLendingVault[] = [];
  if(data === null || vaults.length === 0) {
    return res;
  }
  vaults.forEach((vault)=> {
    if (data[vault.address]) {
      let lendBalance = data[vault.address].lendBalance.toString();
      if(Number(lendBalance) > 0) {
        const vaultSupplyPnl = getSupplyPnL([vault], data, events); 
        res.push({
          address: vault.address,
          underlying: vault.underlying,
          uSymbol: vault.uSymbol,
          uDecimals: vault.uDecimals,
          balanceAmount: lendBalance,
          balanceTokens: data[vault.address].lendTokens,
          poolApy: calculateApy(data[vault.address].supplyRatePerBlock, blocksPerDay),
          earnings: vaultSupplyPnl.pnlUsd,
          exchangeRate: data[vault.address].exchangeRateStored
        });
     }
    }
  });
  return res;
}

export interface LendingVault {
  address: Address;
  underlying: Address;
  underlyingBalance: string;
  symbol: string;
  uDecimals: number;
  markets: Address[];
  marketsSymbols: string[];
  poolApy?: number;
  rewardsApr?: RewardsAPR;
  tvlUsd?: number;
  utilization?: number;
  exchangeRate?: number;
  minLendAmountRequired?: number
}

export const getLendingVaults = (
  vaults: VaultInterface[],
  data: VaultsSnapshot | null,
  blocksPerDay: number,
  wefiTokenPrice: number
): LendingVault[] => {
  const res: LendingVault[] = [];
  if(vaults.length === 0) {
    return res;
  }
  const lendingVaults = vaults.filter((item)=> item.lendEnabled);
  lendingVaults.forEach((vault) => {
    const row:LendingVault= {
      address: vault.address,
      underlying: vault.underlying,
      underlyingBalance: "0",
      symbol: vault.uSymbol,
      uDecimals: vault.uDecimals,
      markets: vault.buyAssets ? vault.buyAssets :[],
      marketsSymbols: []
    }
    if (vault.minLendAmountRequired) {
      row.minLendAmountRequired = vault.minLendAmountRequired;
    }
    row.marketsSymbols = row.markets.map((address)=> {
      let symbol = vaults.find((item)=> item.address === address)?.uSymbol
      return symbol ? symbol : '';
    });
    if(data && data[vault.address]) {
      const vaultData =  data[vault.address.toLowerCase()];
      row.exchangeRate = Number(data[vault.address].exchangeRateStored);
      row.underlyingBalance = vaultData.underlyingBalance;
      row.poolApy = calculateApy(vaultData.supplyRatePerBlock, blocksPerDay);
      const tvl = addFixed(vaultData.totalCash, vaultData.totalBorrows);
      const tvlUSD = getUSDPrice(
        vault.uSymbol === 'WEFI' ? wefiTokenPrice :vaultData.oraclePrice, //wefi price is not avaialible on chain
        tvl,
        vault.uDecimals
      );
      row.tvlUsd = Number(tvlUSD);
      if(row.tvlUsd > 0) {
        row.utilization = (Number(vaultData.totalBorrows) / Number(tvl)) * 100;
        row.rewardsApr = getRewardAPR(
          Number(fixed2Decimals(vaultData.supplySpeed, 18)),
          wefiTokenPrice,
          blocksPerDay,
          row.tvlUsd
        )
      }
    }
    res.push(row);
  })
  return res;
}

export const getRewardAPR = (
  rewardSpeed: number, //fixed2Decimals by reward token decimals
  rewardTokenPrice: number,
  blocksPerDay: number, 
  tvlUSD: number): RewardsAPR => {
  let perDayUSD = rewardSpeed * blocksPerDay * rewardTokenPrice;
  if (perDayUSD > 50) {
    return {
      value: perDayUSD,
      suffix: '/Day'
    }
  } else {
    return {
      value: ((
        (rewardSpeed * blocksPerDay * 365 * rewardTokenPrice)
        / tvlUSD
      ) * 100),
      suffix: '%'
    }  
  }
}

interface SupplyPnL {
  symbol: string;
  pnl: AmountWithUSD
}

interface VaultsSupplyPnl {
  vaults: SupplyPnL[],
  pnlUsd: number;
}

export function getSupplyPnL(
  vaults: VaultInterface[],
  vaultsSnapshot: VaultsSnapshot | null,
  events: ContractEvent[]
): VaultsSupplyPnl {
  const data: VaultsSupplyPnl =  {
    vaults: [],
    pnlUsd: 0
  }
  if(vaults.length === 0 || vaultsSnapshot === null) {
    return data;
  }
  vaults.forEach((vault)=> {
    let snapshot = vaultsSnapshot[vault.address.toLowerCase()];
    const filteredEvents = events.filter((e)=> e.eventAddress.toLowerCase() === vault.address.toLowerCase() &&  ['Mint', 'Redeem'].includes(e.eventName));
    if (filteredEvents.length>0 && !isZero(snapshot.lendBalance)) {
      const defaultCurrent = {
        redeemed: "0",
        balance: "0",
        balanceTokens: "0"
      }
      let current = {...defaultCurrent}
      filteredEvents.forEach((event) => {
        if (event.eventName === 'Mint') {
          const eventData = event.eventData as MintEvent;
          current.balance = addFixed(current.balance , eventData.mintAmount);
          current.balanceTokens = addFixed(current.balanceTokens , eventData.mintTokens);
        }

        if (event.eventName === 'Redeem') {
          const eventData = event.eventData as RedeemEvent;
          current.redeemed = addFixed(current.redeemed, eventData.redeemAmount);
          if((Number(current.balance) - Number(eventData.redeemAmount)) <=0) { //using Number as not sure if minusFixed will return negative
            current = {...defaultCurrent}
          } else {
            current.balance = minusFixed(current.balance, eventData.redeemAmount)
            current.balanceTokens = minusFixed(current.balanceTokens, eventData.redeemTokens)
          }
        }
      });
      if(!isZero(current.balance)) {
        const currentBalance = snapshot.lendBalance;
        const deltaAmount = minusFixed(currentBalance, current.balance);
        const deltaUsd = Number(getUSDPrice(snapshot.oraclePrice, deltaAmount, vault.uDecimals));
        data.vaults.push({
          symbol: vault.uSymbol,
          pnl: {
            amount: deltaAmount,
            usd: deltaUsd
          }
        });
        data.pnlUsd += deltaUsd;
      }
      
    }
  })

  return data;
} 