import { ColorHues } from "@chakra-ui/react";

export const Primary:ColorHues = {
  50: '#BDE3FF',
  100: '#6B81F5',
  200: '#415EF7',
  300: '#1B3CF7',
  400: '#0429EF',
  500: '#0429EF',
  600: '#0522B8',
  700: '#051EA3',
  800: '#041A8F',
  900: '#031570',
}

export const Gray = {
  50: '#FFFFFF',
  100: '#F4F5FB',
  150: '#E1E3EB',
  200: '#C2C3CC',
  300: '#9B9DAC',
  400: '#828496',
  500: '#6E7186',
  600: '#515466',
  700: '#3E4151',
  800: '#292B38',
  900: '#191B24'
}