import { useState, useEffect } from 'react'
import { debounceInputdeplay } from '../constants';
import { Input, InputRightElement, Button, InputGroup, Flex, Box, HStack, Badge, Img, Text } from '@chakra-ui/react';
import { getTokenImage } from '../utils/image';

interface Props {
  type: string;
  variant: string;
  value: string;
  balanceIn: string;
  onChange: (value:any) => void;
  symbol?: string
}

export default function DebouncedInput(props: Props) {
  const [value, setValue] = useState<string>('0');

  useEffect(() => {
      const getData = setTimeout(() => {
        props.onChange(value);
      }, debounceInputdeplay);
      
      return () => clearTimeout(getData);
    }, [value]);

  return (
    <InputGroup w='100%' borderLeft='1px solid' borderColor='gray.150' pl='10px'>
      <Input
          variant={props.variant}
          type={props.type}
          onChange={(event) => setValue(event.target.value)}
          value={value}
      />
      <InputRightElement>
              <Button
                w='50px'
                top= '-6px'
                variant='ghost'
                colorScheme='primary'
                size='sm'
                onClick={()=> setValue(props.balanceIn)}
              >
                MAX
              </Button>
        </InputRightElement>
    </InputGroup>
  )
}

export function DebouncedInput2(props: Props) {
  const [value, setValue] = useState<string>('0');

  useEffect(() => {
      const getData = setTimeout(() => {
        props.onChange(value);
      }, debounceInputdeplay);
      
      return () => clearTimeout(getData);
    }, [value]);

  return (

    <Flex>
      <Box w='60%'>
          <Input
          variant={props.variant}
          type={props.type}
          onChange={(event) => setValue(event.target.value)}
          value={value}
        />
      </Box>
      <HStack w='40%' justifyContent='flex-end' spacing={0}>
        <Badge
          cursor='pointer'
          color='primary.900'
          textTransform='capitalize'
          bg='transparent'
          borderColor='gray.150'
          border='1px solid'
          borderRadius='14px'
          textStyle='label'
          onClick={()=> setValue(props.balanceIn)}
          me={2}
          >Max</Badge>
        {props.symbol && <Img h='16px' src={getTokenImage(props.symbol)} me={1}/>}
        {props.symbol && <Text textStyle='labelBold' color='primary.900'>{props.symbol}</Text>}
      </HStack>
    </Flex>
  )
}