import { Address } from "viem";
import { VaultsSnapshot } from "../atoms";
import { VaultInterface } from "../constants/interfaces";
import { addFixed, calculateApy, decimal2Fixed, fixed2Decimals, getUSDPrice, isGreaterThan, isGreaterThanOrEqualTo, minusFixed, mulFixed } from "./helpers";
import { AmountWithUSD } from "./loanPositions";
import { BorrowEvent, ContractEvent, LiquidateBorrowEvent, RedeemEvent, SwapAndSettleEvent } from "./events/interfaces";
import { RewardsAPR, getRewardAPR } from "./lending";

export interface ActiveInvestmentVault {
  address: Address;
  symbol: string;
  uDecimals: number;
  underlying: Address;
  avgCost: string[];
  amount: number;
  amountUsd: number;
  pnl: number;
}

export const getActiveInvestmentVaults = (
  vaults: VaultInterface[],
  data: VaultsSnapshot | null,
  events: ContractEvent[]
): ActiveInvestmentVault[] => {
  const res:ActiveInvestmentVault[] = [];
  if(data === null || vaults.length === 0) {
    return res;
  }
  const investmentVaults = vaults.filter((item)=> item.buyEnabled);
  const borrowVaults = vaults.filter((item)=> item.borrowEnabled);
  investmentVaults.forEach((vault)=> {
    const vaultSnapshot = data[vault.address];
    if(vaultSnapshot) {
      const amount = vaultSnapshot.lendBalance;
      if (isGreaterThan(amount, 0)) {
        const avgCost: string[] = [];
        let pnl = 0;
        if(events.length > 0) {
          const details = getInvestmentDetails([vault, ...borrowVaults], data, events);
          if (details.length === 1) {
            details[0].borrowedAssets.forEach((asset) => {
              avgCost.push(`${asset.avgCost} ${asset.symbol}`)
            });
            pnl = details[0].pnl;
          }
        }
        const amountParsed= Number(fixed2Decimals(amount, vault.uDecimals));
        res.push({
          address: vault.address,
          symbol: vault.uSymbol,
          uDecimals: vault.uDecimals,
          underlying: vault.underlying,
          amountUsd: Number(getUSDPrice(vaultSnapshot.oraclePrice, amount, vault.uDecimals)),
          avgCost,
          amount: amountParsed,
          pnl
        })
      }
    }
  });
  return res;
}

export interface BorrowVault {
  address: Address;
  symbol: string;
  underlying: Address;
  underlyingBalance: string;
  uDecimals: number;
  leverage: number;
  borrowApy: number;
  liquidity: number;
  liquidityUsd: number;
  rewardsApr?: RewardsAPR;
  priceUsd?: number;
}

export interface InvestmentVault {
  address: Address;
  underlying: Address;
  symbol: string;
  uDecimals: number;
  borrowVaults: BorrowVault[];
  priceUsd?: number;
  leverage?: number;
  interestRates?: string;
  liquidityUsd?: number;
}

export const getInvestmentVaults = (
  vaults: VaultInterface[],
  data: VaultsSnapshot | null,
  blocksPerDay: number,
  wefiTokenPrice: number
): InvestmentVault[] => {
  const res: InvestmentVault[] = [];
  if(vaults.length === 0) {
    return res;
  }
  const investmentVaults = vaults.filter((item)=> item.buyEnabled);
  investmentVaults.forEach((vault) => {
    const row:InvestmentVault= {
      address: vault.address,
      symbol: vault.uSymbol,
      uDecimals: vault.uDecimals,
      underlying: vault.underlying,
      borrowVaults: [],
      interestRates: "---",
      leverage: 0,
      liquidityUsd: 0
    }
    if(data && data[vault.address]) {
      const vaultData =  data[vault.address.toLowerCase()];
      row.priceUsd = Number(vaultData.oraclePrice);
      vault.borrowAssets?.forEach((address:Address)=> {
        const borrowVaultData = data[address.toLowerCase()];
        const borrowVault = vaults.find((item)=> item.address.toLowerCase() === address.toLowerCase());
        if(borrowVault && borrowVaultData) {
          const leverage = Number(fixed2Decimals(borrowVaultData.buyFactor, 18));
          const borrowApy = calculateApy(
            borrowVaultData.borrowRatePerBlock,
            blocksPerDay
          );
          const liquidity = fixed2Decimals(borrowVaultData.totalCash, borrowVault.uDecimals);
          const liquidityUsd = Number(getUSDPrice(
            borrowVaultData.oraclePrice,
            borrowVaultData.totalCash,
            borrowVault.uDecimals
          ));
          const totalBorrowsUsd = Number(getUSDPrice(
            borrowVaultData.oraclePrice,
            borrowVaultData.totalBorrows,
            borrowVault.uDecimals
          ))
          row.borrowVaults.push({
            address: address,
            symbol: borrowVault.uSymbol,
            underlying: borrowVault.underlying,
            underlyingBalance: borrowVaultData.underlyingBalance,
            uDecimals: borrowVault.uDecimals,
            leverage,
            borrowApy,
            liquidity: Number(liquidity),
            liquidityUsd,
            rewardsApr: totalBorrowsUsd > 0 ? getRewardAPR(
              Number(fixed2Decimals(borrowVaultData.borrowSpeed, 18)), wefiTokenPrice, blocksPerDay, totalBorrowsUsd
              ) : {value: 0, suffix: '%'},
            priceUsd: Number(borrowVaultData.oraclePrice)
          });
          row.liquidityUsd = (row.liquidityUsd ? row.liquidityUsd : 0) + liquidityUsd;
          if(!row.leverage || leverage > row.leverage) {
            row.leverage = leverage;
          }
        } 
      });
    }
    const apys = row.borrowVaults
    .filter((v)=> v.borrowApy > 0)
    .map((v)=> v.borrowApy)
    .sort((prev, next)=> prev - next);
    if(apys.length > 1) {
      row.interestRates=[apys[0].toFixed(2), apys[apys.length-1].toFixed(2)].join('-') + '%';
    } else if( apys.length === 1) {
      row.interestRates = `${apys[0].toFixed(2)}%`;
    }
    res.push(row);
  })
  return res;
}


export interface BorrowedAsset {
  address: string;
  symbol: string;
  decimals: number; 
  downpayment: AmountWithUSD;
  borrowed: AmountWithUSD;
  avgCost: string; 
}

export interface InvestmentDetail {
  address: string;
  symbol: string;
  decimals: number;
  balance: AmountWithUSD;
  balanceCurrent: string; // calculated from events
  pnl: number;
  borrowedAssets: BorrowedAsset[],
  redeemed: string;
  borrowedUsd: number;
}

export function getInvestmentDetails(
  vaults: VaultInterface[],
  vaultsSnapshot: VaultsSnapshot | null,
  events: ContractEvent[]
): InvestmentDetail[] {
  const details: InvestmentDetail[] = [];
  const investmentVaults = vaults.filter((v)=> v.buyEnabled);
  const borrowVaults = vaults.filter((v)=> v.borrowEnabled);

  investmentVaults.forEach((vault)=> {
    const defaultDetail:InvestmentDetail =  {
      address: vault.address.toLowerCase(),
      symbol: vault.uSymbol,
      decimals: vault.uDecimals,
      balance: { amount: "0", usd: 0},
      balanceCurrent: "0",
      borrowedAssets: [],
      redeemed: "0",
      pnl:0,
      borrowedUsd: 0
    }
    const detail: InvestmentDetail = {...defaultDetail}

    const snapshot = vaultsSnapshot ? vaultsSnapshot[vault.address.toLowerCase()] : null;
    if(snapshot) {
      detail.balance.amount = snapshot.lendBalance;
      detail.balance.usd = Number(getUSDPrice(snapshot.oraclePrice, snapshot.lendBalance, vault.uDecimals));
    }

    const filteredEvents = events.filter((e)=> {
      if (e.eventAddress.toLowerCase() === vault.address.toLowerCase() &&
      ['SwapAndSettle', 'Redeem'].includes(e.eventName)
      ){
        return true;
      } else if (e.eventName === 'Borrow') { 
        const eData = e.eventData as BorrowEvent;
        return eData.boughtAsset.toLowerCase() === vault.address.toLowerCase();
      } else if (e.eventName === 'LiquidateBorrow') {
        const eDdata = e.eventData as LiquidateBorrowEvent;
        return eDdata.cTokenCollateral.toLowerCase() === vault.address.toLowerCase();
      } else {
        return false;
      }
    });
      
    if(filteredEvents.length > 0) {
      filteredEvents.forEach((ev)=> {
        const eventName= ev.eventName;
        if(eventName === 'Borrow') {
          const eventData = ev.eventData as BorrowEvent;
          const borrowVault = borrowVaults.find((v)=>v.address.toLowerCase() === ev.eventAddress.toLowerCase());
          const boughtAmount = eventData.boughtAssetAmount;
          detail.balanceCurrent = addFixed(detail.balanceCurrent, boughtAmount);
          if(borrowVault) {
            let borrowedAsset: BorrowedAsset | undefined = detail.borrowedAssets ? 
              detail.borrowedAssets.find((a)=> a.address === ev.eventAddress.toLowerCase())
            : undefined;
            let borrowVaultSnapshot = vaultsSnapshot ? vaultsSnapshot[borrowVault.address.toLowerCase()] : null;
            if(borrowVaultSnapshot) {
              detail.borrowedUsd += Number(getUSDPrice(borrowVaultSnapshot.oraclePrice, eventData.borrowAmount, borrowVault.uDecimals));
              if(!borrowedAsset) {
                borrowedAsset = {
                  address: ev.eventAddress.toLowerCase(),
                  symbol: borrowVault.uSymbol,
                  decimals: borrowVault.uDecimals,
                  downpayment: {
                    amount: eventData.paidAmount,
                    usd: Number(getUSDPrice(borrowVaultSnapshot.oraclePrice, eventData.paidAmount, borrowVault.uDecimals))
                  },
                  borrowed: {
                    amount: eventData.borrowAmount,
                    usd: Number(getUSDPrice(borrowVaultSnapshot.oraclePrice, eventData.borrowAmount, borrowVault.uDecimals))
                  },
                  avgCost: (
                    Number(fixed2Decimals(addFixed(eventData.paidAmount, eventData.borrowAmount), borrowVault.uDecimals))
                    /
                    Number(fixed2Decimals(boughtAmount, vault.uDecimals))
                  ).toFixed(2)
                }
                detail.borrowedAssets.push(borrowedAsset);
              } else {
                borrowedAsset.downpayment.amount = addFixed(borrowedAsset.downpayment.amount, eventData.paidAmount);
                borrowedAsset.downpayment.usd += Number(getUSDPrice(borrowVaultSnapshot.oraclePrice, eventData.paidAmount, borrowVault.uDecimals));
                borrowedAsset.borrowed.amount = addFixed(borrowedAsset.borrowed.amount, eventData.borrowAmount);
                borrowedAsset.borrowed.usd += Number(getUSDPrice(borrowVaultSnapshot.oraclePrice, eventData.borrowAmount, borrowVault.uDecimals));
                borrowedAsset.avgCost  = (
                  (
                    Number(borrowedAsset.avgCost) + 
                    (
                      Number(fixed2Decimals(addFixed(eventData.paidAmount, eventData.borrowAmount), borrowVault.uDecimals))
                      /
                      Number(fixed2Decimals(boughtAmount, vault.uDecimals))
                    )
                  )/
                  2
                ).toFixed(2);
              }
            }
          }
        }
        if (eventName === 'SwapAndSettle' || eventName === 'Redeem' || eventName === 'LiquidateBorrow') {
          let redeemAmount = "0";
          if (eventName === 'LiquidateBorrow') {
            const eventData = ev.eventData as LiquidateBorrowEvent;
            redeemAmount = mulFixed(eventData.seizeTokens, 0.02);
            redeemAmount = fixed2Decimals(redeemAmount, vault ? vault.uDecimals : 18);
            redeemAmount = decimal2Fixed(redeemAmount, vault ? vault.uDecimals : 18);
          } else if (eventName === 'SwapAndSettle') {
            const eventData = ev.eventData as SwapAndSettleEvent;
            redeemAmount = eventData.numTokensAmount;
          } else {
            const eventData = ev.eventData as RedeemEvent;
            redeemAmount = eventData.redeemAmount;
          }
          if (isGreaterThanOrEqualTo(redeemAmount, detail.balanceCurrent)) {
            detail.balanceCurrent = "0";
            detail.redeemed = "0";
            detail.borrowedAssets = [];
            detail.borrowedUsd = 0;
          } else {
            detail.redeemed = addFixed(detail.redeemed, redeemAmount);
            detail.balanceCurrent = minusFixed(detail.balanceCurrent, redeemAmount);
          }
        }

      });
    }
    details.push(detail);
  });
  //calculate overall pnl
  details.forEach((detail) => {
    detail.pnl = getInvestmetnPnl(detail);
  })
  return details;
}

function getInvestmetnPnl(detail: InvestmentDetail):number {
  let pnl = 0
  let totalBorrowedUsd = 0;
  let totalDownpaymentUsd = 0;
  detail.borrowedAssets.forEach((item)=> {
    totalBorrowedUsd += item.borrowed.usd;
    totalDownpaymentUsd += item.downpayment.usd;
  })
  const totalPaid = (totalBorrowedUsd + totalDownpaymentUsd);
  if(totalPaid > 0) {
    pnl = detail.balance.usd - totalPaid;
  }
  return pnl;
}

interface OverallInvestmentStats {
  // totalValueUsd: number;
  totalBorrowedUsd: number;
  pnlUsd: number;
}

export function getOverallInvestmentStats(
  vaults: VaultInterface[],
  snapshot : VaultsSnapshot | null,
  events: ContractEvent[]
): OverallInvestmentStats {
  const detailsData = getInvestmentDetails(vaults, snapshot, events);
  const data: OverallInvestmentStats = {
    pnlUsd: detailsData.reduce((total, item)=> total + item.pnl , 0),
    totalBorrowedUsd: detailsData.reduce((total, item)=> total + item.borrowedUsd , 0)
  }
  return data;
}
