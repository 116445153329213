import { Box, Button, Flex, HStack, Icon, Image, Img, Text, useBreakpointValue } from '@chakra-ui/react'
import StatsBlock from './ProtocolStatsBlock'
import ImageGift from '../images/graphics/gift.svg';
import ImageGiftBG from '../images/graphics/giftBg.svg';
import IconWeFi from '../images/tokens/wefi.svg';

import { atomRewardsAccrued } from '../atoms';
import { useAtom } from 'jotai';
import { fixed2Decimals, isZero } from '../utils/helpers';
import { useState } from 'react';
import { ModalClaimRewards } from './ModalClaimRewards';
import { useContractReads, useNetwork } from 'wagmi';
import { MUMBAI_CHAIN_ID, getAbi, getContractAddress } from '../constants';
import moment from 'moment';
import { FiCalendar, FiClock } from 'react-icons/fi';
import useDaysCounter from '../hooks/useDaysCounter';
import { getRewardTokenIcon } from '../utils/image';

export function RewardStats() {
  const {chain} = useNetwork();
  const [amount] = useAtom(atomRewardsAccrued);
  const [showModal, setShowModal] = useState(false);
  const contractComptroller = {
    address: getContractAddress({chainId: chain?.id, contractName: 'Comptroller'}),
    abi:  getAbi({chainId: chain?.id, abiFile: 'Comptroller'})
  }
  const {data, isLoading} = useContractReads({
    // enabled: !isZero(amount),
    contracts: [
      {
        ...contractComptroller,
        functionName: chain?.id === MUMBAI_CHAIN_ID ? 'compClaimThresholdDays' : 'compClaimWindowDays'
      },
      {
        ...contractComptroller,
        functionName: 'compClaimEpoch'
      },
      {
        ...contractComptroller,
        functionName: 'isCompClaimCliffEnabled'
      }
    ]
  })
  const isCliffEnabled = data ? data[2].error ? true: data[2].result : true;

  const claimEpoch = data ? Number(data[1].result) : 0;
  const claimThresholdDays = data ? Number(data[0].result) : 0;
  const claimAvailableTill = claimEpoch + (claimThresholdDays * 24*60*60);
  let now = Number(moment().format("X"));
  const showDetails = ( isCliffEnabled && data && claimEpoch && claimThresholdDays );
  return (
    <Flex 
      gap='24px' 
      flexDirection={useBreakpointValue({base: 'column', md: 'row'})}
      px='56px'
      py='21px'
      bgImage={showDetails ? "" : ImageGiftBG}
      bgPos='right bottom'
      bgRepeat='no-repeat'
      borderBottomRadius='16px'
    >
      <StatsBlock
        w={{base: 'full', md:'33%', sm: '100%'}}
        title='Rewards Accumulated'
        value={<HStack>
          <Img height='20px' src={getRewardTokenIcon(chain?.id)} />
          <Text>{fixed2Decimals(amount, 18, 4)}</Text>
        </HStack>}
        // value='$341.64'
        valueColor='green.500'
        graphic={<Image src={ImageGift}/>}
      />
      {isCliffEnabled === false && <Button mt={2}
        isDisabled={isZero(amount)}
        size='sm'
        colorScheme='primary'
        onClick={()=> setShowModal(true)}
      >
        Claim Rewards
      </Button>}
      {showDetails ? <Flex
        flexDirection={{base: 'column', sm: 'column', md: 'row'}}
        w={{base: 'full', md:'66%',  sm: '100%'}}
        alignItems='flex-start'
      >
        {now >= claimEpoch && now <= claimAvailableTill ? <Box  w={{base: 'full', md:'50%',  sm: '100%'}}>
          <HStack alignItems='center'>
            <Icon fontSize='20px' color='green.500' as={FiClock}/>
            <Text color='green.500' textStyle='heading-3'> Open For Claim</Text>
          </HStack>
          <Text mt='4px' color='gray.500' textStyle='body-3'>Tokens ready to claim</Text>
          <Button mt={2}
            isDisabled={isZero(amount)}
            size='sm'
            colorScheme='primary'
            onClick={()=> setShowModal(true)}
          >
            Claim Rewards
          </Button>
        </Box> : <Box  w={{base: 'full', md:'50%',  sm: '100%'}}>
          <HStack alignItems='center'>
            <Icon fontSize='20px' color='primary.800' as={FiClock}/>
            <Text color='primary.800' textStyle='heading-3'><DaysCounter targetTime={claimEpoch * 1000}/></Text>
          </HStack>
          <Text mt='4px' color='gray.500' textStyle='body-3'>Next epoch for rewards claim opens in</Text>
        </Box>}
        <Box w={{base: 'full', md:'50%',  sm: '100%'}} mt={{base: 5, sm: 5, md: 0}}>
          <HStack alignItems='center'>
            <Icon fontSize='20px' color='primary.800' as={FiCalendar}/>
            <Text color='primary.800' textStyle='subheading-1'>
              {moment.unix(claimEpoch).format('MMM DD, HH:mm')} - {moment.unix(claimAvailableTill).format("MMM DD, HH:mm ")}</Text>
          </HStack>
          <Text mt='4px' color='gray.500' textStyle='body-3'>Open dates for rewards claim (GMT {moment().format("Z")})</Text>
        </Box>
      </Flex>: null}
      {showModal && <ModalClaimRewards
        amount={amount}
        contract={contractComptroller.address}
        abi={contractComptroller.abi}
        isOpen={showModal}
        onClose={()=> setShowModal(false)}
      />}
    </Flex>
  )
}

function DaysCounter({targetTime}:{targetTime: number}) {
  const value = useDaysCounter(targetTime);
  return <span>{value}</span>
}