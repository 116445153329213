import { Box, Text } from "@chakra-ui/react";
import { useAccount, useBalance, useNetwork } from "wagmi";
import { fixed2Decimals } from "../../utils/helpers";
import { getContractAddress } from "../../constants";

export function WeFiBalance() {
  const {address} = useAccount();
  const {chain} = useNetwork();
  const {data} = useBalance({
    address,
    token: getContractAddress({chainId: chain?.id, contractName: "WEFI"}),
    watch: true
  })
  return <Box>
    <Text textStyle='heading-3' color='primary.900'>
      You have {data && fixed2Decimals(data.value.toString(), 18, 4)} WEFI available to stake.
    </Text>
    <Text textStyle='body-1' color='gray.400'>
      Lock your WEFI tokens to receive veWEFI, the unit of WeFi governance voting power. WeFi is governed entirely by WeFi token holders via voting escrow.
    </Text>
  </Box>
}