import { Box, Button, Input, InputGroup, InputRightElement, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { decimal2Fixed, divFixed, fixed2Decimals, isGreaterThan, isGreaterThanOrEqualTo, isLessThan, isLessThanOrEqualTo, isZero } from '../utils/helpers';
import { useAccount, useContractRead, useNetwork } from 'wagmi';
import { useState } from 'react';
import { zeroAddress } from 'viem';
import { getAbi, getContractAddress } from '../constants';
import { WithdrawVault } from './ModalWithdraw';

interface WithdrawProps extends WithdrawVault{
  sendWithdraw: (tokens: string, amount: string) => void
}

export function Withdraw({
  address,
  balanceTokens,
  balanceAmount,
  uSymbol,
  uDecimals,
  exchangeRate,
  sendWithdraw
}: WithdrawProps) {
  const [amountWithdraw, setAmountWithdraw] = useState<string>("0");
  const {address: userAddress} = useAccount();
  const {chain} = useNetwork();

  const amountToTokens = (amount:string) => {
    return divFixed(decimal2Fixed(amount, uDecimals), fixed2Decimals(exchangeRate, 18));
  }

  const { data : hypotheticalLiq}: {data: any} = useContractRead({
    abi: getAbi({chainId: chain?.id, abiFile: 'Comptroller'}),
    address: getContractAddress({chainId :chain?.id, contractName: 'Comptroller'}),
    functionName: 'getHypotheticalAccountLiquidity',
    args: [userAddress || zeroAddress, address, amountToTokens(amountWithdraw), 0]
  });

  
  const handleMax = ()=> { 
    if(Number(balanceAmount) > 0) {
      setAmountWithdraw(
        fixed2Decimals(balanceAmount, uDecimals)
      );
    }
  }
  
  return (
    <Box mt='12px'>
      <Box textAlign='center' color='primary.900'>
        <Text textStyle='body-3'>Withdraw from</Text>
        <Text textStyle='body-bold-1'>{uSymbol} Pool</Text>
      </Box>
      <InputGroup mt='20px' borderRadius='12px'>
        <Input
          value={amountWithdraw}
          variant='filled'
          type='number'
          size='lg'
          onChange={(e)=> setAmountWithdraw(e.target.value)}
        />
        <InputRightElement mx='16px' my='8px'>
          <Button variant='ghost' colorScheme='primary' onClick={()=> handleMax()}>
            MAX
          </Button>
        </InputRightElement>
      </InputGroup>
      <Text
        textStyle='body-bold-3'
        color='primary.900'
        opacity='0.64'
        textAlign='right'
        mt='12px'
      >
        Invested: {fixed2Decimals(balanceAmount, uDecimals)} {uSymbol}
      </Text>
      { isGreaterThan(amountWithdraw, 0)
        && isGreaterThanOrEqualTo(balanceAmount, decimal2Fixed(amountWithdraw, uDecimals))
        && hypotheticalLiq
        && isZero(hypotheticalLiq[0])
        && !(isZero(hypotheticalLiq[1]) && isZero(hypotheticalLiq[2]))
        && <VStack mt='28px'>
        <SimpleGrid
          columns={1}
          borderBottom='1px solid'
          py="12px"
          borderColor='gray.150'
          w="100%"
        >
          <Text
            textStyle="labelBold"
            color='primary.900'
            textTransform='uppercase'
          >
            Portfolio after withdrawl
          </Text>
        </SimpleGrid>
        {/* <SimpleGridf
          columns={2} spacing='20px'
          borderBottom='1px solid'
          py="12px"
          borderColor='gray.150'
          w="100%"
        >
          <Text
            textStyle="body-bold-3"
            color='primary.900'
            opacity='0.64'
          >
            Portfolio Value
          </Text>
            <Text textStyle="body-bold-2"
              color='primary.900'
              justifySelf='flex-end'
            >
            </Text>
        </SimpleGrid> */}
        <SimpleGrid
          columns={2} spacing='20px'
          py="12px"
          w="100%"
        >
          <Text
            textStyle="body-bold-3"
            color='primary.900'
            opacity='0.64'
          >
            Account Liquidity
          </Text>
        
          {isZero(hypotheticalLiq[2]) ? <Text textStyle="body-bold-2"
            color='primary.900'
            justifySelf='flex-end'
          >${fixed2Decimals(hypotheticalLiq[1], 18 ,2)}</Text> : <Text textStyle="body-bold-2"
            color='red.500'
            justifySelf='flex-end'
          >-${fixed2Decimals(hypotheticalLiq[2], 18 ,2)}</Text>}
        </SimpleGrid>
      </VStack>}
      <Button
        isDisabled={
          isLessThanOrEqualTo(amountWithdraw, 0) ||
          isLessThan(balanceAmount, decimal2Fixed(amountWithdraw, uDecimals)) ||
          (hypotheticalLiq === undefined ? true : !isZero(hypotheticalLiq[2]))
        }
        mt='28px'
        w='full'
        colorScheme='primary'
        size='lg'
        onClick={()=> {
          sendWithdraw(amountWithdraw, amountToTokens(amountWithdraw))
        }}
      >
        Withdraw
      </Button>
    </Box>
  )
}