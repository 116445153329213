import { Center, Img, Modal, ModalContent, ModalOverlay, UseModalProps, Box, Button, Icon, Link, SimpleGrid, Text, VStack, Divider  } from "@chakra-ui/react";
import { getRewardTokenIcon, getTokenImage } from "../utils/image";
import { Address, useAccount, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { useAtom } from "jotai";
import { atomPendingHashWrite } from "../atoms";
import { useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";
import { PiCheckCircleFill, PiWarningCircleBold, PiWarningOctagonDuotone } from "react-icons/pi";
import LoadingGif from '../images/loading.gif';
import { getBlockExplorer } from "../constants";
import { fixed2Decimals, shortAddress } from "../utils/helpers";

interface ModalClaimRewardsProps extends UseModalProps {
  amount: string;
  contract: Address | undefined;
  abi: any
}

export function ModalClaimRewards({
  amount,
  contract,
  abi,
  isOpen,
  onClose,
}: ModalClaimRewardsProps) {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const [ , setHash] = useAtom(atomPendingHashWrite);

  const {
    config,
    error: prepareError,
    isError: isPrepareError
  } = usePrepareContractWrite({
    address: contract,
    abi: abi,
    functionName: 'claimComp',
    args: [address]
  });

  const { data, error, isLoading, isError, write } = useContractWrite(config);

  useEffect(()=> {
    if (write) {
      write();
    }
  }, [write]);

  useEffect(() => {
    if(data?.hash ) {
      setHash(data.hash);
    }
  }, [data?.hash])

  const {data: txData, isLoading: isTxLoading, isSuccess}  = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
      <ModalOverlay/>
      <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getRewardTokenIcon(chain?.id)}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        <Box textAlign='center' color='primary.900'>
          <Text textStyle='body-3'>Claim Rewards</Text>
          <Text textStyle='body-bold-1'>{chain?.id === 50 ? 'FXD' : 'WEFI'} Tokens</Text>
        </Box>
        <Box textAlign='center'>
          {(isLoading || isTxLoading) && <Img
            display='inline-block'
            height='96px'
            width='96px'
            src={LoadingGif} 
          />}

          {( isPrepareError || isError) && <VStack spacing='20px' mt='20px'>
            <Icon as={PiWarningOctagonDuotone} fontSize={'5xl'} color='orange.300'/>
            <Box>
              <Text color='primary.900' textStyle='body-bold-1'>
                Failed to initiate transaction
              </Text>
              <Text wordBreak='break-word' color='orange.400' textStyle='body-bold-3'>
                { (prepareError || error)?.name }
              </Text>
            </Box>
            <Button onClick={()=> onClose()}>Close</Button>
          </VStack>}

          {isLoading && <Box>
            <Text textStyle='body-bold-1' color='primary.900'>
              Awaiting Txn to initiate.
            </Text>
            <Text textStyle="body-bold-3" color="gray.500">
              Please go to your wallet and <br/> allow this transaction to continue.
            </Text>
          </Box>}

          {isTxLoading && <Box>
            <Text textStyle='body-bold-1' color='primary.900'>
              Awaiting transaction to complete.
            </Text>
            <Link
              textStyle="body-bold-3"
              color="gray.500"
              isExternal
              href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}
            >
              View in Explorer {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
            </Link>
          </Box>}

          {isSuccess && <VStack spacing="32px" mt='20px'>
            <Box>
              {txData?.status === 'success' ? <Text textStyle='body-bold-3' color='green.500'>
                Rewards Claimed <Icon as={PiCheckCircleFill}/>
              </Text> : <Text textStyle='body-bold-3' color='red.500'>
                Txn Reverted <Icon as={PiWarningCircleBold}/>
              </Text>}
            </Box>
            <Box w='100%'>
              <SimpleGrid
                columns={2} spacing='20px'
                py="12px"
                borderColor='gray.150'
                w="100%"
              >
                <Text
                  textStyle="body-3"
                  color='primary.900'
                  opacity='0.6399999856948853'
                  textAlign='left'
                >
                  Txn Hash
                </Text>
                <Text
                  textStyle="body-3"
                  color='primary.900'
                  opacity='0.6399999856948853'
                  justifySelf='flex-end'
                >
                  <Link isExternal href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}>
                    {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
                  </Link>
                </Text>
              </SimpleGrid>
            </Box>
            <Box w='100%'>
              {txData?.status === 'success' ? <Button
                size="lg"
                w="100%"
                colorScheme='primary'
                mb="16px"
                onClick={()=> onClose()}
              >Done</Button> :
              <Button
                size="lg"
                w="100%"
                onClick={()=> onClose()}
              >
                Close
              </Button>}
            </Box>
          </VStack>}
        </Box>
      </ModalContent>
    </Modal>
  )
}
