import { Center, Divider, Flex, Image, useBreakpointValue } from '@chakra-ui/react'
import StatsBlock from './ProtocolStatsBlock'
import ImageBars from '../images/graphics/bars.svg';
import ImageCrypto from '../images/graphics/crypto.svg';
import ImageCryptoCards from '../images/graphics/cryptoCards.svg';
import { VaultsSnapshot } from '../atoms';
import { VaultInterface } from '../constants/interfaces';
import { getProtocolStats } from '../utils/protocolStats';
import { formatVolume } from '../utils/helpers';
import { useAtom } from 'jotai';
import { atomChainTVL, atomDefaultChainID } from '../atoms';
import { useAccount, useNetwork } from 'wagmi';

export function ProtocolStats({
  vaults, vaultsSnapshot
}: {vaults: VaultInterface[], vaultsSnapshot: VaultsSnapshot | null}) {
  let isMobile = useBreakpointValue({base: true, md: false});
  const stats = getProtocolStats(vaults, vaultsSnapshot);
  const {isConnected} = useAccount();
  const {chain} = useNetwork();
  const [chainTVL] = useAtom(atomChainTVL);
  const [defaultChainId] = useAtom(atomDefaultChainID);

  const tvl = chainTVL[isConnected ? chain?.id ? chain.id : 0 : defaultChainId];
  
  return (
    <Flex 
      gap='24px' 
      flexDirection={useBreakpointValue({base: 'column', sm: 'column', md: 'row'})}
      border='1px solid'
      borderColor='gray.150'
      borderRadius='16px'
      px='56px'
      py='21px'
      bgColor='white'
    >
      <StatsBlock
        w={{base: 'full', md:'33%', sm: '100%'}}
        title='Total Value Locked'
        value={stats.tvlUsd ? `$ ${formatVolume(stats.tvlUsd)}` : '---'}
        graphic={<Image src={ImageBars}/>}
      />
      <Center height={isMobile ? '0px': '50px'}>
        <Divider orientation={isMobile ? 'horizontal': 'vertical'} />
      </Center>
      <StatsBlock
        w={{base: 'full', md:'33%',  sm: '100%'}}
        title='Total Trading Volume'
        value={tvl ? `$ ${formatVolume(tvl)}` : '---'}
        graphic={<Image src={ImageCrypto}/>}
      />
      <Center height={isMobile ?  '0px': '50px'}>
        <Divider orientation={isMobile ? 'horizontal': 'vertical'} />
      </Center>
      <StatsBlock
        w={{base: 'full', md:'33%',  sm: '100%'}}
        title='Total Borrowed Value'
        value={stats.borrowedUsd ? `$ ${formatVolume(stats.borrowedUsd)}` : ''}
        graphic={<Image src={ImageCryptoCards}/>}
      />
    </Flex>
)
}