import { Box, Divider, Flex, HStack, Link, Icon, Text } from "@chakra-ui/react";
import {FiExternalLink} from 'react-icons/fi';
export function BridgePage() {
  
  return <Flex justifyContent='center'>
    <Box
      p='20px' w={{sm: '100%', base: '100%', md: '55%'}}
      border='1px solid'
      borderColor='gray.150'
      borderRadius='16px'
    >
      <Text textStyle='heading-3' color='gray.800'>
        Trying to bridge to XDC Network? 
      </Text>
      <HStack textDecoration='underline' as={Link} color='primary.400' isExternal={true} target="_blank" href="https://bridge.wanchain.org/">
        <Text  textStyle='body-1'>
         Transfer USDT from various networks to XDC Network
        </Text>
        <Icon as={FiExternalLink}/>
      </HStack>
     
      <Divider mt={5}/>
      {/* @ts-ignore */}
      <stargate-widget
        theme='light'
        partnerId="0x0024"
        feeCollector="0xd08b37B15134c158DABcD4d09ea0207465Ea5e4D"
        tenthBps="100"
      />
    </Box>
  </Flex>
}

