import { Address } from "viem";
import { atomRefetchEvents, atomRefetchEventsWrite, atomUserEventsWrite } from "../atoms";
import { useAtom } from "jotai";
import { ContractEvent } from "../utils/events/interfaces";
import { useAccount, useNetwork } from "wagmi";
import { useEffect } from "react";
import { processEventsAPIData } from "../utils/events";
import { getEventsAPIChainSlug } from "../constants";

export default function useEventsFetch() {
  const {chain} = useNetwork();
  const {address} = useAccount();
  const [refetchEvents] = useAtom(atomRefetchEvents);
  const [, setRefetchEvents] = useAtom(atomRefetchEventsWrite);
  const [, saveEvents] = useAtom(atomUserEventsWrite);
  useEffect(()=> {
    const fetchData = (address: Address | undefined, chainId: number | undefined) => {
      let chainSlug = getEventsAPIChainSlug(chainId)
      if(chainSlug === "") {
        return;
      }
      fetch(`https://ayn1ewl5ck.execute-api.ap-south-1.amazonaws.com/${getEventsAPIChainSlug(chainId)}/transactions/${chainId === 50 ? (address? address: '') : (address? address.toLowerCase(): '')}`)
      .then((res)=> res.json())
      .then((resJson) => {
        if(resJson.success) {
          saveEvents(processEventsAPIData(resJson.response.data.userEvents as ContractEvent[]));
        }
      })
      .catch((err)=> {
        saveEvents([]);
        console.log("error while fetching txns", err);
      })
    }

    if ((address && chain?.id && !chain?.unsupported) || refetchEvents) {
      fetchData(address, chain?.id);
      if(refetchEvents) {
        setTimeout(()=> fetchData(address, chain?.id), 15000);
        setTimeout(()=> fetchData(address, chain?.id), 30000);
        setRefetchEvents(false);
      }
    } else {
      saveEvents([]);
    }
  }, [chain, address, saveEvents, refetchEvents])

}