import { ComponentStyleConfig } from "@chakra-ui/react";
import { textStyles } from "../textStyles";

export const tableStyles: ComponentStyleConfig = {
    variants: {
      simple: {
        th: {
          ...textStyles['body-2'],
          color: 'gray.500',
          textTransform: 'none',
          px: '16px',
          py: '20px'
        },
        td: {
            ...textStyles['body-bold-1'],
            px: '16px',
            py: '20px',
            color: "primary.900"
        }
      }
    }
  }