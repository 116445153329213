import { Address, zeroAddress } from "viem";
import LoadingGif from '../images/loading.gif';
import { Box, Button, Icon, Img, Link, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useAccount, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { useEffect, useState } from "react";
import { decimal2Fixed, encodeDexData, getReadableError, shortAddress } from "../utils/helpers";
import { FiExternalLink } from "react-icons/fi";
import { PiCheckCircleFill, PiWarningCircleBold, PiWarningOctagonDuotone } from "react-icons/pi";
import { getAbi, getBlockExplorer, getEventsAPIChainSlug } from "../constants";
import { useAtom } from "jotai";
import { atomPendingTxWrite, atomPendingHashWrite } from "../atoms";
import moment from "moment";
import { ContractEvent, RepayBorrowEvent, SwapAndSettleEvent } from "../utils/events/interfaces";
import { SellPreviewData } from "./Sell";

interface SellSendProps {
  vaultAddress: Address;
  borrowVaultAddress: Address;
  inSymbol: string;
  inAmount: string;
  inTokens: string;
  inDecimals: number;
  outAmount: string;
  fee: string;
  quoteJson: any,
  dexId: string;
  sellPreviewData: SellPreviewData | undefined,
  onCancel: ()=> void;
}

export function SellSend({
  vaultAddress,
  borrowVaultAddress,
  inSymbol,
  inAmount,
  inTokens,
  inDecimals,
  outAmount,
  fee,
  quoteJson,
  dexId,
  sellPreviewData,
  onCancel
}: SellSendProps) {
  const {chain} = useNetwork();
  const { address } = useAccount();

  const [ , setTx] = useAtom(atomPendingTxWrite);
  const [ , setHash] = useAtom(atomPendingHashWrite);
  const [dexData, setDexData] = useState<null | string>(null);
  const [isTxPrepared, setIsTxPrepared] = useState(false);

  useEffect(()=> {
    const getDexData = async()=> {
      let encoded = await encodeDexData(chain?.id, dexId, fee, quoteJson, vaultAddress, vaultAddress);
      setDexData(encoded);
    }
    getDexData();
  }, []);

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    enabled: !isTxPrepared, // this hook can re run due to dexData in state. Manually created flag to make it run only once in modal's lifecycle.
    address: dexData ? vaultAddress : undefined,
    abi: getAbi({chainId: chain?.id, abiFile: 'Vault'}),
    functionName: 'swapAndSettle',
    args: [
      inTokens,
      borrowVaultAddress,
      outAmount,
      dexData
    ],
    onSuccess: () => {
      setIsTxPrepared(true);
    }
  });
 
  const { data, error, isLoading, isError, write } = useContractWrite(config);

  useEffect(()=> {
    if (write) {
      write();
    }
  }, [write]);

  useEffect(() => {
    if(data?.hash) {
      if (getEventsAPIChainSlug(chain?.id) !== "") {
        const swapEventData: SwapAndSettleEvent = {
          numTokensAmount: decimal2Fixed(inAmount, inDecimals),
          numTokens: inTokens,
          accountAddress: address ? address : zeroAddress,
          borrowedAsset: borrowVaultAddress,
          repayAmount: sellPreviewData?.repayAmountRequired,
          receivedAmount: sellPreviewData?.totalReceivable
        }
        const pendingTxtoAdd: ContractEvent[] = [
          {
            eventAddress: vaultAddress ,
            eventName: "SwapAndSettle",
            transactionHash: data?.hash,
            timestamp: moment().unix().toString(),
            __typename: "",
            data: "",
            eventData: swapEventData
          }
        ]
        if(sellPreviewData?.repayAmountRequired){
          const repayEventData: RepayBorrowEvent = {
            payer: address ? address : zeroAddress,
            borrower: address ? address : zeroAddress,
            repayAmount: sellPreviewData?.repayAmountRequired,
            accountBorrows: "", 
            totalBorrows: "" 
          }
          pendingTxtoAdd.push({
            eventAddress: borrowVaultAddress ,
            eventName: "RepayBorrow",
            transactionHash: data?.hash,
            timestamp: moment().unix().toString(),
            __typename: "",
            data: "",
            eventData: repayEventData
          })
        }
        setTx(pendingTxtoAdd)
      } else {
        setHash(data.hash);
      }
    }
  }, [data?.hash]);
  
  const {data: txData, isLoading: isTxLoading, isSuccess}  = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <Box textAlign='center'>
      {(isLoading || isTxLoading) && <Img
        display='inline-block'
        height='96px'
        width='96px'
        src={LoadingGif} 
      />}

      {( isPrepareError || isError) && <VStack spacing='20px' mt='20px'>
        <Icon as={PiWarningOctagonDuotone} fontSize={'5xl'} color='orange.300'/>
        <Box>
          <Text color='primary.900' textStyle='body-bold-1'>
            Failed to initiate transaction
          </Text>
          <Text wordBreak='break-word' color='orange.400' textStyle='body-bold-3'>
            { (prepareError || error)?.name }
          </Text>
          <Text wordBreak='break-word' color='primary.900' mt={4} textStyle='body-2'>
            {getReadableError((prepareError || error)?.message)}
          </Text>
        </Box>
        <Button onClick={()=> onCancel()}>Close</Button>
      </VStack>}

      {isLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting Txn to initiate.
        </Text>
        <Text textStyle="body-bold-3" color="gray.500">
          Please go to your wallet and <br/> allow this transaction to continue.
        </Text>
      </Box>}

      {isTxLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting transaction to complete.
        </Text>
        <Link
          textStyle="body-bold-3"
          color="gray.500"
          isExternal
          href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}
        >
          View in Explorer {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
        </Link>
      </Box>}

      {isSuccess && <VStack spacing="32px" mt='20px'>
        <Box>
          <Text textStyle='body-bold-1' color='primary.900'>{inAmount} {inSymbol}</Text>
          {txData?.status === 'success' ? <Text mt='5px' textStyle='body-bold-3' color='green.500'>
            Sold Successfully <Icon as={PiCheckCircleFill}/>
          </Text> : <Text textStyle='body-bold-3' color='red.500'>
            Txn Reverted <Icon as={PiWarningCircleBold}/>
          </Text>}
        </Box>
        <Box w='100%'>
          <SimpleGrid
            columns={2} spacing='20px'
            borderBottom='1px solid'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Vault Name
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
            {inSymbol} Vault
            </Text>
          </SimpleGrid>
          <SimpleGrid
            columns={2} spacing='20px'
            borderBottom='1px solid'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Fees Paid
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              0.00
            </Text>
          </SimpleGrid>
          <SimpleGrid
            columns={2} spacing='20px'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Txn Hash
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              <Link isExternal href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}>
                {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
              </Link>
            </Text>
          </SimpleGrid>
        </Box>
        <Box w='100%'>
          {txData?.status === 'success' ? <Button
            size="lg"
            w="100%"
            colorScheme='primary'
            mb="16px"
            onClick={()=> onCancel()}
          >Done</Button> :
          <Button
            size="lg"
            w="100%"
            onClick={()=> onCancel()}
          >
            Close
          </Button>}
        </Box>
      </VStack>}
    </Box>
  )
}