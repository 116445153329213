import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useState } from "react";
import { BuyTab } from "./BuyTab";
import { InvestmentVault } from '../utils/investments';
import { VaultsSnapshot } from '../atoms';
import { VaultInterface } from "../constants/interfaces";
import { SellTab } from "./SellTab";

const selectedStyle= {
  borderRadius: '12px',
  backgroundColor: 'white',
  boxShadow:'0px 0.8025210499763489px 4.815126419067383px 0.40126052498817444px rgba(3, 17, 92, 0.20)'
}
interface BuySellProps {
  vault: VaultInterface,
  vaultData: InvestmentVault,
  snapshot: VaultsSnapshot
}
export function BuySellTabs({
  vault,
  vaultData,
  snapshot
}: BuySellProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const tabs = ['BUY', 'SELL'];

  const getSelectedStyle = (index: number) => {
    return index ===activeIndex ? selectedStyle : {}
  }
  
  return <Box>
    <SimpleGrid
      backgroundColor='#F4F5FB'
      p='4px'
      columns={2}
      borderRadius='16px'
    >
      {tabs.map((tab, i)=> <Box
        p='12px'
        key={i}
        textAlign='center'
        {...getSelectedStyle(i)}
        cursor="pointer"
        onClick={()=> {
          if(activeIndex !== i) {
            setActiveIndex(i);
          }
        }}
      >
        <Text textStyle='subheading-3' color='primary.900'>{tab}</Text>
      </Box>)}
    </SimpleGrid>
    <Box mt='12px'>
      {tabs[activeIndex] === 'BUY' && <BuyTab
        vault={vault}
        vaultData={vaultData}
        borrowVaults={vaultData.borrowVaults}
      />}
      {tabs[activeIndex] === 'SELL' && <SellTab
        vault={vault}
        vaultData={snapshot[vault.address]}
        borrowVaults={vaultData.borrowVaults}
      />}
    </Box>
  </Box>
}