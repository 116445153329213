import { Address } from "viem";
import { atomUserCrossChainbalancesWrite } from "../atoms";
import { useAtom } from "jotai";
import { useAccount } from "wagmi";
import { useEffect } from "react";

export default function useCrossChainBalanceRead() {
  const {address} = useAccount();
  const [, saveBalances] = useAtom(atomUserCrossChainbalancesWrite);
  
  useEffect(()=> {
    const fetchData = (address: Address ) => {
      fetch(`https://ayn1ewl5ck.execute-api.ap-south-1.amazonaws.com/null/chainbalances/${address? address.toLowerCase(): ''}`)
      .then((res)=> res.json())
      .then((resJson) => {
        if(resJson.success) {
          saveBalances(resJson.response);
        }
      })
      .catch((err)=> {
        saveBalances(null);
      })
    }


    if (address) {
      fetchData(address);
    } else {
      saveBalances(null);
    }
  }, [ address, saveBalances])

}