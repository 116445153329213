import { useAccount } from "wagmi";
import { useEffect, useState } from "react";

export default function useLeaderboard(lastEvaluatedKey: any | undefined) {
  const {address} = useAccount();
  const  [data, setData] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(()=> {
    const fetchData = () => {
      let payload: any = {
        "network": "boba",
        "limit": 10
      }
      if(address) {
        payload.userAddress = address.toLowerCase();
      }
      if(lastEvaluatedKey) {
        payload.LastEvaluatedKey = lastEvaluatedKey;
      }
      setIsLoading(true);
      fetch(`https://api.wefi.xyz/misc/leaderboard`, {
        method: "POST",
        body: JSON.stringify(payload)
      })
      .then((res)=> res.json())
      .then((resJson) => {
        if(resJson.success) {
          setData(resJson.data);
        }
      })
      .catch((err)=> {
        console.log("error while fetching leaderboard", err);
        setError(true);
      }).finally(()=> {
        setIsLoading(false);
      })
    }
    if(!isLoading && !isError) {
      fetchData();
    }
  }, [address])

  return {data, isLoading, isError};
}