import { Center, Img, Modal, ModalContent, ModalOverlay, UseModalProps } from "@chakra-ui/react";
import { getTokenImage } from "../utils/image";
import { FaucetSend } from "./FaucetSend";
import { Address } from "viem";
import { getVaults } from "../constants";
import { useNetwork } from "wagmi";

interface ModalSupplyProps extends UseModalProps {
  faucetAddress: Address
}

export function ModalFaucet({
  isOpen,
  onClose,
  faucetAddress
}: ModalSupplyProps) {

  const {chain} = useNetwork();
  const vault = getVaults(chain?.id)[0]
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
      <ModalOverlay/>
      <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getTokenImage(vault.uSymbol)}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        
        {<FaucetSend
          faucetAddress={faucetAddress}
          onCancel={()=> onClose()}
        />}
      </ModalContent>
    </Modal>
  )
}