import { Box, Button, HStack, Icon, Img, Input, InputGroup, InputRightElement, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { LendingVault } from '../utils/lending';
import { getRewardTokenIcon, getTokenImage } from '../utils/image';
import { decimal2Fixed, fixed2Decimals, isGreaterThanOrEqualTo, isLessThan, isLessThanOrEqualTo } from '../utils/helpers';
import { erc20ABI, useAccount, useContractRead, useNetwork } from 'wagmi';
import { zeroAddress } from 'viem';
import { PiWarningDiamondDuotone, PiWarningDuotone } from 'react-icons/pi';

interface SupplyProps extends Omit <LendingVault, 'markets' | 'marketsSymbols'> {
  amountSupply: string;
  onAmountChange: (amount: string) => void;
  sendApproval: (amount: string)=> void
  sendSupply: (amount: string) => void
}

export function Supply({
  amountSupply,
  address,
  underlying,
  underlyingBalance,
  symbol,
  uDecimals,
  minLendAmountRequired,
  poolApy,
  rewardsApr,
  onAmountChange,
  sendApproval,
  sendSupply
}: SupplyProps) {
  const {chain} = useNetwork();
  const {address: userAddress} = useAccount();
  const { data : amountApproved} = useContractRead({
    abi: erc20ABI,
    address: underlying,
    functionName: 'allowance',
    args: [userAddress || zeroAddress, address]
  });

  const handleMax = ()=> {
    if(Number(underlyingBalance) > 0) {
      onAmountChange(
        fixed2Decimals(underlyingBalance, uDecimals)
      );
    }
  }

  const hasApproval = isGreaterThanOrEqualTo(
    amountApproved? amountApproved.toString(): 0,
    decimal2Fixed(amountSupply, uDecimals)
  );

  return (
    <Box mt='12px'>
      <Box textAlign='center' color='primary.900'>
        <Text textStyle='body-3'>Supplying to</Text>
        <Text textStyle='body-bold-1'>{symbol} Pool</Text>
      </Box>
      <InputGroup mt='20px' borderRadius='12px'>
        <Input
          value={amountSupply}
          variant='filled'
          type='number'
          size='lg'
          onChange={(e)=> onAmountChange(e.target.value)}
        />
        <InputRightElement mx='16px' my='8px'>
          <Button variant='ghost' colorScheme='primary' onClick={()=> handleMax()}>
            MAX
          </Button>
        </InputRightElement>
      </InputGroup>
      <Text
        textStyle='body-bold-3'
        color='primary.900'
        opacity='0.6399999856948853'
        textAlign='right'
        mt='12px'
      >
        Bal. {fixed2Decimals(underlyingBalance, uDecimals)}
      </Text>
      {minLendAmountRequired && isLessThan(amountSupply, minLendAmountRequired) && <HStack
        w='100%'
        mt='12px'
        spacing={1}
        alignItems='center'
        justifyContent='center'
      >
        <Icon as={PiWarningDuotone} color='orange.500' />
        <Text textStyle='body-2'
          color='primary.900'
          opacity='0.6399999856948853'
        >
          Minimum Supply Amount Required: <b>{minLendAmountRequired} {symbol}</b>
        </Text>
      </HStack>}
      <VStack mt='28px'>
        <SimpleGrid
          columns={2} spacing='20px'
          borderBottom='1px solid'
          py="12px"
          borderColor='gray.150'
          w="100%"
        >
          <Text
            textStyle="body-bold-3"
            color='primary.900'
            opacity='0.6399999856948853'
          >
            Pool APY
          </Text>
          <Text textStyle="body-bold-2"
            color='primary.900'
            justifySelf='flex-end'
          >
            {poolApy ? `${poolApy.toFixed(2)}%` : '---'}
          </Text>
        </SimpleGrid>
        {rewardsApr && rewardsApr.value && <SimpleGrid
          columns={2} spacing='20px'
          borderBottom='1px solid'
          py="12px"
          borderColor='gray.150'
          w="100%"
        >
          <Text
            textStyle="body-bold-3"
            color='primary.900'
            opacity='0.6399999856948853'
          >
            Boosted APY
          </Text>
          <HStack  justifySelf='flex-end'>
            <Img src={getRewardTokenIcon(chain?.id)} height='16px'/>
            <Text textStyle="body-bold-2"
              color='green.500'
            >
              {rewardsApr.value ? `${rewardsApr.value.toFixed(0)}${rewardsApr.suffix}` : '---'}
            </Text>
          </HStack>
        </SimpleGrid>}
        <SimpleGrid
          columns={2} spacing='20px'
          py="12px"
          w="100%"
        >
          <Text
            textStyle="body-bold-3"
            color='primary.900'
            opacity='0.6399999856948853'
          >
            Platform Fees
          </Text>
          <Text textStyle="body-bold-2"
            color='primary.900'
            justifySelf='flex-end'
          >
            0.00
          </Text>
        </SimpleGrid>
      </VStack>
      <Button
        isDisabled={
          (minLendAmountRequired ? isLessThan(amountSupply, minLendAmountRequired) : false ) ||
          isLessThanOrEqualTo(amountSupply, 0) ||
          isLessThan(underlyingBalance, decimal2Fixed(amountSupply, uDecimals)) ||
          amountApproved === undefined
        }
        mt='28px'
        w='full'
        colorScheme='primary'
        size='lg'
        onClick={()=> {
          if (hasApproval) {
            sendSupply(amountSupply);
          } else {
            sendApproval(amountSupply);
          }
        }}
      >
        {hasApproval ? 'Proceed' : `Allow Spend of ${amountSupply} ${symbol}`}
      </Button>
      {Number(underlyingBalance) < Number(decimal2Fixed(amountSupply, uDecimals)) && <Text 
        textAlign='center'
        color="orange.500"
        mt="20px"
        textStyle="body-bold-2"
      >
        Insufficient wallet balance
      </Text>}
    </Box>
  )
}