import { Address, zeroAddress } from "viem";
import {
  DexQuoter,
  DexSlug,
  GetAbiProps,
  GetContractAddressProps,
  VaultInterface
} from "./interfaces";

import {
  Vaults as MumbaiVaults,
  ContractAddresses as MumbaiContractAddresses,
  blocksPerDay as MumbaiBlocksPerDay,
  blockExplorer as MumbaiBlockExplorer,
  DexQuoters as MumbaiDexQuoters,
  eventsApiChainSlug as MumbaiEventsAPIChainSlug,
  faucetURL as MumbaiFaucet,
  faucetAddress as MumbaiFaucetAddress,
  predictionTokensConfig as MumbaiPredectionTokensConfig,
  defaultTradePair as MumbaiDefaultTradePair
} from "./mumbai";


import {
  Vaults as ZkSyncTestnetVaults,
  ContractAddresses as ZkSyncTestnetContractAddresses,
  blocksPerDay as ZkSyncTestnetBlocksPerDay,
  blockExplorer as ZkSyncTestnetBlockExplorer,
  DexQuoters as ZkSyncTestnetDexQuoters,
  eventsApiChainSlug as ZkSyncTestnetEventsAPIChainSlug,
  faucetURL as ZkSyncFaucet,
  faucetAddress as ZkSyncFaucetAddress,
  defaultTradePair as ZkSyncDefaultTradePair

} from "./zksyncTestnet";

import {
  Vaults as XdcTestnetVaults,
  ContractAddresses as XdcTestnetContractAddresses,
  blocksPerDay as XdcTestnetBlocksPerDay,
  blockExplorer as XdcTestnetBlockExplorer,
  DexQuoters as XdcTestnetDexQuoters,
  eventsApiChainSlug as XdcTestnetEventsAPIChainSlug,
  faucetURL as XdcFaucet,
  faucetAddress as XdcFaucetAddress,
  defaultTradePair as XdcTestnetDefaultTradePair
} from "./xdcTestnet";

import {
  Vaults as BaseTestnetVaults,
  ContractAddresses as BaseTestnetContractAddresses,
  blocksPerDay as BaseTestnetBlocksPerDay,
  blockExplorer as BaseTestnetBlockExplorer,
  DexQuoters as BaseTestnetDexQuoters,
  eventsApiChainSlug as BaseTestnetEventsAPIChainSlug,
  faucetURL as BaseFaucet,
  faucetAddress as BaseFaucetAddress,
  defaultTradePair as BaseTestnetDefaultTradePair
} from "./baseTestnet";

import {
  Vaults as PolygonZkevmTestnetVaults,
  ContractAddresses as PolygonZkevmTestnetContractAddresses,
  blocksPerDay as PolygonZkevmTestnetBlocksPerDay,
  blockExplorer as PolygonZkevmTestnetBlockExplorer,
  DexQuoters as PolygonZkevmTestnetDexQuoters,
  eventsApiChainSlug as PolygonZkevmTestnetEventsAPIChainSlug,
  faucetURL as PolygonZkevmFaucet,
  faucetAddress as PolygonZkevmFaucetAddress,
  defaultTradePair as PolygonZkevmTestnetDefaultTradePair
} from "./polygonZkevmTestnet";

import {
  Vaults as PolygonZkevmVaults,
  ContractAddresses as PolygonZkevmContractAddresses,
  blocksPerDay as PolygonZkevmBlocksPerDay,
  blockExplorer as PolygonZkevmBlockExplorer,
  DexQuoters as PolygonZkevmDexQuoters,
  eventsApiChainSlug as PolygonZkevmEventsAPIChainSlug,
  oraclePriceDecimals as PolygonZkevmoraclePriceDecimals,
  defaultTradePair as PolygonZkevmDefaultTradePair
} from "./polygonZkevm";

import {
  Vaults as LineaVaults,
  ContractAddresses as LineaContractAddresses,
  blocksPerDay as LineaBlocksPerDay,
  blockExplorer as LineaBlockExplorer,
  DexQuoters as LineaDexQuoters,
  eventsApiChainSlug as LineaEventsAPIChainSlug,
  kyberChainSlug as LineaKyberChainSlug,
  oraclePriceDecimals as LineaOraclePriceDecimals,
  predictionTokensConfig as LineaPredictionTokensConfig,
  defaultTradePair as LineaDefaultTradePair
} from "./linea";

import {
  Vaults as LineaTestnetVaults,
  ContractAddresses as LineaTestnetContractAddresses,
  blocksPerDay as LineaTestnetBlocksPerDay,
  blockExplorer as LineaTestnetBlockExplorer,
  DexQuoters as LineaTestnetDexQuoters,
  eventsApiChainSlug as LineaTestnetEventsAPIChainSlug,
  faucetURL as LineaFaucet,
  faucetAddress as LineaFaucetAddress,
  defaultTradePair as LineaTestnetDefaultTradePair
} from "./lineaTestnet";

import {
  Vaults as XdcVaults,
  ContractAddresses as XdcContractAddresses,
  blocksPerDay as XdcBlocksPerDay,
  blockExplorer as XdcBlockExplorer,
  DexQuoters as XdcDexQuoters,
  eventsApiChainSlug as XdcEventsAPIChainSlug,
  oraclePriceDecimals as XdcOraclePriceDecimals,
  defaultTradePair as XdcDefaultTradePair
} from "./xdc";

import { linea, polygonMumbai, lineaTestnet, baseGoerli, xdcTestnet, zkSyncTestnet, zkSync, xdc, boba  } from "viem/chains";

import {
  ContractAddresses as PolygonContractAddresses,
  blockExplorer as PolygonBlockExplorer,
  predictionTokensConfig as PolygonPredectionTokensConfig
} from "./polygon";

import {
  Vaults as BobaVaults,
  ContractAddresses as BobaContractAddresses,
  blocksPerDay as BobaBlocksPerDay,
  blockExplorer as BobaBlockExplorer,
  DexQuoters as BobaDexQuoters,
  eventsApiChainSlug as BobaEventsAPIChainSlug,
  defaultTradePair as BobaDefaultTradePair
} from "./boba";

export const debounceInputdeplay = 2000;
export const MUMBAI_CHAIN_ID = 80001;
export const ZKSYNC_TESTNET_ID = 280;
export const XDC_TESTNET_ID = 51;
export const BASE_TESTNET_ID = 84531;
export const POLYGON_ZKEVM_TESTNET_ID = 1442;
export const POLYGON_ZKEVM_CHAIN_ID = 1101;
export const LINEA_CHAIN_ID = 59144;
export const LINEA_TESTNET_ID = 59140;
export const POLYGON_CHAIN_ID = 137;
export const XDC_ID = 50;
export const BOBA_CHAIN_ID=288;

export type VaultType= 'all' | 'lending' | 'invest';
export const getVaults = (chainId: number | undefined, vaultType:VaultType='all' ): VaultInterface[] => {
  let vaults:VaultInterface[] = [];
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      vaults = MumbaiVaults;
      break;
    case ZKSYNC_TESTNET_ID:
      vaults  = ZkSyncTestnetVaults;
      break;
    case XDC_TESTNET_ID:
      vaults = XdcTestnetVaults;
      break;
    case BASE_TESTNET_ID:
      vaults = BaseTestnetVaults;
      break;
    case POLYGON_ZKEVM_TESTNET_ID:
      vaults = PolygonZkevmTestnetVaults;
      break;
    case POLYGON_ZKEVM_CHAIN_ID:
      vaults = PolygonZkevmVaults;
      break;
    case LINEA_CHAIN_ID:
        vaults = LineaVaults;
        break;
    case LINEA_TESTNET_ID:
      vaults = LineaTestnetVaults;
      break;
    case XDC_ID:
      vaults = XdcVaults;
      break;
    case BOBA_CHAIN_ID:
      vaults = BobaVaults;
  }
  if(vaultType === 'lending') {
    return vaults.filter((item)=> item.lendEnabled);
  } else if(vaultType === "invest") {
    return vaults.filter((item)=> item.buyEnabled);
  } else {
    return vaults;
  }
}

export const getAbi = ({chainId, abiFile}: GetAbiProps) : any[] | undefined => {
  let dir = "";
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      dir="mumbai";
      break;
    case ZKSYNC_TESTNET_ID:
      dir= 'zksyncTestnet';
      break;
    case XDC_TESTNET_ID:
      dir = "xdcTestnet";
      break;
    case BASE_TESTNET_ID:
      dir = "baseTestnet";
      break;
    case POLYGON_ZKEVM_TESTNET_ID:
      dir = "polygonzkevm";
      break;
    case POLYGON_ZKEVM_CHAIN_ID:
      dir = "polygonzkevm";
      break;
    case LINEA_CHAIN_ID:
      dir = "linea";
      break;
    case LINEA_TESTNET_ID:
      dir = "lineaTestnet";
      break;
    case XDC_ID:
      dir = "linea";
      break;
    case BOBA_CHAIN_ID:
      dir = 'linea';
  }
  if(dir === "") {
    return [];
  } else {
    try {
      return require(`./abi/${dir}/${abiFile}.json`)
    } catch (error) {
      return undefined;
    }
  }
}

export const getDexABI = (slug:DexSlug) => {
  return require(`./abi/${slug}.json`);
} 

// faucet will not be there for mainnet; not making changes in interface
export const getFaucetABI = () => {
  return require('./abi/faucet.json');
}

export const getContractAddress = ({chainId, contractName}: GetContractAddressProps) : Address | undefined => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiContractAddresses[contractName];
    case ZKSYNC_TESTNET_ID:
      return ZkSyncTestnetContractAddresses[contractName];
    case XDC_TESTNET_ID:
      return XdcTestnetContractAddresses[contractName];
    case BASE_TESTNET_ID:
      return BaseTestnetContractAddresses[contractName];
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmTestnetContractAddresses[contractName];
    case POLYGON_ZKEVM_CHAIN_ID:
        return PolygonZkevmContractAddresses[contractName];
    case LINEA_CHAIN_ID:
      return LineaContractAddresses[contractName];
    case LINEA_TESTNET_ID:
      return LineaTestnetContractAddresses[contractName];
    case XDC_ID:
      return XdcContractAddresses[contractName];
    case POLYGON_CHAIN_ID:
      return PolygonContractAddresses[contractName];
    case BOBA_CHAIN_ID:
      return BobaContractAddresses[contractName];
    default:
      return undefined;
  }
}

export const getBlocksPerDay = (chainId: number | undefined): number => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiBlocksPerDay;
    case ZKSYNC_TESTNET_ID:
      return ZkSyncTestnetBlocksPerDay;
    case XDC_TESTNET_ID:
      return XdcTestnetBlocksPerDay;
    case BASE_TESTNET_ID:
      return BaseTestnetBlocksPerDay;
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmTestnetBlocksPerDay;
    case POLYGON_ZKEVM_CHAIN_ID:
      return PolygonZkevmBlocksPerDay;
    case LINEA_CHAIN_ID:
      return LineaBlocksPerDay;
    case LINEA_TESTNET_ID:
      return LineaTestnetBlocksPerDay;
    case XDC_ID:
      return XdcBlocksPerDay;
    case BOBA_CHAIN_ID:
      return BobaBlocksPerDay;
    default:
      return 37565;
  }
}

export const getBlockExplorer = (chainId: number | undefined): string => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiBlockExplorer;
    case ZKSYNC_TESTNET_ID:
      return ZkSyncTestnetBlockExplorer;
    case XDC_TESTNET_ID:
      return XdcTestnetBlockExplorer;
    case BASE_TESTNET_ID:
      return BaseTestnetBlockExplorer;
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmTestnetBlockExplorer;
    case POLYGON_ZKEVM_CHAIN_ID:
      return PolygonZkevmBlockExplorer;
    case LINEA_CHAIN_ID:
      return LineaBlockExplorer;
    case LINEA_TESTNET_ID:
      return LineaTestnetBlockExplorer;
    case POLYGON_CHAIN_ID:
      return PolygonBlockExplorer;
    case XDC_ID:
      return XdcBlockExplorer;
    case BOBA_CHAIN_ID:
      return BobaBlockExplorer;
    default:
      return '';
  }
}

export const getDexQuoters = (chainId: number|undefined ): DexQuoter[] => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiDexQuoters;
    case ZKSYNC_TESTNET_ID:
      return ZkSyncTestnetDexQuoters;
    case XDC_TESTNET_ID:
      return XdcTestnetDexQuoters;
    case BASE_TESTNET_ID:
      return BaseTestnetDexQuoters;
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmTestnetDexQuoters;
    case POLYGON_ZKEVM_CHAIN_ID:
      return PolygonZkevmDexQuoters;
    case LINEA_CHAIN_ID:
      return LineaDexQuoters;
    case LINEA_TESTNET_ID:
      return LineaTestnetDexQuoters;
    case XDC_ID:
      return XdcDexQuoters;
    case BOBA_CHAIN_ID:
      return BobaDexQuoters;
    default:
      return [];
  } 
}

export const getEventsAPIChainSlug = (chainId: number|undefined): string => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiEventsAPIChainSlug;
    case BASE_TESTNET_ID:
      return BaseTestnetEventsAPIChainSlug;
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmTestnetEventsAPIChainSlug;
    case POLYGON_ZKEVM_CHAIN_ID:
      return PolygonZkevmEventsAPIChainSlug;
    case ZKSYNC_TESTNET_ID:
      return ZkSyncTestnetEventsAPIChainSlug;
    case XDC_TESTNET_ID:
      return XdcTestnetEventsAPIChainSlug;
    case LINEA_CHAIN_ID:
        return LineaEventsAPIChainSlug;
    case LINEA_TESTNET_ID:
      return LineaTestnetEventsAPIChainSlug;
    case XDC_ID:
      return XdcEventsAPIChainSlug;
    case BOBA_CHAIN_ID:
      return BobaEventsAPIChainSlug;
    default:
      return '';
  } 
}

export const getFaucetURL = (chainId: number | undefined ): string => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiFaucet;
    case ZKSYNC_TESTNET_ID:
      return ZkSyncFaucet;
    case XDC_TESTNET_ID:
      return XdcFaucet;
    case BASE_TESTNET_ID:
      return BaseFaucet;
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmFaucet;
    case LINEA_TESTNET_ID:
      return LineaFaucet;
    default:
      return '';
  } 
}

export const getFaucetAddress = (chainId: number | undefined ): Address => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiFaucetAddress;
    case ZKSYNC_TESTNET_ID:
      return ZkSyncFaucetAddress;
    case XDC_TESTNET_ID:
      return XdcFaucetAddress;
    case BASE_TESTNET_ID:
      return BaseFaucetAddress;
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmFaucetAddress;
    case LINEA_TESTNET_ID:
      return LineaFaucetAddress;
    default:
      return zeroAddress;
  } 
}

export const getOraclePriceDecimals = (chainId: number | undefined ): string => {
  switch(chainId) {
    case POLYGON_ZKEVM_CHAIN_ID:
      return PolygonZkevmoraclePriceDecimals;
    case LINEA_CHAIN_ID:
      return LineaOraclePriceDecimals;
    case XDC_ID:
      return XdcOraclePriceDecimals;
    default:
      return "8";
  } 
}
export const protocolSupportedChains = {mainnet: [
  linea,
  xdc,
  boba
].map((c)=> c.name),
testnet: []
}

export const protocolSupportedChainIds = [
  LINEA_CHAIN_ID,
  XDC_ID,
  BOBA_CHAIN_ID
]

export const predictionSupportedChainIds = [
  // MUMBAI_CHAIN_ID
  // LINEA_CHAIN_ID
  POLYGON_CHAIN_ID
]

export const getPredictionTokens = (chainId: number | undefined) => {
  if (chainId === MUMBAI_CHAIN_ID) {
    return MumbaiPredectionTokensConfig;
  } else if(chainId === LINEA_CHAIN_ID) {
    return LineaPredictionTokensConfig;
  } if(chainId === POLYGON_CHAIN_ID) {
    return PolygonPredectionTokensConfig;
  } else {
    return []
  }
}

export const getDefaultTradePair = (chainId: number | undefined): string => {
  switch(chainId) {
    case MUMBAI_CHAIN_ID:
      return MumbaiDefaultTradePair;
    case ZKSYNC_TESTNET_ID:
      return ZkSyncDefaultTradePair;
    case XDC_TESTNET_ID:
      return XdcTestnetDefaultTradePair;
    case BASE_TESTNET_ID:
      return BaseTestnetDefaultTradePair;
    case POLYGON_ZKEVM_TESTNET_ID:
      return PolygonZkevmTestnetDefaultTradePair;
    case POLYGON_ZKEVM_CHAIN_ID:
      return PolygonZkevmDefaultTradePair;
    case LINEA_CHAIN_ID:
      return LineaDefaultTradePair;
    case LINEA_TESTNET_ID:
      return LineaTestnetDefaultTradePair;
    case XDC_ID:
      return XdcDefaultTradePair;
    case BOBA_CHAIN_ID:
      return BobaDefaultTradePair;
    default:
      return '';
  }
}
