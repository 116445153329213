import {fonts} from './fonts';

export const textStyles =  {
  'heading-1': {
    fontFamily: fonts.heading,
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  'heading-2': {
    fontFamily: fonts.heading,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '36px',
  },
  'heading-3': {
    fontFamily: fonts.heading,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px'
  },
  'subheading-1': {
    fontFamily: fonts.heading,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
  },
  'subheading-2': {
    fontFamily: fonts.heading,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  'subheading-3': {
    fontFamily: fonts.heading,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px'
  },
  'cta-1': {
    fontFamily: fonts.heading,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.4px'
  },
  'cta-2': {
    fontFamily: fonts.heading,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px'
  },
  'cta-3': {
    fontFamily: fonts.heading,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px'
  },
  'body-bold-1': {
    fontFamily: fonts.body,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  'body-1': {
    fontFamily: fonts.body,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.064px'
  },
  'body-bold-2': {
    fontFamily: fonts.body,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    lettterSpacing: '0.056px'
  },
  'body-2': {
    fontFamily: fonts.body,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.056px'
  },
  'body-bold-3': {
    fontFamily: fonts.body,
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.5px'
  },
  'body-3': {
    fontFamily: fonts.body,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.6px'
  },
  'labelBold': {
    fontFamily: fonts.body,
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.6px'
  },
  'label': {
    fontFamily: fonts.body,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.6px'
  },
  'badge': {
    fontFamily: fonts.body,
    fontSize: '11px',
    fontWeight: 700,
    lineHeight: '14px',
    letterSpacing: '0.2px'
  }
}