import { Box, BoxProps, HStack, Icon, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { PiGraph, PiTrendUp } from "react-icons/pi";
import { Link } from "react-router-dom";

interface StatsBlockProps extends BoxProps {
  value: string | ReactNode,
  valueColor?: string,
  title: string,
  graphic: JSX.Element,

}

export default function StatsBlock({value, title, valueColor, graphic, ...rest}: StatsBlockProps ) {
  return (
    <Box  {...rest}>
      <HStack>
        {graphic}
        <Box>
          <Box
            textStyle="heading-3"
            color={valueColor ? valueColor : "primary.900"}
          >
            {value}
          </Box>
          <Text
            textStyle="body-3"
            color= "gray.500"
            mt="4px"
          >
            {title}
          </Text>
          {title=== 'Total Trading Volume' && <HStack
            mt={2}
            textDecoration='underline'
            color= "gray.600"
            as={Link}
            alignItems='center'
            spacing={0}
            to='leaderboard'
          >
            <Text fontSize='12px'>Leaderboard</Text>
            <Icon as={PiTrendUp}/>
          </HStack>}
        </Box>
      </HStack>
    </Box>
  )
}