import { Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  UseModalProps,
  SimpleGrid,
  Divider,
  VStack,
  HStack,
  Img,
  Link,
  Flex,
  Spacer,
  Center
} from "@chakra-ui/react";

import DinoBG from '../images/graphics/dinoSaysHi.svg';
import { Connector, useConnect } from "wagmi";
import { getWalletImage } from "../utils/image";
import { useEffect } from "react";
import { useAtom } from 'jotai';
import { atomDefaultChainID } from '../atoms';

import WeFiLogo from '../images/logo.svg';
import { ChainSelector } from "./ChainSelector";

interface ModalWalletConnectProps extends UseModalProps { }

export default function ModalWalletConnect(
  {isOpen, onClose}
: ModalWalletConnectProps) {
  const {connectors, isLoading, pendingConnector, connect, error} = useConnect();
  const [defaultChainId] = useAtom(atomDefaultChainID);
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="full">
      <ModalOverlay/>
      <ModalContent>
        {/* <ModalCloseButton /> */}
        <ModalBody
          p={0}
        >
          <SimpleGrid
            columns={2}
            px={{base: '30px', sm: '30px', md: '120px'}}
            py='12px'
            borderBottom='1px solid'
            borderColor='gray.100'
            alignItems='center'
          >
              <Box>
                <Img src={WeFiLogo} h='44px'/>
              </Box>
              <Box display='flex' justifyContent='flex-end'>
                <HStack spacing='20px'>
                  <Link isExternal={true} href='https://twitter.com/wefi_xyz' textStyle='body-3' color='primary.800'>Twitter</Link>
                  <Link isExternal={true} href='https://docs.wefi.xyz/' textStyle='body-3' color='primary.800'>Docs</Link>
                  <Link isExternal={true} href='https://discord.com/invite/B6U6kectbV' textStyle='body-3' color='primary.800'>Get Help</Link>
                </HStack>
              </Box>
          </SimpleGrid>
          <Center>
            <Box
              mt='46px'
              w={{base: 'full', sm: '100%', md: '45%'}}
              borderRadius='20px'
              border='1px solid'
              borderColor='gray.150'
              boxShadow='0px 4px 16px 4px rgba(3, 17, 92, 0.04)'
            >
              <SimpleGrid columns={[1, null, 2]}>
                <Box
                  p="24px"
                  minHeight={{base: 'auto', sm: 'auto', md: '400px'}}
                  bgImage={{base: undefined, md: DinoBG}}
                  bgRepeat="no-repeat"
                  bgPos="left bottom"
                  textStyle="heading-3"
                >
                  <Text color="primary.500">Welcome to WeFi</Text>
                  <Text>how would you like proceed?</Text>
                </Box>
                <Box minHeight={{base: 'auto', sm: 'auto', md: '400px'}} p="24px">
                  {isLoading && pendingConnector?.id && error === null ? <Box
                    h="100%"
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <VStack alignSelf="center">
                      <Img src={getWalletImage(pendingConnector?.id)}/>
                      <Text textStyle="cta-2" color="primary.900">
                        Connecting To {pendingConnector?.name}
                      </Text>
                      <Text textAlign="center" textStyle="body-3" color="gray.500">
                        Please approve your wallet to connect with {window.location.host}
                      </Text>
                      <Divider w="140px" mt="12px"/>
                    </VStack>
                  </Box> : <Box>
                    <HStack>
                      <Text textStyle="body-2" color="gray.500">
                        Select Network
                      </Text>
                      <ChainSelector/>
                    </HStack>
                   
                    <Divider my="16px"/>
                    <Text textStyle="body-2" color="gray.500">
                      Select a wallet to proceed
                    </Text>
                    <Divider my="16px"/>
                    <ConnectorsList
                      connectors={connectors}
                      onConnect={(connector: Connector)=> connect({connector, chainId: defaultChainId}) }
                    />
                  </Box>}
                </Box>
              </SimpleGrid>
              <Flex
                px='28px'
                py='10px'
                borderTop='1px solid'
                borderColor='gray.150' 
                alignItems='center'
              >
                <Text textStyle="body-2" color="gray.500">
                  Want to explore first without connecting wallet?
                </Text>
                <Spacer/>
                <Button
                  justifySelf='flex-end'
                  colorScheme='primary'
                  variant="ghost"
                  mr={3}
                  onClick={onClose}
                >
                  Explore WeFi
                </Button>
              </Flex>
            </Box>
          </Center>
          <Text mt='40px' textStyle="body-2" color="gray.500" textAlign='center'>
            By connecting wallet you are agreeing to the<br/><Link isExternal href='https://www.wefi.xyz/terms-and-conditions' color='primary.200' as='a'>terms and conditions</Link> of WeFi platform.
          </Text>

        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
interface ConnectorListInterface {
  connectors: Connector[];
  onConnect: {(connector: Connector) :void};
}
const ConnectorsList = ({connectors, onConnect}: ConnectorListInterface)=> {
  const walletConnector = connectors.find((item)=> item.id === "walletConnect");
  useEffect(()=> {
    /*
      Custom Z-index of Wallet Connect Modal.
    */
    if( walletConnector?.ready) {
      var style= document.createElement('style');
      style.innerHTML='.wcm-overlay{z-index: 1410 !important}';
      let host = document.getElementsByTagName('wcm-modal')[0];
      host?.shadowRoot?.appendChild(style);
    }
  }, [walletConnector?.ready]);

  return (
    <VStack textStyle="body-bold-1" alignItems="flex-start">
      {connectors.map((connector: Connector)=> {
        if(connector.id === "injected" && !connector.ready) {
          return null;
        }
        return (
          <HStack
            key={connector.id}
            onClick={()=> onConnect(connector)}
            cursor="pointer"
            w="100%"
            p="12px"
            _notLast={{
              borderBottom: "1px solid",
              borderColor: "gray.100"
            }}
            _hover={{
              color: "primary.500"
            }}
          >
            <Img w="24px" h="24px" src={getWalletImage(connector.id)}/>
            <Text>{connector.name}</Text>
          </HStack>
        )
      })}
    </VStack>
  )
}