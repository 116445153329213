import { extendTheme } from '@chakra-ui/react'
import { Primary, Gray } from './colorScheme';
import { fonts } from './fonts';
import { layerStyles } from './layerStyles';
import { textStyles } from './textStyles';
import { buttonStyles } from './components/buttonStyles';
import { menuStyles } from './components/menuStyles';
import { tableStyles } from './components/tableStyles';
import { modalStyles } from './components/modalStyles';
import { inputStyles } from './components/inputStyles';
export const theme = extendTheme({
  fonts,
  colors: {
    primary: Primary,
    gray: Gray
  },
  layerStyles,
  textStyles,
  zIndices: {
    modalWallet: 80,
    modalOverlay: 80
  },
  components: {
    Button: buttonStyles,
    Menu: menuStyles,
    Table: tableStyles,
    Modal: modalStyles,
    Input: inputStyles,
    NumberInput: inputStyles
  },
});