import { HStack, StackProps, Text } from "@chakra-ui/react";

interface IndicativeNumberProps extends StackProps {
  number: number;
  prefix?: string;
  suffix?: string;
}

export default function IndicativeNumber({
  number, prefix, suffix, ...rest
}: IndicativeNumberProps) {
  const getColor = ()=> {
    if(number > 0) {
      return 'green.500';
    } else if(number < 0) {
      return 'red.500';
    } else {
      return 'current';
    }
  }
  return <HStack color={getColor()} {...rest} >
    {number<0 && <Text>-</Text>}
    {prefix && <Text>{prefix}</Text>}
    <Text>{Math.abs(number)}</Text>
    {suffix && <Text>{suffix}</Text>}
  </HStack>
}