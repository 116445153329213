import { zeroAddress } from 'viem';
import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0x4a92889c2ba7c10a1407efc619343123d425591b",
    decimals: 8,
    underlying: "0x1d4cf861932778fbf52b7696e44b4f724823e9d6",
    uSymbol: "USDT",
    uName: "USDT",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0x4f390d552af461d12f151677a44351eb93231af0"]
  },
 
  {
    address: "0x4f390d552af461d12f151677a44351eb93231af0",
    decimals: 8,
    underlying: "0x13955758a6f6e8be65246f3600161380728e9d1a",
    uSymbol: "WETH",
    uName: "WETH",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x4a92889c2ba7c10a1407efc619343123d425591b"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  }
];

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0xA28d7A648b702ED4701F406a968924a222908eC0",
  'Comptroller': "0x22D11aD36E12A973C7Ba3d7A246fd44FFC94277e"
}

export const defaultTradePair = "usdt-weth";
export const blockExplorer = 'https://testnet-zkevm.polygonscan.com';

export const blocksPerDay=37565;

export const DexQuoters : DexQuoter[] = [
  {
    id: '1',
    name: 'Mute.io',
    slug: 'mute-io',
    type: 'Contract',
    address: zeroAddress,
    functionName: ''
  }
]

export const eventsApiChainSlug='zkevm-testnet';

export const faucetURL = "https://faucet.polygon.technology";
export const faucetAddress = "0x02CDD94a9C71c78016EdD77FD4d31803939F967f";