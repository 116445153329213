import { Box, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { ClosedLoanPosition } from "../utils/loanPositions";
import { getTokenImage } from "../utils/image";
import { fixed2Decimals } from "../utils/helpers";
import moment from "moment";

export default function ClosedLoanPositions({
  data
}: {data: ClosedLoanPosition[]}) {
  return (
    <Box>
      <Text textStyle='heading-3' color='primary.900'>
        CLOSED LOAN POSITIONS
      </Text>
      <TableContainer>
        <Table>
          <Thead textStyle='body-2'>
            <Tr>
              <Th>Vault</Th>
              <Th>Borrowed</Th>
              <Th>Repaid</Th>
              <Th>Closing Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, i)=> {
              return (
                <Tr key={i}>
                  <Td>
                    <HStack>
                      <Image h='24px' w='24px' src={getTokenImage(row.uSymbol)} />
                      <Text>{row.uSymbol} Vault</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {fixed2Decimals(row.borrowed.amount, row.uDecimals, 2)} {row.uSymbol}
                  </Td>
                  <Td>
                    {fixed2Decimals(row.repaid.amount, row.uDecimals, 2)} {row.uSymbol}
                  </Td>
                  <Td>
                    {row.closedAt ? moment.unix(Number(row.closedAt)).format("ll") : ''}
                  </Td>
              </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}