import { Center, Img, Modal, ModalContent, ModalOverlay, UseModalProps } from "@chakra-ui/react";
import { getTokenImage } from "../utils/image";
import { useEffect, useState } from "react";
import { ApproveSpend } from "./ApproveSpend";
import { Repay } from "./Repay";
import { OpenLoanPosition } from "../utils/loanPositions";
import { RepaySend } from "./RepaySend";

interface ModalRepayProps extends UseModalProps {
  loanPositions: OpenLoanPosition[]
}

export type TxStep = 'input' | 'approval' | 'submit';

export function ModalRepay({
  loanPositions,
  isOpen,
  onClose
}: ModalRepayProps) {
  const [amount, setAmount] = useState<string>("0");
  const [activeStep, setActiveStep] = useState<TxStep>("input");
  const [activeLoanPosition, setActiveLoanPosition] = useState<OpenLoanPosition | null>(null);

  useEffect(()=> {
    if(loanPositions.length > 0 && activeLoanPosition === null) {
      setActiveLoanPosition(loanPositions[0]);
    }
  }, [loanPositions]);

  return (
    <Modal isOpen={isOpen} onClose={()=> {
      onClose();
      setActiveStep('input');
    }} isCentered size='sm'>
      <ModalOverlay/>
      {activeLoanPosition && <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getTokenImage(activeLoanPosition.uSymbol)}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        {activeStep === 'input' && <Repay
          {...activeLoanPosition}
          loanPositions={loanPositions}
          onPosChange={(pos)=> {
            setActiveLoanPosition(pos)
            setAmount("0");
          }}
          sendApproval={(amount)=> {
            setAmount(amount);
            setActiveStep('approval');
          }}
          sendRepay={(amount)=> {
            setAmount(amount);
            setActiveStep('submit');
          }}
        />}
        {activeStep === 'approval' && <ApproveSpend
          amount={amount}
          tokenAddress={activeLoanPosition.underlying}
          spender={activeLoanPosition.address}
          symbol={activeLoanPosition.uSymbol}
          decimals={activeLoanPosition.uDecimals}
          onCancel={()=> {
            setActiveStep('input');
            onClose();
          }}
          onProceed={()=> setActiveStep('submit')}
          ctaText={`Proceed to Repay`}
        />}

        {activeStep === 'submit' && <RepaySend
          vaultAddress={activeLoanPosition.address}
          amount={amount}
          symbol={activeLoanPosition.uSymbol}
          decimals={activeLoanPosition.uDecimals}
          onCancel={()=> {
            setActiveStep('input');
            onClose();
          }}
        />}
      </ModalContent>}
    </Modal>
  )
}