import { VaultSnapshot, VaultsSnapshot } from "../atoms";
import { VaultInterface } from "../constants/interfaces";
import { addFixed, getUSDPrice, isGreaterThan, isZero, minusFixed } from "./helpers";
import { BorrowEvent, ContractEvent, RepayBorrowEvent } from "./events/interfaces";
import { Address } from "viem";

export interface AmountWithUSD {
  amount: string;
  usd: number
}

export interface OpenLoanPosition {
  address: Address,
  underlying: Address,
  uSymbol: string;
  uDecimals: number;
  underlyingBalance: string,
  outstanding: AmountWithUSD;
  borrowed?: AmountWithUSD;
  repaid?: AmountWithUSD;
  interest?: AmountWithUSD;
}

export interface ClosedLoanPosition extends LoanTimeline {
  uSymbol: string;
  uDecimals: number;
}

export interface LoanPositions {
  totalOutstandingUsd: number;
  totalBorrowedUsd?: number; 
  totalInterestUsd?: number,
  totalRepaidUsd?: number;
  open: OpenLoanPosition[],
  closed: ClosedLoanPosition[]
}

export function getLoanPositions(
  vaults: VaultInterface[],
  snapshot: VaultsSnapshot | null,
  events: ContractEvent[]
): LoanPositions {
  const data: LoanPositions = {
    totalOutstandingUsd: 0,
    open: [],
    closed: []
  }

 if(vaults.length === 0 || snapshot === null) {
  return data;
 }
 vaults.forEach((vault)=> {
    const vaultSnapshot = snapshot[vault.address];
    if(vaultSnapshot) {
      const borrowBalance =vaultSnapshot.borrowBalance;
      //prepare open loan poistion if borrow balance
      if(isGreaterThan(borrowBalance, 0)) {
        const openPosition =  geVaultOpenPosition(vault, vaultSnapshot);
        if(events.length>0) {
          const timeline = getVaultLoanTimeline(vault, vaultSnapshot, events);
          openPosition.borrowed = timeline.current.borrowed;
          openPosition.repaid = timeline.current.repaid;
          openPosition.interest = timeline.current.interest;
          data.closed = [...data.closed, ...timeline.closed.map((item)=> {
            return {
              uSymbol: vault.uSymbol,
              uDecimals: vault.uDecimals,
              ...item
            }
          })];
        }
        data.open.push(openPosition);

        data.totalOutstandingUsd = data.totalOutstandingUsd + openPosition.outstanding.usd;
        if(openPosition.borrowed)
          data.totalBorrowedUsd = (data.totalBorrowedUsd ? data.totalBorrowedUsd : 0) + openPosition.borrowed.usd;
        if(openPosition.repaid)
          data.totalRepaidUsd = (data.totalRepaidUsd ? data.totalRepaidUsd : 0) + openPosition.repaid.usd;
        if(openPosition.interest)
          data.totalInterestUsd = (data.totalInterestUsd ? data.totalInterestUsd : 0) + openPosition.interest.usd;
      }
    }
 })
 return data; 
}

function geVaultOpenPosition(
  vault: VaultInterface,
  vaultSnapshot: VaultSnapshot
):OpenLoanPosition {
  const data: OpenLoanPosition = {
    address: vault.address,
    uSymbol: vault.uSymbol,
    uDecimals: vault.uDecimals,
    outstanding: {
      amount:  vaultSnapshot.borrowBalance,
      usd: Number(getUSDPrice(vaultSnapshot.oraclePrice, vaultSnapshot.borrowBalance, vault.uDecimals))
    },
    underlying: vault.underlying,
    underlyingBalance:vaultSnapshot.underlyingBalance 
  }
  return data;
}

interface LoanTimeline {
  borrowed: AmountWithUSD,
  repaid: AmountWithUSD,
  interest: AmountWithUSD,
  closedAt?: string
}
interface VaultLoanTineline {
  current: LoanTimeline,
  closed: LoanTimeline[]
}

function getVaultLoanTimeline(
  vault: VaultInterface,
  vaultSnapshot: VaultSnapshot,
  events: ContractEvent[]
): VaultLoanTineline {

  const defaultCurrent: LoanTimeline  = {
    borrowed: { amount: '0', usd: 0 },
    repaid: { amount: '0', usd: 0 },
    interest: { amount: '0', usd: 0 },
  }
  const data: VaultLoanTineline = {
    current: {
      borrowed: { amount: '0', usd: 0 },
      repaid: { amount: '0', usd: 0 },
      interest: { amount: '0', usd: 0 },
    },
    closed: []
  }
  events = events.filter((e)=> e.eventAddress.toLowerCase() === vault.address.toLowerCase() &&  ['Borrow', 'RepayBorrow'].includes(e.eventName));
  events.forEach((event) => {
    if (event.eventName === 'Borrow') {
      const eventData = event.eventData as BorrowEvent;
      data.current.borrowed.amount = addFixed(data.current.borrowed.amount, eventData.borrowAmount);
      data.current.borrowed.usd += Number(getUSDPrice(vaultSnapshot.oraclePrice, eventData.borrowAmount, vault.uDecimals));
    }

    if (event.eventName === 'RepayBorrow') {
      const eventData = event.eventData as RepayBorrowEvent;
      data.current.repaid.amount = addFixed(data.current.repaid.amount, eventData.repayAmount);
      data.current.repaid.usd += Number(getUSDPrice(vaultSnapshot.oraclePrice, eventData.repayAmount, vault.uDecimals));
      if(isZero(eventData.accountBorrows)) {
        /*
          push to closed, reset current
        */
        data.closed.push({...data.current, closedAt: event.timestamp});
        data.current = {...defaultCurrent};
      }
    }
  });
  const outstanding = vaultSnapshot.borrowBalance;
  if(isGreaterThan(outstanding, 0)) {
    data.current.interest.amount = minusFixed(outstanding, minusFixed(data.current.borrowed.amount, data.current.repaid.amount));
    data.current.interest.usd = Number(getUSDPrice(vaultSnapshot.oraclePrice, data.current.interest.amount, vault.uDecimals));
  }
  return data;
} 