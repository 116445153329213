import { Box, Button, Center, HStack, Icon, Img, Text, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Address} from "wagmi";
import { fixed2Decimals } from "../../utils/helpers";
import { getTokenImage } from "../../utils/image";
import { PiLockBold } from "react-icons/pi";
import moment from "moment";
import { useState } from "react";
import { WithdrawSend } from "./WitthdrawSend";
import { LockedData } from "./Tabs";

export function VeBalance({
  veTokenAddress,
  veBalance,
  lockedData
} : {
  veTokenAddress: Address,
  veBalance: string,
  lockedData: LockedData
}) {
  const [showModal, setShowModal] = useState(false);
  
  if(Number(veBalance) === 0) {
    return null;
  }

  return <Box
    borderRadius='16px'
    border='1px solid'
    borderColor='gray.150'
    padding='20px'
  >
    <Text textStyle='cta-1' color='primary.900'>
      {fixed2Decimals(veBalance, 18, 4)}
    </Text>
    <Text textStyle='body-2' color='gray.400'>veWEFI Balance</Text>
    
    <HStack alignItems='center' mt='14px'>
      <Img src={getTokenImage("wefi")} height='24px' />
      <Text textStyle='cta-1' color='primary.900' >{lockedData.amount > 0 ? 
        lockedData.amount
      : '---'}
      </Text>
    </HStack>
    <Text textStyle='body-2' color='gray.400'>WEFI Locked</Text>

    <HStack alignItems='center' mt='14px'>
      <Icon as={PiLockBold} fontSize='18px' />
      <Text textStyle='cta-1' color='primary.900'>
        {lockedData.amount > 0 ? 
          moment.unix(lockedData.unlockTime).format("ll")
        : '---'}
      </Text>
    </HStack>
    <Text textStyle='body-2' color='gray.400'>Unlock Time</Text>

    <Button
      isDisabled={moment().unix()< lockedData.unlockTime}
      variant='outline'
      mt='20px'
      onClick={()=> setShowModal(true)}
    >Unstake</Button>
    {showModal && <Modal isOpen={showModal} onClose={() => setShowModal(false)} isCentered size='sm'>
      <ModalOverlay/>
      <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getTokenImage("wefi")}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        <WithdrawSend
          veTokenAddress={veTokenAddress}
          amount={lockedData.amount.toString()}
          decimals={18}
          symbol="veWEFI"
          onCancel={()=> setShowModal(false)}
        />
      </ModalContent>
    </Modal>}
  </Box>
}
