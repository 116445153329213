
import { VaultInterface } from '../../constants/interfaces';
import { decimal2Fixed, divFixed, fixed2Decimals, mulFixed } from '../helpers';
import { APIEventRecord, BorrowEvent, ContractEvent, ContractEventName, ContractTx, LiquidateBorrowEvent, MintEvent, RedeemEvent, RepayBorrowEvent, SwapAndSettleEvent, TxnType } from './interfaces';
// import eventAPIRes from './txData.json';

export function processEventsAPIData(events:APIEventRecord[]): ContractEvent[] {
    let records = events.map((record)=> {
    const contractEvent: ContractEvent = {
      ...record,
      eventAddress: record.eventAddress.toLowerCase(),
      eventData: JSON.parse(record.data)
    }
    return contractEvent;
  }).sort((a, b)=>Number(a.timestamp) - Number(b.timestamp));
  // rearrange if repay is emitted after swapNSettle
  records.forEach((record, i) => {
      if (i> 0 && record.eventName === 'RepayBorrow' && records[i-1].transactionHash === record.transactionHash) {
        const settleRecord = records[(i-1)];
        records[i-1] = record;
        records[i] = settleRecord;
      }
  }) 
  return records;
}

export function getTransactions(vaults : VaultInterface[], events: ContractEvent[]) : ContractTx[] {
  return events.map((row)=> {
    let vault = vaults.find((item)=> item.address.toLowerCase() === row.eventAddress.toLocaleLowerCase());
    const eventName = row.eventName as ContractEventName;
    let txnType:TxnType = 'Tx';
    let amount = '';
    let symbol: string | undefined = '';
    
    if(eventName === 'Borrow') {
      let eventData = row.eventData as BorrowEvent;
      txnType = 'Borrow';
      symbol = vault?.uSymbol;
      amount = fixed2Decimals(eventData.borrowAmount, vault? vault.uDecimals: 18, 4);
    } else if(eventName === 'Mint') {
      let eventData = row.eventData as MintEvent;
      symbol = vault?.uSymbol;
      txnType = vault?.buyEnabled ? 'Invest' : 'Supply';
      amount = fixed2Decimals(eventData.mintAmount, vault? vault.uDecimals: 18, 4);
    } else if(eventName === 'Redeem') {
      txnType = 'Withdraw';
      let eventData = row.eventData as RedeemEvent;
      symbol = vault?.uSymbol;
      amount = fixed2Decimals(eventData.redeemAmount , vault? vault.uDecimals: 18, 4);
    } else if (eventName === 'SwapAndSettle') {
      txnType = 'Sell Position';
      let eventData = row.eventData as SwapAndSettleEvent;
      symbol = vault?.uSymbol;
      amount = fixed2Decimals(eventData.numTokensAmount , vault? vault.uDecimals: 18, 4);
    } else if (eventName === 'RepayBorrow') {
      txnType = 'Repay';
      let eventData = row.eventData as RepayBorrowEvent;
      symbol = vault?.uSymbol;
      amount = fixed2Decimals(eventData.repayAmount , vault? vault.uDecimals: 18, 4);
    }  else if (eventName === 'LiquidateBorrow') {
      txnType = 'LiquidateBorrow';
      let eventData = row.eventData as LiquidateBorrowEvent;
      let seizedVault = vaults.find((item)=> item.address.toLowerCase() === eventData.cTokenCollateral.toLowerCase());
      symbol = seizedVault?.uSymbol;
      amount = mulFixed(eventData.seizeTokens, 0.02);
      amount = fixed2Decimals(amount, seizedVault ? seizedVault.uDecimals : 18, 4, 1);
      // amount = fixed2Decimals(amount, (10 + (seizedVault? seizedVault.uDecimals: 18)) - 8);
    }

    return {
      symbol: symbol,
      amount,
      txnType,
      txHash: row.transactionHash,
      timestamp: Number(row.timestamp)
    }
  }).sort((a, b)=> b.timestamp - a.timestamp);
}

