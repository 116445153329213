import { Box, Button, Modal, ModalContent, ModalOverlay, Center } from "@chakra-ui/react";
import { useState } from "react";
import { Address, zeroAddress } from "viem";
import { useAccount, useContractRead, erc20ABI } from "wagmi";
import { fixed2Decimals, isGreaterThanOrEqualTo } from "../utils/helpers";
import { TxStep } from "./ModalWithdraw";
import { Quote } from "../hooks/useDexQuotesRead";
import { ApproveSpend } from "./ApproveSpend";
import { BorrowSend } from "./BorrowSend";

interface BorrowAndBuyProps {
  isDisabled: boolean;
  borrowAmount: string;
  partialAmount: string;
  underlying: Address;
  uDecimals: number;
  uSymbol: string;
  vaultAddress: Address,
  borrowVaultAddress: Address,
  outAmount: string;
  outSymbol: string;
  outDecimals: number;
  quote: Quote | null
}


export function BorrowAndBuy({
  isDisabled,
  borrowAmount,
  partialAmount,
  underlying,
  uDecimals,
  vaultAddress,
  borrowVaultAddress,
  uSymbol,
  outAmount,
  outSymbol,
  outDecimals,
  quote
}: BorrowAndBuyProps) {
  const [activeStep, setActiveStep] = useState<TxStep>("input");
  const {address: userAddress} = useAccount();
  const { data : amountApproved} = useContractRead({
    abi: erc20ABI,
    address: userAddress ? underlying : undefined,
    functionName: 'allowance',
    args: [userAddress || zeroAddress, borrowVaultAddress]
  });
  const hasApproval = isGreaterThanOrEqualTo(
    amountApproved? amountApproved.toString(): 0,
    partialAmount
  );
  return (
    <Box w='100%'>
      <Button
        isDisabled={isDisabled}
        w='full'
        size='lg'
        colorScheme='primary'
        onClick={()=> {
          if (hasApproval) {
            setActiveStep('submit');
          } else {
            setActiveStep('approval');
          }
        }}
      >
          Invest
      </Button>
      <Modal
        isOpen={(activeStep === 'approval' || activeStep==='submit')}
        onClose={()=> setActiveStep('input')}
        isCentered
        size='sm'>
        <ModalOverlay/>
          <ModalContent px='20px' pb="28px">
            <Center>
              {activeStep === 'approval' && <ApproveSpend
                amount={fixed2Decimals(partialAmount, uDecimals)}
                tokenAddress={underlying}
                spender={borrowVaultAddress}
                symbol={uSymbol}
                decimals={uDecimals}
                ctaText={`Proceed to Trade`}
                onCancel={()=> setActiveStep('input')}
                onProceed={()=> setActiveStep('submit')}
              />}
              {activeStep === 'submit' && <BorrowSend
                vaultAddress={vaultAddress}
                borrowVaultAddress={borrowVaultAddress}
                partialAmount={partialAmount}
                borrowAmount={borrowAmount}
                outAmount={outAmount}
                fee={quote?.fee ? quote.fee: '3000'}
                dexId={quote? quote.dexId : ''}
                quoteJson={quote && quote.quoteJSON ? quote.quoteJSON : null}
                outDecimals={outDecimals}
                outSymbol={outSymbol}
                onCancel={()=> setActiveStep('input')}
              />}
              </Center> 
          </ModalContent>
      </Modal>
    </Box>
  )
}