import { ethers} from "ethers";
import { AlphaRouter, ChainId, SwapType } from "@xspswap/smart-order-router";
import { Token, Percent, CurrencyAmount, TradeType } from '@uniswap/sdk-core';
import { Vaults, rpc } from "../constants/xdc";

export interface RouteParams {
  tokenIn: string;
  tokenOut: string;
  amountIn: string; 
  recipient: string;
  slippage: number;
}

export async function getXswapRoute(params: RouteParams) {
  const dataTokenIn = Vaults.find((item)=> item.underlying.toLowerCase() === params.tokenIn.toLowerCase());
  const dataTokenOut = Vaults.find((item)=> item.underlying.toLowerCase() === params.tokenOut.toLowerCase());

  if (!dataTokenIn || ! dataTokenOut) {
    throw new Error("Input Token not found in Vaults!");
  }

  const token0 = new Token(50, dataTokenIn.underlying, dataTokenIn.uDecimals, dataTokenIn.uSymbol);
  const token1 = new Token(50, dataTokenOut.underlying, dataTokenOut.uDecimals, dataTokenOut.uSymbol);
  const router = new AlphaRouter({
    chainId: ChainId.XDC,
    provider: new ethers.providers.JsonRpcProvider(
      rpc
    ),
  })
  
  const options = {
    recipient: params.recipient, 
    slippageTolerance: new Percent(params.slippage, 10_000),
    deadline: Math.floor(Date.now() / 1000 + 120), // 0 deadline
    type: SwapType.SWAP_ROUTER_02,
  }

  const route = await router.route(
    CurrencyAmount.fromRawAmount(
      token0 , // token in
      params.amountIn
    ),
    token1, // token out
    TradeType.EXACT_INPUT,
    options
  )

  if(!route) {
    throw new Error("No Route Found!");
  }
  const callData = route.methodParameters?.calldata;
  const quoteAmount = route.quote.numerator.toString();
  const routes = route.route.map((r) => r.tokenPath.map((t) => t.symbol));

  return {
    calldata: callData,
    quoteAmount: quoteAmount,
    routes: routes
  }
}