import { Box, Divider, Flex, Text } from "@chakra-ui/react"
import TradeSelector from "./TradeSelector"
import { TradePair } from "../../routes/leverageVaults/LeverageVaulltsPageNew"
import { getInvestmentVaults } from "../../utils/investments";
import { useAtom } from "jotai";
import { atomMenuCollapsed, atomVaultsSnapshot, atomWeFiPrice } from "../../atoms";
import { VaultData } from "./VaultData";
import { BuySellTabs } from "../BuySellTabs";
import { TradingViewWidget } from "../TradingView";
import { PositionTabs } from "./PositionTabs";
import { VaultInterface } from "../../constants/interfaces";

export function LeverageTrade({
  chainId,
  blocksPerDay,
  tradePair,
  vaults
} : {
  chainId: number,
  blocksPerDay: number,
  tradePair: TradePair,
  vaults: VaultInterface[]
}) {
  const [snapshot] = useAtom(atomVaultsSnapshot);
  const [wefiPrice] = useAtom(atomWeFiPrice);
  const [collapsed] = useAtom(atomMenuCollapsed);
  if(snapshot === null) {
    return <Text>Loading...</Text>
  }

  const investmentVaultData = getInvestmentVaults([tradePair.investmentVault, tradePair.borrowVault], snapshot, blocksPerDay, chainId === 50 ? 1 : wefiPrice)[0];

  return <Flex gap='10px' direction='column'>
    <Flex direction={{base: 'column', sm: 'column', md: 'row'}} gap='20px'>
      <Box w={{base: 'full', sm: '100%', md: collapsed? '70%' : '65%'}}
      >
        <Flex
          border='1px solid'
          borderColor='gray.150'
          borderTopLeftRadius='16px'
          borderTopRightRadius='16px'
          px={2}
          bgColor='gray.100'
        >
          <TradeSelector
            blocksPerDay={blocksPerDay}
            chainId={chainId}
            tradePair={tradePair}
            vaults={vaults}
          />
          <Divider borderColor='gray.150' mx={2} orientation="vertical" height='100px'/>
          <VaultData 
            chainId={chainId}
            data={investmentVaultData}
          />
        </Flex>
        {tradePair.investmentVault.tradingWidgetConfig && <TradingViewWidget 
          {...tradePair.investmentVault.tradingWidgetConfig}
        />}
        <PositionTabs 
          vaults={vaults}
          snapshot={snapshot}
          chainId={chainId}
        />
      </Box>
      <Box w={{base: 'full', sm: '100%', md: collapsed ? '30%' : '35%'}}>
        <BuySellTabs
          vault={tradePair.investmentVault}
          snapshot={snapshot}
          vaultData={investmentVaultData}
        />
      </Box>
    </Flex>
  </Flex>
}