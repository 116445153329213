import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  UseModalProps,
  HStack,
  Link,
  SimpleGrid,
  Button,
  Img,
  Box,
  Text,
  useBreakpointValue,
  SlideDirection,
  Icon,
  CloseButton,
  Flex
} from '@chakra-ui/react';
import { VaultInterface } from '../../constants/interfaces';
import { InvestmentVault, getInvestmentVaults } from '../../utils/investments';
import { useAtom } from 'jotai';
import { atomVaultsSnapshot, atomWeFiPrice } from '../../atoms';
import { useNetwork } from 'wagmi';
import StackedImages from '../StackedImages';
import { getTokenImage } from '../../utils/image';
import { TradePair } from '../../routes/leverageVaults/LeverageVaulltsPageNew';
import { useNavigate } from 'react-router-dom';

interface DrawerPairsProps extends UseModalProps {
  activePair: TradePair;
  vaults: VaultInterface[];
  chainId: number;
  blocksPerDay: number;
}

export default function DrawerPairs({
  activePair,
  chainId,
  blocksPerDay,
  vaults,
  isOpen,
  onClose
}: DrawerPairsProps) {
  const navigate = useNavigate();
  const placement = useBreakpointValue<SlideDirection>({base: 'left', sm: 'top', md: 'left'});
  const [snapshot] = useAtom(atomVaultsSnapshot);
  const [wefiPrice] = useAtom(atomWeFiPrice);
  const investmentVaults = getInvestmentVaults(vaults, snapshot, blocksPerDay, chainId === 50 ? 1 : wefiPrice);
  const getTradingPairs =  () => {
    const data: InvestmentVault[] = [];
    investmentVaults && investmentVaults.forEach((vaultData)=> {
      vaultData.borrowVaults.forEach((v)=> {
        data.push({...vaultData, borrowVaults: [v]});
      })
    });
    return data;
  }
  return <Drawer
    isOpen={isOpen}
    placement={placement}
    onClose={onClose}
  >
    <DrawerOverlay />
    <DrawerContent
      
      borderRightRadius='16px'
      // mr='12px'
      // my='12px'
      width='340px'
      maxW='340px'
    >
      {/* <DrawerCloseButton color='gray.500' /> */}
      <DrawerBody p={0}>
        <Flex bgColor='gray.100' px={5} py={4} w='100%' alignItems='center' gap={2}>
          <Box>
            <StackedImages
              height='25px'
              leftUnits="-5px"
              images={[getTokenImage(activePair.borrowVault.uSymbol), getTokenImage(activePair.investmentVault.uSymbol)]}
            /> 
          </Box>
          <Text textStyle='subheading-1' color='primary.900'>
            {activePair.investmentVault.uSymbol}&nbsp;/&nbsp;{activePair.borrowVault.uSymbol}
          </Text>
          <CloseButton color='primary.900' opacity={0.64} marginLeft='auto' onClick={()=> onClose()}/>
        </Flex>
        {getTradingPairs().map((row, i)=> <TradePairRow
          vaultData={row}
          handleNavigate={(pair)=> {
            navigate(`/leverage-vaults/${pair}`);
            onClose();
        }}
        /> )}
      </DrawerBody>
      {/* <DrawerFooter
        justifyContent='flex-start'
      >
        
      </DrawerFooter> */}
    </DrawerContent>
  </Drawer>
}


export function TradePairRow({
  vaultData,
  handleNavigate
}: {
  vaultData: InvestmentVault,
  handleNavigate: (pair: string) => void
}) {
  const borrowVault = vaultData.borrowVaults[0];
  if (!borrowVault) {
    return null;
  }
  return <SimpleGrid columns={2} spacing={2}
  borderTop='1px solid'
  py={4}
  px={5}
  borderColor='gray.150'
  color='primary.900'
  cursor='pointer'
  textStyle='body-2'
  _hover={{
    textStyle: 'body-bold-2',
    backgroundColor: 'gray.100'
  }}
  onClick={()=> handleNavigate(`${borrowVault.symbol.toLowerCase()}-${vaultData.symbol.toLowerCase()}`)}
>
  <Flex alignItems='center' gap={3}>
    <StackedImages
      w='20px'
      height='20px'
      leftUnits="-5px"
      images={[getTokenImage(borrowVault.symbol), getTokenImage(vaultData.symbol)]}
    />
    <Text>
    {vaultData.symbol}&nbsp;/&nbsp;{borrowVault.symbol}
    </Text>
  </Flex>
  <Text textAlign='right'>
    ${vaultData.priceUsd?.toFixed(2)}
  </Text>
</SimpleGrid>
}


