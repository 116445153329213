import { Address } from "viem";
import { getDexQuoters } from "../constants";
import { useAccount, useNetwork } from "wagmi";
import { useDexQuotesRead, Quote } from "../hooks/useDexQuotesRead";
import { HStack, Menu, MenuButton, Text, MenuList, MenuItem, Icon, Img, Box, Spinner } from "@chakra-ui/react";
import { IconAngleDown } from "./CustomIcons";
import { fixed2Decimals } from "../utils/helpers";
import { useEffect, useState } from "react";
import { getTokenImage } from "../utils/image";
import { useAtom } from "jotai";
import { atomDefaultChainID } from "../atoms";
import { DexType } from "../constants/interfaces";

interface DexQuotesProps {
  amountIn: string;
  tokenIn: Address;
  tokenOut: Address;
  decimalsIn: number;
  decimalsOut: number;
  symbolOut: string;
  recipient: Address;
  onQuoteUpdate: (quote: Quote | null) => void
}

export function DexQuotes({
  amountIn,
  tokenIn,
  tokenOut,
  decimalsIn,
  decimalsOut,
  symbolOut,
  recipient,
  onQuoteUpdate
}: DexQuotesProps) {
  const [activeQuote, setActiveQuote] = useState<Quote|null>(null);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const {isConnected} = useAccount();
  const {chain} = useNetwork();
  const [defaultChainId] = useAtom(atomDefaultChainID);

  const quoters = getDexQuoters(isConnected ? chain?.id: defaultChainId);
  const onUpdate = (dexQuotes: Quote[], type: DexType)=> {
    let newQuotes = [ ...quotes.filter((q)=> q.dexType !== type) , ...dexQuotes];
    newQuotes = newQuotes.sort((a, b)=> Number(b.quote)- Number(a.quote));

    const active = newQuotes.length === 0 ? null : newQuotes[0];
    setQuotes(newQuotes);
    setActiveQuote(active);
    onQuoteUpdate(active);
  }
  const isLoading = useDexQuotesRead(
    quoters,
    amountIn,
    tokenIn,
    tokenOut,
    recipient,
    isConnected ? chain?.id: defaultChainId,
    onUpdate
  );
  
  return (
    <Menu>
      <MenuButton
        as={Box}
        disabled={activeQuote === null}
        cursor='pointer'
      >
        <HStack cursor='pointer' justifyContent='flex-end'>
          {isLoading && <Spinner size='xs' colorScheme='primary'/>}
          <Text textStyle='label' color='gray.400' textTransform='capitalize'>
            {activeQuote ? `${activeQuote.dexName}` : isLoading ? '': 'Could not find swap route'}
          </Text>
          {activeQuote && <Icon textStyle='label' color='gray.400' as={IconAngleDown}/>}
        </HStack>
      </MenuButton>
      <MenuList>
        {quotes.map((item)=> (
          <MenuItem key={item.dexId} 
            onClick={()=> {
              setActiveQuote(item);
              onQuoteUpdate(item);
            }}
          >
            <HStack spacing='10px'>
              <Text textStyle='labelBold' color='gray.500' textTransform='uppercase'>{item.dexName}</Text>
              <Img src={getTokenImage(symbolOut)} h='12px'/>
              <Text textStyle='labelBold' color='primary.900'>{fixed2Decimals(item.quote, decimalsOut, 4)}</Text>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) 
}