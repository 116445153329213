import { Box, Text, Button, Link, SimpleGrid, HStack, Img, VStack, Flex, Divider, Center, Icon, useBreakpointValue } from "@chakra-ui/react";
import { useBalance, useAccount, useNetwork } from 'wagmi'
import { getVaults } from "../constants";
import { ModalFaucet } from "./ModalFaucet";
import { useState } from "react";
import { getFaucetAddress, getFaucetURL } from "../constants";
import { getTokenImage } from "../utils/image";
import { zeroAddress } from "viem";
import {FcMoneyTransfer} from 'react-icons/fc';

export default function Faucet() {
  const [getTokens, setGetTokens] = useState(false);

  const {address: userAddress} = useAccount();
  const {chain} = useNetwork()
  
  const tokenVault = getVaults(chain?.id).find((vault) => vault.lendEnabled === true);
  
  const {data: balance} = useBalance({
    address : userAddress,
    watch: true
  });
  const {data: tokenBalance} = useBalance({
    address: userAddress,
    token: tokenVault ? tokenVault.underlying : zeroAddress,
    watch: true
  })
  
  return (
    <Flex
      px='56px'
      py='21px'
      border="1px solid"
      borderColor="gray.100"
      borderRadius="16px"
      alignItems='center'
      flexDirection={useBreakpointValue({base: 'column', sm: 'column', md: 'row'})}
    >
      <Box w={{base: '100%', sm: '100%', md: '33%'}}>
        <HStack>
          <Icon as={FcMoneyTransfer} fontSize='5xl'/>
          <Text
            textStyle="body-2"
            color= "gray.500"
            mt="4px"
          >Testnet Faucet</Text>
        </HStack>
      </Box>
      <Center mx='25px' height={useBreakpointValue({base: '0px', sm: '0px', md: '50px'})}>
       <Divider orientation={useBreakpointValue({base: "horizontal", sm: "horizontal", md: "vertical"})} />
      </Center>
      <Box w={{base: '100%', sm: '100%', md: '33%'}}>
        <SimpleGrid columns={2} spacing='20px'>
          <HStack spacing='12px'>
            <Img src={getTokenImage(chain?.nativeCurrency ? chain.nativeCurrency.symbol: '')} height='32px' width='32px' />
            <VStack spacing='4px' alignItems="flex-start">
              <Text textStyle='cta-2' color='gray.800'>{chain?.nativeCurrency.symbol}</Text>
              <Text textStyle='body-bold-3' color="gray.500">{balance? Number(balance.formatted).toFixed(4) : ''}</Text>
            </VStack>
          </HStack>
          <Box textAlign='right'>
            <Button as={Link} size='sm' variant='outline'  isExternal href={getFaucetURL(chain?.id)} >
              Request {chain?.nativeCurrency.symbol}
            </Button> 
          </Box>
        </SimpleGrid>
        
      </Box>
      <Center mx='25px' height={useBreakpointValue({base: '0px', sm: '0px', md: '50px'})}>
       <Divider orientation={useBreakpointValue({base: "horizontal", sm: "horizontal", md: "vertical"})} />
      </Center>
      <Box w={{base: '100%', sm: '100%', md: '33%'}}>
        <SimpleGrid columns={2} spacing='20px'>
          <HStack spacing='12px'>
            <Img src={getTokenImage(tokenVault ? tokenVault.uSymbol: '')} height='32px' width='32px' />
            <VStack spacing='4px' alignItems="flex-start">
              <Text textStyle='cta-2' color='gray.800'>{tokenVault?.uSymbol}</Text>
              <Text textStyle='body-bold-3' color="gray.500">{tokenBalance? Number(tokenBalance.formatted).toFixed(4) : ''}</Text>
            </VStack>
          </HStack>
          <Box textAlign='right'>
            <Button size="sm" variant='outline' onClick={()=> setGetTokens(true)}>
              Request {tokenVault?.uSymbol}
            </Button>
          </Box>
        </SimpleGrid>
        <ModalFaucet
          isOpen={getTokens}
          onClose={()=> setGetTokens(false)}
          faucetAddress={getFaucetAddress(chain?.id)}
        />
      </Box>
    </Flex>
    
  )
}