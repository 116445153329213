import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0x7f6864e4ef5ba50dd1b38a4ef6ef9530f758377b",
    decimals: 8,
    underlying: "0x3200a835da2567435cd93163672d731deb1f53c0",
    uSymbol: "USDC",
    uName: "USDC",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0x68e2cec5e093375edd74b5ff1b99dc22314d2259"]
  },
 
  {
    address: "0x68e2cec5e093375edd74b5ff1b99dc22314d2259",
    decimals: 8,
    underlying: "0xf01259739ff6099e7242c0f753b2524222fe9471",
    uSymbol: "WETH",
    uName: "WETH",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x7f6864e4ef5ba50dd1b38a4ef6ef9530f758377b"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  }
];

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0xb81B5f693a3E5859103c297f770075e62a3b0a8C",
  'Comptroller': "0x2D434E9d354b4FbE11F9ebF5AfA36C7787DCBA6C"
}

export const defaultTradePair  = "usdc-weth";
export const blockExplorer = 'https://goerli.basescan.org';

export const blocksPerDay=43200;

export const DexQuoters : DexQuoter[] = [
  // {
  //   id: '1',
  //   name: 'Uniswap V3',
  //   slug: 'uniswap-v3',
  //   type: 'Contract',
  //   address: "0xedf539058e28E5937dAef3f69cEd0b25fbE66Ae9",
  //   functionName: 'quoteExactInputSingle'
  // }
  {
    id: '2',
    name: 'Alien Base',
    slug: 'quickswap-v2',
    type: 'Contract',
    address: "0x39Dcd5aeE4E1461804842BA3300442765a1265C9",
    functionName: 'getAmountsOut'
  }
]

export const eventsApiChainSlug='base-testnet';

export const faucetURL = "https://www.coinbase.com/faucets/base-ethereum-goerli-faucet";
export const faucetAddress = "0x9c8Cc59D5c2d3aD32e2a413b53b46CB3ecb0640E";