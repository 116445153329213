import { Box, Button, HStack, Icon, Img, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { getTokenImage } from '../utils/image';
import { decimal2Fixed, fixed2Decimals, isGreaterThan, isGreaterThanOrEqualTo, isLessThan, isLessThanOrEqualTo } from '../utils/helpers';
import { erc20ABI, useAccount, useContractRead } from 'wagmi';
import { useState } from 'react';
import { zeroAddress } from 'viem';
import { OpenLoanPosition } from '../utils/loanPositions';
import { PiCaretDownBold, PiCheckCircleFill } from 'react-icons/pi';

interface RepayProps extends OpenLoanPosition {
  loanPositions: OpenLoanPosition[];
  onPosChange: (pos: OpenLoanPosition) => void;
  sendApproval: (amount: string)=> void;
  sendRepay: (amount: string) => void;
}

export function Repay({
  address,
  underlying,
  underlyingBalance,
  outstanding,
  uSymbol,
  uDecimals,
  sendApproval,
  sendRepay,
  loanPositions,
  onPosChange
}: RepayProps) {
  const [amountRepay, setAmountRepay] = useState<string>("0");
  const {address: userAddress} = useAccount();
  const { data : amountApproved} = useContractRead({
    abi: erc20ABI,
    address: underlying,
    functionName: 'allowance',
    args: [userAddress || zeroAddress, address]
  });


  const handleMax = ()=> {
    if(Number(underlyingBalance) > 0) {
      setAmountRepay(
        fixed2Decimals(outstanding.amount, uDecimals)
      );
    }
  }

  const hasApproval = isGreaterThanOrEqualTo(
    amountApproved? amountApproved.toString(): 0,
    decimal2Fixed(amountRepay, uDecimals)
  );

  return (
    <Box mt='12px'>
      <Box textAlign='center' >
        <Menu>
          <MenuButton color='primary.900'>
            <Text mb='8px' textStyle='body-3' opacity='0.64'>Repaying to</Text>
            <HStack>
              <Img src={getTokenImage(uSymbol)} h='20px' w='20px' />
              <Text textStyle='body-bold-1'>{uSymbol}</Text>
              <Icon as={PiCaretDownBold} opacity='0.64' />
            </HStack>
          </MenuButton>
          <MenuList>
            {loanPositions.map((pos, i)=> (
              <MenuItem onClick={()=> {
                onPosChange(pos);
                setAmountRepay("0");
              }} key={i}>
                <HStack>
                  <Img src={getTokenImage(pos.uSymbol)} h='20px' w='20px' />
                  <Text textStyle='body-bold-1'>{pos.uSymbol}</Text>
                  {uSymbol === pos.uSymbol && <Icon color='primary.500' as={PiCheckCircleFill} />}
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
      
      <InputGroup mt='20px' borderRadius='12px'>
        <Input
          value={amountRepay}
          variant='filled'
          type='number'
          size='lg'
          onChange={(e)=> setAmountRepay(e.target.value)}
        />
        <InputRightElement mx='16px' my='8px'>
          <Button variant='ghost' colorScheme='primary' onClick={()=> handleMax()}>
            MAX
          </Button>
        </InputRightElement>
      </InputGroup>
      <Text
        textStyle='body-bold-3'
        color='primary.900'
        opacity='0.6399999856948853'
        textAlign='right'
        mt='12px'
      >
        Bal. {fixed2Decimals(underlyingBalance, uDecimals)}
      </Text>
      <VStack mt='28px'>
        <SimpleGrid
          columns={2} spacing='20px'
          borderBottom='1px solid'
          py="12px"
          borderColor='gray.150'
          w="100%"
        >
          <Text
            textStyle="body-bold-3"
            color='primary.900'
            opacity='0.6399999856948853'
          >
            Borrow Outstanding 
          </Text>
          <Text textStyle="body-bold-2"
            color='primary.900'
            justifySelf='flex-end'
          >
            {fixed2Decimals(outstanding.amount, uDecimals)} {uSymbol}
          </Text>
        </SimpleGrid>
      </VStack>
      <Button
        isDisabled={
          isLessThanOrEqualTo(amountRepay, 0) ||
          isLessThan(underlyingBalance, decimal2Fixed(amountRepay, uDecimals)) ||
          isGreaterThan( decimal2Fixed(amountRepay, uDecimals), outstanding.amount) ||
          amountApproved === undefined
        }
        mt='28px'
        w='full'
        colorScheme='primary'
        size='lg'
        onClick={()=> {
          if (hasApproval) {
            sendRepay(amountRepay);
          } else {
            sendApproval(amountRepay);
          }
        }}
      >
        Proceed
      </Button>
      {Number(underlyingBalance) < Number(decimal2Fixed(amountRepay, uDecimals)) && <Text 
        textAlign='center'
        color="orange.500"
        mt="20px"
        textStyle="body-bold-2"
      >
        Insufficient wallet balance
      </Text>}
    </Box>
  )
}