import { Button, Img, Icon, Menu, MenuButton, MenuItem, MenuList, HStack, Text, Spinner, MenuDivider, MenuGroup, Switch, Link } from '@chakra-ui/react'
import { IconAngleDown } from './CustomIcons';
import { Chain, useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { getChainIcon } from '../utils/image';
import { PiCaretDownBold, PiCaretUpBold, PiCheckCircleFill, PiWarningOctagonDuotone } from 'react-icons/pi';
import { useAtom } from 'jotai';
import { atomDefaultChainID, atomDefaultChainIDWrite } from '../atoms';
import { availableChains } from '../web3/config';
import { FiExternalLink } from 'react-icons/fi';
import { XDC_TESTNET_ID } from '../constants';
import { useState } from 'react';

export const ChainSelector = ({
  shwoNetworkName
}: {shwoNetworkName?: boolean})=> {
  const {isConnected} = useAccount();
  const {chain:activeConnectedChain, chains} = useNetwork();
  const [defaultChainId] = useAtom(atomDefaultChainID);
  const [, setDefaultChainId] = useAtom(atomDefaultChainIDWrite);
  const [showUpcoming, setUpcoming] = useState(false);
  const [showTestnets, setTestnets] = useState(false);
  const { 
    error,
    isLoading,
    pendingChainId,
    switchNetwork
  } = useSwitchNetwork({
    onSuccess: (data)=> {
      setDefaultChainId(data.id);
    }
  });
  
  const activeChain = isConnected ? activeConnectedChain : {id: defaultChainId, unsupported: false};

  // const upcomingChains = [
  //   // {chainId: 50, name: 'XDC Network'},
  //   {chainId:42161 , name: "Arbitrum"},
  //   {chainId:1 , name: "Ethereum"},
  //   {chainId:8453 , name: "Base"},
  //   {chainId:56 , name: "BNB Smart Chain"},
  // ];
  return (
    <Menu closeOnSelect={false}>
      <MenuButton 
        as={Button}
        variant='outline'
        colorScheme='gray'
      >
        {activeChain ? <HStack spacing='0px'>
          {activeChain.unsupported ? <Icon as={PiWarningOctagonDuotone} color="orange.300" h='24px' w='24px' /> 
          :<Img h="24px" w="24px" src={getChainIcon(activeChain.id)}/>}
          {activeChain.unsupported && shwoNetworkName && <Text textStyle='body-1' mx='10px'>Select Network</Text>}
          <Icon h='20px' w='20px' as={IconAngleDown} />
        </HStack> : <Text>Select Chain</Text> }
      </MenuButton>
      <MenuList>
        {/* <MenuItem
          // 
        >
          <HStack
            textStyle='body-bold-3'
          >
            <Switch
              id='testnetSwitch'
              colorScheme='primary'
              isChecked={showTestnets}
              onChange={()=> setTestnets(!showTestnets)}
            />
            <Text onClick={()=> setTestnets(!showTestnets)} color='gray.500' fontWeight='500'>Show Testnets</Text>
          </HStack>
        </MenuItem> */}
        {(isConnected ? chains : availableChains).map((chain:Chain)=> {
          if(!showTestnets && (chain.testnet || chain.id === XDC_TESTNET_ID)) {
            return null;
          }
          return (
            <MenuItem
              key={chain.id}
              onClick={() => {
                if(activeChain?.id && chain.id !== activeChain?.id) {
                  if(isConnected) {
                    switchNetwork?.(chain.id)
                  } else {
                    setDefaultChainId(chain.id);
                  }
                }
              }}
            >
              <HStack spacing='10px'>
                <Img h="24px" w="24px" src={getChainIcon(chain.id)}/>
                <Text>{chain.name}</Text>
                {chain.id === activeChain?.id && <Icon
                  as={PiCheckCircleFill}
                  color="primary.200"
                  w="16px"
                  h="16px"
                />}
              </HStack>
              {error === null && isLoading && pendingChainId === chain.id && <Spinner
                ml="5px"
                thickness='2px'
                speed="2s"
                size="sm"
                color="primary.100"
                emptyColor="primary.50"
              />}
            </MenuItem>
          )
        })}
        <MenuItem as={Link} href="https://beta.vaults.wefi.xyz" isExternal>
          <HStack spacing='10px'>
            <Img h="24px" w="24px" src={getChainIcon(80001)}/>
            <Text>(Legacy app) Polygon</Text>
            <Icon
              as={FiExternalLink}
              color="primary.200"
              w="16px"
              h="16px"
            />
          </HStack>
        </MenuItem>
        {/* <MenuDivider/> */}
        {/* <HStack
          onClick={()=> setUpcoming(!showUpcoming)}
          _hover={{bg: 'gray.100'}}
          textStyle='body-bold-2'
          cursor='pointer'
        >
          <Text color='gray.500' fontWeight='500'>Upcoming Networks ({upcomingChains.length})</Text>
          <Icon as={showUpcoming ? PiCaretUpBold : PiCaretDownBold} />
        </HStack> */}
        {/* {showUpcoming && <MenuGroup>
          {upcomingChains.map((network, i)=> {
            return <MenuItem key={i}>
            <HStack spacing='10px'>
                <Img h="16px" w="16px" src={getChainIcon(network.chainId)}/>
                <Text fontSize='14px' fontWeight='500' color='gray.300'>{network.name}</Text>
              </HStack>
          </MenuItem>
          })}
        </MenuGroup>} */}
      </MenuList>
    </Menu>
  )
}