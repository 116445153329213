import { Box, Button, Center, HStack, Img, Text, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Address, useAccount, useContractRead, useNetwork, useToken } from "wagmi";
import { getContractAddress } from "../../constants";
import StakingRewardAbi from '../../constants/abi/StakingReward.json';
import { getTokenImage } from "../../utils/image";
import { fixed2Decimals } from "../../utils/helpers";
import { useState } from "react";
import { ClaimSend } from "./ClaimSend";

export default function Rewards({
  rewardContractAddress
}: {rewardContractAddress: Address | undefined}) {
  const {address} = useAccount();
  const {chain} = useNetwork();
  const [showModal, setShowModal] = useState(false);

  const tokenAddress = getContractAddress({chainId: chain?.id, contractName: 'StakingRewardToken'});
  const {data: amount} = useContractRead({
    address: rewardContractAddress,
    abi: StakingRewardAbi,
    functionName: 'claimToken',
    args: [address, tokenAddress],
    watch: rewardContractAddress !== undefined
  });

  const claimAmount = amount ? amount.toString() : "0";

  const {data: tokenMeta} = useToken({address: tokenAddress});
  if (rewardContractAddress === undefined || tokenAddress === undefined || amount === null || !tokenMeta) {
    return null;
  }

  return <Box
    borderRadius='16px'
    border='1px solid'
    borderColor='gray.150'
    padding='20px'
  >
    <Text textStyle='cta-2' color='primary.900'>
      Staking Rewards
    </Text>
    <HStack alignItems='center' mt='10px'>
      <Img src={getTokenImage(tokenMeta.symbol)} height='24px' />
      <Text textStyle='cta-1' color='primary.900' >
        {fixed2Decimals(claimAmount, tokenMeta.decimals, 4)}
      </Text>
    </HStack>
    <Button 
      mt='14px'
      isDisabled={Number(claimAmount) === 0}
      variant='outline'
      onClick={()=> setShowModal(true)}
    >
      Claim
    </Button>
    {showModal && <Modal isOpen={showModal} onClose={() => setShowModal(false)} isCentered size='sm'>
      <ModalOverlay/>
      <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getTokenImage(tokenMeta.symbol)}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        <ClaimSend
          contractAddress={rewardContractAddress}
          tokenAdddress={tokenAddress}
          amount={claimAmount}
          decimals={tokenMeta.decimals}
          symbol={tokenMeta.symbol}
          onCancel={()=> setShowModal(false)}
        />
      </ModalContent>
    </Modal>}
  </Box>
}