import {
  Box,
  Flex,
  HStack,
  Img,
  SimpleGrid,
  Text,
  VStack
} from "@chakra-ui/react";

import { getTokenImage } from "../utils/image";
import { BorrowVault } from "../utils/investments";
import { useEffect, useState } from "react";
import {
  decimal2Fixed,
  divFixed,
  fixed2Decimals,
  isGreaterThan,
  isZero,
} from "../utils/helpers";
import { DexQuotes } from "./DexQuotes";
import { VaultInterface } from "../constants/interfaces";
import { Quote } from "../hooks/useDexQuotesRead";
import { VaultSnapshot } from "../atoms";
import { Sell } from "./Sell";
import { DebouncedInput2 } from "./DebouncedInput";

export function SellTab({
  vault,
  vaultData,
  borrowVaults
}: {vault: VaultInterface, vaultData: VaultSnapshot , borrowVaults: BorrowVault[]}) {
  const [activeBorrowVault, setActiveBorrowVault] = useState<BorrowVault | undefined>(borrowVaults[0]);
  const [amountIn, setAmountIn] = useState<string>("0");
  const [quote, setQuote] = useState<Quote | null>(null);
  
  useEffect(()=> {
    if(!activeBorrowVault && borrowVaults.length > 0) {
      setActiveBorrowVault(borrowVaults[0])
    }
  },[borrowVaults, activeBorrowVault]);
  if(!activeBorrowVault) {
    return null;
  }

  const balanceIn = fixed2Decimals(vaultData.lendBalance, vault.uDecimals);

  const amountToTokens = (amount:string) => {
    return divFixed(decimal2Fixed(amount, vault.uDecimals), fixed2Decimals(vaultData.exchangeRateStored, 18));
  }

  return (
    <Box
      // borderRadius='16px'
      // border='1px solid'
      // borderColor='gray.150'
    >
      <Box mb={2} bgColor='#F4F5FB' p={4} borderRadius='8px'>
        <Flex mb={1}>
          <Box w='30%'>
            <Text textStyle='label' color='gray.400'>Sell</Text>
          </Box>
          <Box w='70%' >
            <Text textAlign='right' color='gray.400' textStyle='label'>Balance: {balanceIn}</Text>
          </Box>
        </Flex>
        <DebouncedInput2 
          variant='unstyled'
          type='number'
          value={amountIn}
          balanceIn={balanceIn}
          onChange={(e)=> setAmountIn(e)}
          symbol={vault.uSymbol}
        />
      </Box>
      <Box mb={5} bgColor='#F4F5FB' p={4} borderRadius='8px'>
        <Flex mb={1}>
          <Box w='30%'>
            <Text textStyle='label' color='gray.400'>{!isZero(amountIn) && quote && quote.quote && activeBorrowVault.priceUsd ? `~ $${(activeBorrowVault.priceUsd * Number(fixed2Decimals(quote.quote, activeBorrowVault.uDecimals))).toFixed(4)}` : '~' }</Text>
          </Box>
          <Box w='70%' >
            {(isZero(amountIn) || amountIn === "") ? '' : <DexQuotes
                amountIn={decimal2Fixed(amountIn, vault.uDecimals)}
                tokenIn={vault.underlying}
                tokenOut={activeBorrowVault.underlying}
                decimalsIn={vault.uDecimals}
                decimalsOut={activeBorrowVault.uDecimals}
                symbolOut={activeBorrowVault.symbol}
                recipient={vault.address}
                onQuoteUpdate={(quote)=> {
                  setQuote(quote)
                }}
              />}
          </Box>
        </Flex>
        <Flex>
          <Box w='60%'>
            <Text fontWeight={700} color='primary.900'>
              {Number(amountIn) > 0 && quote ? fixed2Decimals(quote.quote, activeBorrowVault.uDecimals) : ''}
            </Text>
          </Box>
          <HStack w='40%' justifyContent='flex-end'>
            <Img h='16px' src={getTokenImage(activeBorrowVault.symbol)} />
            <Text textStyle='labelBold' color='primary.900'>{activeBorrowVault.symbol}</Text>
            {/* <Icon as={IconAngleDown} color='gray.300'/> */}
          </HStack>
        </Flex>
      </Box>
      <Box px={4}>
        <VStack mt='28px'>
          <SimpleGrid
            columns={2}
            borderBottom='1px solid'
            py="8px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-bold-3"
              color='primary.900'
              opacity='0.64'
            >
              Fees
            </Text>
            <Text textStyle="labelBold"
              color='primary.900'
              justifySelf='flex-end'
            >
              0.00
            </Text>
          </SimpleGrid>
        </VStack>
        <VStack
          spacing='20px'
          mt='20px'
          alignItems='flex-start'
          padding='20px'
          w='100%'
        >
          <Sell
            isDisabled={(
              quote === null ||
              isZero(amountIn) ||
              isGreaterThan(decimal2Fixed(amountIn, vault.uDecimals), vaultData.lendBalance)
            )}
            
            inAddress={vault.address}
            inAmount={amountIn}
            inTokens={amountToTokens(amountIn)}
            inSymbol={vault.uSymbol}
            inDecimals={vault.uDecimals}

            outAddress={activeBorrowVault.address}
            outSymbol={activeBorrowVault.symbol}
            outDecimals={activeBorrowVault.uDecimals}

            quote={quote}
          />
        </VStack>
      </Box>
    </Box>
  )
}
