import { Flex, Text,Box, Alert, AlertIcon, Button, Icon, HStack } from "@chakra-ui/react";
import { ChainSelector } from "./ChainSelector";
import { ReactNode } from "react";
import { Chain } from "viem";
import { NavLink } from "react-router-dom";
import { PiArrowRightBold } from "react-icons/pi";
import { protocolSupportedChains } from "../constants";
import { useNetwork } from "wagmi";

export function UnsupportedNetwork({
  text1, text2, note
}: {text1?: React.ReactNode, text2?: string, note?: ReactNode}) {
  return (
    <Flex justifyContent='center'>
      <Box 
        w={{base: 'full', sm: '100%', md: '50%'}}
        border='1px solid'
        borderColor='gray.150'
        borderRadius='16px'
        p='30px'
      >
        {note && <Alert status='info' borderRadius='20px'>
          <AlertIcon></AlertIcon>
          {note}
        </Alert>}
        <HStack mt='20px' mb='10px' alignItems='center'>
          <Text textStyle='subheading-1' color='primary.900' >SWITCH YOUR NETWORK</Text>
          <ChainSelector shwoNetworkName={true}/>
       </HStack>
        {text1 ? text1 : <Text textStyle='body-1' color='primary.900' >"You are connected to unsupported network"</Text>}
        <Text textStyle='body-1' color='primary.900' >{text2 ? text2 : "Please select a network WeFi supports"}</Text>
        
      </Box>
    </Flex>
  )
}

export function UnsupportedNetworkProtocolOnly() {
  const {chain} = useNetwork();
  return <UnsupportedNetwork
  note = {
    chain?.testnet ? null : 
    <Box>
      <Text textStyle='body-1' color='primary.900'>
        You are connected to <b>{chain?.name}</b> Network.<br/>This network currently only supports bridge. Use bridge to transfer assets from Base to other networks.
      </Text>
      <Button variant='link' mt={2} as={NavLink} to='bridge' size={'sm'} colorScheme='primary' rightIcon={<Icon as={PiArrowRightBold}/>}>Bridge</Button>
    </Box>
  }
  text1={<Box>
      <Text  textStyle='body-1' color='primary.900'>
        Lending & Leverage Vaults are available on:
        <ul style={{marginLeft: '3em'}}>
          <li><b>Mainnet:</b>{protocolSupportedChains.mainnet.join(', ')}</li>
          {/* <li><b>Testnet:</b>{protocolSupportedChains.testnet.join(', ')}</li> */}
        </ul>
    </Text>
  </Box>}
/>
}
