import { PredictionConfig } from '../utils/predictions/interfaces';
import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';
// import PredictionV3ABI from './abi/predictionV3.json';
import PredictionV2ABI from './abi/predictionV2.json';

export const Vaults: VaultInterface[] = [
  {
    address: "0xa176829ea5b33b816eea098b12374ce91270050f",
    decimals: 8,
    underlying: "0xd33572f6dd1bb0d99c8397c8efe640cf973eaf3b",
    uSymbol: "USDC",
    uName: "USD Coin",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0x0f1de0f8b495049684779b0f2d760b86e15e6391", "0xa9aaf4acf01227b56411b6d16532d2797fb00776"]
  },
  {
    address: "0x227dae38a74256686b015291031e342bd900c1d3",
    decimals: 8,
    underlying: "0xefad4677b620658e8875ef54d311cc614b0df053",
    uSymbol: "USDT",
    uName: "USDT",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0x0f1de0f8b495049684779b0f2d760b86e15e6391", "0xa9aaf4acf01227b56411b6d16532d2797fb00776"]
  },
  {
    address: "0x0f1de0f8b495049684779b0f2d760b86e15e6391",
    underlying: "0x64659fa9064bb434daa6e8e0b2706d01e9f9e30f",
    decimals: 8,
    uSymbol: "WMATIC",
    uName: "wMATIC",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0xa176829ea5b33b816eea098b12374ce91270050f", "0x227dae38a74256686b015291031e342bd900c1d3"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'MATICUSD'
    }
  },
  {
    address: "0xa9aaf4acf01227b56411b6d16532d2797fb00776",
    decimals: 8,
    underlying: "0xe37f602e3df0f7b2b0374edd55f54272543442f1",
    uSymbol: "WETH",
    uName: "WETH",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0xa176829ea5b33b816eea098b12374ce91270050f", "0x227dae38a74256686b015291031e342bd900c1d3"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  },
  {
    address: "0xce6bf6bf4339de90b959dd585b1a825f788a3e25",
    underlying: "0xe37f602e3df0f7b2b0374edd55f54272543442f1",
    decimals: 8,
    uSymbol: "WETH-2",
    uName: "WETH (Borrow Only)",
    uDecimals: 18,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    buyAssets: ["0xc81d5f35a1ccffc7bd306eb3ddd81a1c0da78311"]
  },
  {
    uName: "USD Coin (Buy Only)",
    uSymbol: "USDC-2",
    address: "0xc81d5f35a1ccffc7bd306eb3ddd81a1c0da78311",
    underlying: "0xd33572f6dd1bb0d99c8397c8efe640cf973eaf3b",
    uDecimals: 6,
    decimals: 8,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    isStable: true,
    borrowAssets: ["0xce6bf6bf4339de90b959dd585b1a825f788a3e25"],
    tradingWidgetConfig: {
      source: 'BITSTAMP',
      pair: 'USDCUSD'
    }
  },
  {
    uName: "WeFi Coin (Buy Only)",
    uSymbol: "WEFI",
    address: "0x0f6cfdb50314833adc50808c311d79158b74e962",
    underlying: "0xa3451cf7454978a406eb91ca5ccc03fcf68750aa",
    uDecimals: 18,
    decimals: 8,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    buyAssets: []
  },
];

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0x654eE8aaa5B1DCa7c6BCc6E4F2C057245CB3a8Ab",
  'Comptroller': "0x90Cb2a88df816F1BA96Fc2543cDBF2e64c0c69AA",
  "veWEFI": "0x6e4796054d58934EEE5a8b04f11d87086F96d13d",
  "WEFI": "0x8c2caDe1825966ceC1f3Cdb19695c526A72f55b3",
  "StakingReward": "0xD8c9E47500dcaa32CD80d85F8E734e271596EB9b",
  "StakingRewardToken": "0xeFAd4677b620658e8875Ef54d311Cc614B0Df053"
}

export const defaultTradePair  = "usdc-weth";

export const blockExplorer = 'https://mumbai.polygonscan.com';

export const blocksPerDay=37565;

export const DexQuoters : DexQuoter[] = [
  {
    id: '1',
    name: 'Uniswap V3',
    slug: 'uniswap-v3',
    type: 'Contract',
    address: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    functionName: 'quoteExactInputSingle'
  },
  {
    id: '2',
    name: 'Quickswap V2',
    slug: 'quickswap-v2',
    type: 'Contract',
    address: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    functionName: 'getAmountsOut'
  }
]

export const eventsApiChainSlug='mumbai-short';

export const faucetURL = "https://mumbaifaucet.com/";
export const faucetAddress = "0x615aF66F73dd07295d6309281f535B4c4Ac03d00";

export const predictionTokensConfig: PredictionConfig[] = [{
  token: {
    isNative: true,
    address: undefined,
    symbol: "MATIC",
    decimals: 18,
    pythPriceId: "0xd2c2c1f2bba8e0964f9589e060c2ee97f5e19057267ac3284caef3bd50bd2cb5",
  },
  contract: "0xE007E8D3C6E73d56b65Fa78A41a6421a109e9fD7",
  abi: PredictionV2ABI,
  oracle: "0x8ac6E23dDa46957Ab2dEeAA3eAd5A9fa8c884e55",
  bufferSeconds: 240,
  intervalSeconds: 300,
  minBetAmount: 0.00001,
  graphEndpoint: ""
}]