import ChainPolygon from '../images/chains/polygon.svg';
import ChainZkSync from '../images/chains/zkSync.svg';
import ChainEth from '../images/chains/ethereum.svg';
import ChainBSC from '../images/chains/bsc.svg';
import ChainARB from '../images/chains/arbitrum.svg';
import ChainXDC from '../images/chains/xdc.svg';
import ChainAvalanche from '../images/chains/avalanche.svg';
import ChainBase from "../images/chains/base.svg";
import ChainPolygonZkevm from "../images/chains/Chainpolygonzkevm.svg";
import ChainOP from '../images/chains/op.svg';
import ChainBoba from '../images/chains/boba.svg';
import TokenUSDC from '../images/tokens/usdc.svg';
import TokenUSDT from '../images/tokens/usdt.svg';
import TokenAAVE from '../images/tokens/aave.svg';
import TokenETH from '../images/tokens/eth.svg';
import TokenBTC from '../images/tokens/btc.svg';
import TokenWEFI from '../images/tokens/wefi.svg';
import TokenMATIC from '../images/tokens/matic.svg';
import TokenWXDC from "../images/tokens/wxdc.png";
import TokenXDC from "../images/tokens/xdc.svg";
import TokenFXD from "../images/tokens/fxd.png";
import TokenEURS from '../images/tokens/eurs.png';
import TokenXSP from '../images/tokens/xsp.png';
import TokenPLI from '../images/tokens/pli.png';
import TokenPRNT from '../images/tokens/prnt.png';

export const getWalletImage = (walletId: string | undefined) => {
  return walletId ? require(`../images/wallets/${walletId.toLowerCase()}.svg`) : '';
}

export const getChainIcon = (id: number | null) => {
  switch(id) {
    case 137:
      return ChainPolygon;
    case 80001:
      return ChainPolygon;
    case 280:
      return ChainZkSync;
    case 1:
      return ChainEth;
    case 1101:
      return ChainPolygon;
    case 8453:
      return 'https://icons.llamao.fi/icons/chains/rsz_base.jpg';
    case 59144:
      return 'https://icons.llamao.fi/icons/chains/rsz_linea.jpg';
    case 59140:
      return 'https://icons.llamao.fi/icons/chains/rsz_linea.jpg';
    case 42161:
      return ChainARB;
    case 56:
      return ChainBSC;
    case 50:
      return ChainXDC;
    case 43114:
      return ChainAvalanche;
    case 51:
      return ChainXDC;
    case 84531:
      return ChainBase;
    case 1442:
      return ChainPolygonZkevm;
    case 10:
      return ChainOP;
    case 288:
      return ChainBoba
    default:
      return '';
  }
}

export const getTokenImage= (symbol: string) => {
  switch(symbol.toLowerCase()) {
    case "usdt":
      return TokenUSDT;
    case "usdc":
      return TokenUSDC;
    case "usdc-2":
      return TokenUSDC;
    case "eth":
      return TokenETH;
    case "weth":
      return TokenETH;
    case "weth-2":
      return TokenETH;
    case "matic":
      return TokenMATIC;
    case "wmatic":
      return TokenMATIC;
    case "wbtc":
      return TokenBTC;
    case "aave":
      return TokenAAVE;
    case "wefi":
      return TokenWEFI;
    case "xusdt":
      return TokenUSDT;
    case "wxdc":
      return TokenWXDC;
    case "txdc":
      return TokenXDC;
    case 'fxd':
      return TokenFXD;
    case 'eurs':
      return TokenEURS;
    case 'xsp': 
      return TokenXSP;
    case 'pli':
      return TokenPLI;
    case 'prnt':
      return TokenPRNT;
    default:
      return "";
  }
}

export const getRewardTokenIcon = (chainId : number | undefined) => {
  return chainId === 50 ? TokenFXD : TokenWEFI;
}