import { Box, Center, Divider, Flex, Progress, Text, useBreakpointValue } from "@chakra-ui/react";
import PortfolioStatsBlock from "./PortfolioStatsBlock";

interface LiquidationStatsProps {
  totalValue: string;
  borrowAmount: string;
  liquidationPoint: string;
  liquidationScore: number;
  liquidationRisk: "Low" | "High" | "Moderate";
}

export default function LiquidationStats({
  totalValue,
  borrowAmount,
  liquidationPoint,
  liquidationScore,
  liquidationRisk
}: LiquidationStatsProps) {
  let isMobile = useBreakpointValue({base: true, md: false});
  return(
    <Box>
      <Flex 
        gap='24px' 
        flexDirection={useBreakpointValue({base: 'column', md: 'row'})}
        border='1px solid'
        borderColor='gray.150'
        borderRadius='16px'
        px={{base: '20px', md:'56px'}}
        py='21px'
        bgColor='white'
        alignItems="center"
      >
        <PortfolioStatsBlock
          w={{base: 'full', md:'20%', sm: '100%'}}
          title='Current Borrowing'
          value={borrowAmount}
        />
        <Center height={isMobile ? '0px': '50px'}>
          <Divider orientation={isMobile ? 'horizontal': 'vertical'} />
        </Center>
        <Box
          w={{base: 'full', md:'80%', sm: '100%'}}
        >
          <Flex mb="12px">
            <Box
              w='50%'
              display='flex'
              flexDir={{base: 'column', md: 'row'}}
            >
              <Text
                textStyle="body-2"
                color="gray.500"
              >Liquidation Risk:&nbsp;</Text>
              <Text
                textStyle="body-bold-2"
                color={liquidationRisk === "Low"? "green.500" : liquidationRisk === "High" ? "red.500" : "yellow.500"}
              >{liquidationRisk}</Text>
            </Box>
            <Box
              w='50%'
              display='flex'
              flexDir={{base: 'column', md: 'row'}}
              justifyContent='flex-end'
              alignItems='flex-end'
            >
              <Text
                textStyle="body-2"
                color="gray.500"
              >Liquidation Point:&nbsp;</Text>
              <Text
                textStyle="body-bold-2"
                color="primary.900"
              >{liquidationPoint}</Text>
            </Box>
          </Flex>
          <Progress
            value={liquidationScore}
            bgColor="gray.150"
            colorScheme={liquidationRisk === "Low"? "green" : liquidationRisk === "High" ? "red" : "yellow"}
            borderRadius="4px"
            height="8px"
          />
        </Box>
      </Flex>
      <Box py="21px" textAlign="center">
        <Text textStyle="body-2" color="gray.400">
          If your Overall Position value reaches near <Text display="inline" textStyle="body-bold-2" color="primary.900">{liquidationPoint}</Text> your positions have high chance of liquadation.
        </Text>
      </Box>
    </Box>
  )
}