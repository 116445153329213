import { Address, zeroAddress } from "viem";
import LoadingGif from '../images/loading.gif';
import { Box, Button, Icon, Img, Link, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { useAccount } from "wagmi";
import { useEffect } from "react";
import { fixed2Decimals, decimal2Fixed, shortAddress, divFixed } from "../utils/helpers";
import { FiExternalLink } from "react-icons/fi";
import { PiCheckCircleFill, PiWarningCircleBold, PiWarningOctagonDuotone } from "react-icons/pi";
import { getAbi, getBlockExplorer, getEventsAPIChainSlug } from "../constants";
import { useAtom } from "jotai";
import { atomPendingTxWrite, atomPendingHashWrite } from "../atoms";
import { MintEvent } from "../utils/events/interfaces";
import moment from "moment";

interface SupplySendProps {
  vaultAddress: Address;
  amount: string;
  symbol: string;
  decimals: number;
  exchangeRate: number | undefined;
  onCancel: ()=> void;
}

export function SupplySend({
  vaultAddress, // eventAddress
  amount,
  symbol,
  decimals,
  exchangeRate,
  onCancel
}: SupplySendProps) {
  const {chain} = useNetwork();
  const { address } = useAccount();

  const [ , setTx] = useAtom(atomPendingTxWrite);
  const [ , setHash] = useAtom(atomPendingHashWrite);

  const {
    config,
    error: prepareError,
    isError: isPrepareError
  } = usePrepareContractWrite({
    address: vaultAddress,
    abi: getAbi({chainId: chain?.id, abiFile: 'Vault'}),
    functionName: 'mint',
    args: [BigInt(decimal2Fixed(amount, decimals))],
    
  });

  const { data, error, isLoading, isError, write } = useContractWrite(config);

  useEffect(()=> {
    if (write && !data?.hash) {
      write();
    }
  }, [write]);

  useEffect(() => {
    if (data?.hash) {
      if (getEventsAPIChainSlug(chain?.id) !== "") {
        const mintAmount = decimal2Fixed(amount, decimals);
        const mintTokens = divFixed(mintAmount, fixed2Decimals(exchangeRate ? exchangeRate : "", 18));
      
        const eventData: MintEvent = {
          mintAmount: mintAmount,
          mintTokens: mintTokens,
          minter: address ? address : zeroAddress
        }
        setTx([{
          eventAddress: vaultAddress ,
          eventName: "Mint",
          transactionHash: data?.hash,
          timestamp: moment().unix().toString(),
          __typename: "",
          data: "",
          eventData: eventData
        }]);
      } else {
        setHash(data.hash);
      }
    }
  }, [data?.hash])
  

  const {data: txData, isLoading: isTxLoading, isSuccess}  = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <Box textAlign='center'>
      {(isLoading || isTxLoading) && <Img
        display='inline-block'
        height='96px'
        width='96px'
        src={LoadingGif} 
      />}
      
      {( isPrepareError || isError) && <VStack spacing='20px' mt='20px'>
        <Icon as={PiWarningOctagonDuotone} fontSize={'5xl'} color='orange.300'/>
        <Box>
          <Text color='primary.900' textStyle='body-bold-1'>
            Failed to initiate transaction
          </Text>
          <Text wordBreak='break-word' color='orange.400' textStyle='body-bold-3'>
            { (prepareError || error)?.name }
          </Text>
        </Box>
        <Button onClick={()=> onCancel()}>Close</Button>
      </VStack>}

      {isLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting Txn to initiate.
        </Text>
        <Text textStyle="body-bold-3" color="gray.500">
          Please go to your wallet and <br/> allow this transaction to continue.
        </Text>
      </Box>}

      {isTxLoading && <Box>
        <Text textStyle='body-bold-1' color='primary.900'>
          Awaiting transaction to complete.
        </Text>
        <Link
          textStyle="body-bold-3"
          color="gray.500"
          isExternal
          href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}
        >
          View in Explorer {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
        </Link>
      </Box>}

      {isSuccess && <VStack spacing="32px" mt='20px'>
        <Box>
          <Text textStyle='body-bold-1' color='primary.900'>{amount} {symbol}</Text>
          {txData?.status === 'success' ? <Text textStyle='body-bold-3' color='green.500'>
            Supplied Successfully <Icon as={PiCheckCircleFill}/>
          </Text> : <Text textStyle='body-bold-3' color='red.500'>
            Txn Reverted <Icon as={PiWarningCircleBold}/>
          </Text>}
        </Box>
        <Box w='100%'>
          <SimpleGrid
            columns={2} spacing='20px'
            borderBottom='1px solid'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Pool Name
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
            {symbol} Pool
            </Text>
          </SimpleGrid>
          <SimpleGrid
            columns={2} spacing='20px'
            borderBottom='1px solid'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Fees Paid
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              0.00
            </Text>
          </SimpleGrid>
          <SimpleGrid
            columns={2} spacing='20px'
            py="12px"
            borderColor='gray.150'
            w="100%"
          >
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              textAlign='left'
            >
              Txn Hash
            </Text>
            <Text
              textStyle="body-3"
              color='primary.900'
              opacity='0.6399999856948853'
              justifySelf='flex-end'
            >
              <Link isExternal href={`${getBlockExplorer(chain?.id)}/tx/${data?.hash}`}>
                {shortAddress(data?.hash)} <Icon as={FiExternalLink}/>
              </Link>
            </Text>
          </SimpleGrid>
        </Box>
        <Box w='100%'>
          {txData?.status === 'success' ? <Button
            size="lg"
            w="100%"
            colorScheme='primary'
            mb="16px"
            onClick={()=> onCancel()}
          >Done</Button> :
          <Button
            size="lg"
            w="100%"
            onClick={()=> onCancel()}
          >
            Close
          </Button>}
        </Box>
      </VStack>}
    </Box>
  )
}