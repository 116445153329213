import { Box, Button, Center, Icon, Img, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalContent, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, SimpleGrid, Text, Tooltip, VStack } from "@chakra-ui/react";
import { getTokenImage } from "../../utils/image";
import { useState } from "react";
import { erc20ABI, useAccount, useBalance, useContractRead } from "wagmi";
import { decimal2Fixed, fixed2Decimals, getFutureRoundedEpoch, isGreaterThan, isGreaterThanOrEqualTo, isZero } from "../../utils/helpers";
import moment from "moment";
import { Address, zeroAddress } from "viem";
import { ApproveSpend } from "../ApproveSpend";
import { StakeSend } from "./StakeSend";
import { LockedData } from "./Tabs";
import { FiHelpCircle } from "react-icons/fi";

const MAX_TIME = 94608000;
const MIN_DURATION = 1;

export function Stake({
  tokenAddress,
  veTokenAddress,
  lockedData
} : {
  tokenAddress: Address,
  veTokenAddress: Address,
  lockedData: LockedData
}) {
  const [activeStep, setActiveStep] = useState<null | 'approval' | 'submit'>(null);
  const [amount, setAmount] = useState("0");
  const [duration, setDuration] = useState(1);
  const {address} = useAccount();

  const maxDuration = lockedData.amount > 0 ? 
    36 - Math.ceil(moment.unix(lockedData.unlockTime).diff(moment(), 'months', true)) 
    : 36; 
  const {data : wefiBalance} = useBalance({
    address,
    token: tokenAddress,
    watch: true
  })

  const { data : amountApproved} = useContractRead({
    abi: erc20ABI,
    address: tokenAddress,
    functionName: 'allowance',
    args: [address || zeroAddress, veTokenAddress || zeroAddress],
    watch: true
  });

  const hasApproval = isGreaterThanOrEqualTo(
    amountApproved? amountApproved.toString(): 0,
    decimal2Fixed(amount, 18)
  );

  const getProjection = () => {
    const data = {
      lockedAmount: 0,
      votingPower: "0",
      timeLeft: "---",
      unlockTime: "---",
      method: "create_lock",
      btnText: "Stake"
    }
    let amt = Number(amount) + lockedData.amount;
    if(amt > 0 && duration <= maxDuration) {
      let weeks = moment().add(duration, "months").diff(moment(), "weeks");
      if(lockedData.amount > 0) {
        weeks = weeks + moment.unix(lockedData.unlockTime).diff(moment(), "weeks");
      }
      const unlockEpoch = getFutureRoundedEpoch(lockedData.unlockTime, duration);
      data.lockedAmount = amt;
      data.votingPower = ( 
        // Number(fixed2Decimals(veBalance, 18)) + 
        (amt * (weeks * 7 * 24 * 3600 /MAX_TIME)) 
      ).toFixed(2)
      data.timeLeft = `${Math.round((unlockEpoch - (Date.now()/1000)) / (24 * 3600))} days`;
      data.unlockTime = moment.unix(unlockEpoch).format("ll")
    }
    if(lockedData.amount > 0) {
      if(
          (!isZero(amount) && duration !== 0) ||
          (isZero(amount) && duration ===0)
        ) {
        data.method = 'increase_amount_and_time';
        data.btnText= 'Increase Amount & Stake Duration';
      } else if(duration !== 0) {
        data.method = 'increase_unlock_time';
        data.btnText= 'Increase Stake Duration';
      } else if(!isZero(amount)) {
        data.method = 'increase_amount';
        data.btnText = 'Increase Amount';
      }
    }
    return data;
  }
  const projectionData = getProjection();
  return  <Box>
    <Text textStyle='cta-2' color='primary.900'>
      {lockedData.amount > 0 ? "Increase Amount & Stake Duration" : "Stake WEFI"}
    </Text>
    <InputGroup mt='14px' w='100%' size='lg'>
      <InputLeftElement>
        <Img h='24px' w='24px' src={getTokenImage("wefi")} />
      </InputLeftElement>
      <Input
        variant='filled'
        type='number'
        value={amount}
        onChange={(e)=> setAmount(e.target.value)}
      />
      <InputRightElement>
        <Button
          w='50px'
          right='6px'
          variant='ghost'
          colorScheme='primary'
          onClick={()=> {
            if(wefiBalance) {
              setAmount(fixed2Decimals(wefiBalance?.value.toString(), 18));
            }
          }} 
        >
          MAX
        </Button>
      </InputRightElement>
    </InputGroup>
    <Text
      textStyle='body-bold-3'
      textAlign='right'
      color='primary.900'
      opacity='0.64'
      mt='14px'
    >Bal. {wefiBalance && fixed2Decimals(wefiBalance?.value.toString(), 18, 4)}</Text>
    <Text mt='14px' textStyle='body-2' color='gray.400'>Stake Duration ({MIN_DURATION} to {maxDuration} months)</Text>
    <NumberInput
      value={duration}
      onChange={(value) => {
        let d = parseInt(value);
        if(isNaN(d)) {
          d= 0;
        } else if(d > maxDuration) {
          d = maxDuration;
        }
        setDuration(d);
      }}
      min={0}
      max={maxDuration}
      step={1}
      variant='filled'
      size='lg'
      mt='5px'
      inputMode='numeric'
    >
      <NumberInputField/>
      <NumberInputStepper border='none' right='10px'>
        <NumberIncrementStepper border='none' color='gray.500' _hover={{color:'primary.500'}} />
        <NumberDecrementStepper border='none' color='gray.500'  _hover={{color:'primary.500'}}/>
      </NumberInputStepper>
    </NumberInput>
    <Box w='100%' mt={'14px'}>
      <SimpleGrid
        position='relative'
        columns={2}
        borderBottom='1px solid'
        py="12px"
        borderColor='gray.150'
        w="100%"
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          Reward APR
          <Tooltip placement="top" hasArrow label='Collect staking reward in WEFI tokens.' bg='gray.150' color='primary.900'>
            <span><Icon as={FiHelpCircle}/></span>
          </Tooltip>
        </Text>
        <Text textStyle="labelBold"
          color='primary.900'
          justifySelf='flex-end'
        >
          20%
        </Text>
      </SimpleGrid>
      <SimpleGrid
        columns={2}
        borderBottom='1px solid'
        py="12px"
        borderColor='gray.150'
        w="100%"
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          Locked Amount
        </Text>
        <Text textStyle="labelBold"
          color='primary.900'
          justifySelf='flex-end'
        >
          {projectionData.lockedAmount} WEFI
        </Text>
      </SimpleGrid>
      <SimpleGrid
        columns={2}
        borderBottom='1px solid'
        py="12px"
        borderColor='gray.150'
        w="100%"
        bg='#F4F5FB'
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          Voting Power
        </Text>
        <Text textStyle="labelBold"
          color='primary.900'
          justifySelf='flex-end'
        >
          {projectionData.votingPower} veWEFI
        </Text>
      </SimpleGrid>
      <SimpleGrid
        columns={2}
        borderBottom='1px solid'
        py="12px"
        borderColor='gray.150'
        w="100%"
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          Time Left
        </Text>
        <Text textStyle="labelBold"
          color='primary.900'
          justifySelf='flex-end'
        >
          {projectionData.timeLeft}
        </Text>
      </SimpleGrid>
      <SimpleGrid
        columns={2}
        py="12px"
        w="100%"
      >
        <Text
          textStyle="body-bold-3"
          color='primary.900'
          opacity='0.64'
        >
          Lock Until
        </Text>
        <Text textStyle="labelBold"
          color='primary.900'
          justifySelf='flex-end'
        >
          {projectionData.unlockTime}
        </Text>
      </SimpleGrid>
      <Button
        isDisabled={(
          (lockedData.amount > 0 ? 
            (Number(amount) === 0 && duration === 0) :
            (Number(amount) <=0 || duration ===0 ) 
          ) || duration >36 ||
          (wefiBalance && isGreaterThan(decimal2Fixed(amount, 18), wefiBalance.value.toString()))
        )}
        onClick={()=> {
          if(hasApproval) {
            setActiveStep("submit");
          } else {
            setActiveStep("approval");
          }
        }}
        w='full'
        size='lg'
        mt='14px'
        colorScheme="primary"
      >
        {hasApproval ? projectionData.btnText : 'Allow spend of WEFI'}
      </Button>
    </Box>
    <Modal isOpen={activeStep !== null} onClose={() => setActiveStep(null)} isCentered size='sm'>
      <ModalOverlay/>
      <ModalContent px='20px' pb="28px">
        <Center>
          <Img
            src={getTokenImage("wefi")}
            width='80px'
            height='80px'
            border='6px solid'
            borderColor='white'
            backgroundColor="white"
            borderRadius="100%"
            mt='-40px'
          />
        </Center>
        {activeStep === 'approval' && <ApproveSpend
          amount={amount}
          tokenAddress={tokenAddress}
          spender={veTokenAddress || zeroAddress}
          symbol={"wefi"}
          decimals={18}
          onCancel={()=> setActiveStep(null)}
          onProceed={()=> setActiveStep('submit')}
          ctaText={`Stake WEFI`}
        />}
        {activeStep === 'submit' && <StakeSend
          method = {projectionData.method}
          btnText= {projectionData.btnText}
          startTime={lockedData.amount > 0 ? lockedData.unlockTime: 0}
          veTokenAddress={veTokenAddress || zeroAddress}
          amount={amount}
          duration={duration}
          symbol='wefi'
          decimals={18}
          onCancel={()=> setActiveStep(null)}
        />}
      </ModalContent>
    </Modal>
  </Box>
}