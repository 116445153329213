import { Box, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import OpenLoanPositionsStats from "./OpenLoanPositionsStats";
import { LoanPositions } from "../utils/loanPositions";
import { getTokenImage } from "../utils/image";
import { fixed2Decimals } from "../utils/helpers";

export default function OpenLoanPositions({
  data
}: {data: LoanPositions}) {
  return (
    <Box>
      <Text textStyle='heading-3' color='primary.900' mb='32px'>
        OPEN LOAN POSITIONS
      </Text>
      <OpenLoanPositionsStats data={data}/>
      <TableContainer mt='32px'>
        <Table> 
          <Thead textStyle='body-2'>
            <Tr>
              <Th>Vault</Th>
              <Th>Borrowed</Th>
              <Th>Repaid</Th>
              <Th>Interest Payable</Th>
              <Th>Outstanding</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.open.map((row)=> {
              return (
                <Tr>
                  <Td>
                    <HStack>
                      <Image h='24px' w='24px' src={getTokenImage(row.uSymbol)} />
                      <Text>{row.uSymbol} Vault</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {row.borrowed? `${fixed2Decimals(row.borrowed.amount, row.uDecimals, 2)} ${row.uSymbol}`: '---'}
                  </Td>
                  <Td>
                    {row.repaid? `${fixed2Decimals(row.repaid.amount, row.uDecimals, 2)} ${row.uSymbol}`: '---'}
                  </Td>
                  <Td>
                    {row.interest? `${fixed2Decimals(row.interest.amount, row.uDecimals, 2)} ${row.uSymbol}`: '---'}
                  </Td>
                  <Td>
                    {`${fixed2Decimals(row.outstanding.amount, row.uDecimals, 2)} ${row.uSymbol}`}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}