import { useColorModeValue } from "@chakra-ui/react";

export const layerStyles = {
  'navItemBase': {
    padding: '12px 15px',
    color: 'primary.800',
    opacity: '0.6399999856948853'
  }, 
  'navItemSelected': {
    padding: '12px 15px',
    color: 'primary.500',
    border: '2px solid',
    borderColor: '#9EAEF3',
    borderRadius: '16px',
    backgroundColor: 'white'
  }
}