import { Box, Button, Modal, ModalContent, ModalOverlay} from "@chakra-ui/react";
import { useState } from "react";
import { Address } from "viem";
import { Quote } from "../hooks/useDexQuotesRead";
import { SellPreview } from "./SellPreview";
import { SellSend } from "./SellSend";

export interface SellProps {
  isDisabled: boolean;

  inAddress: Address,
  inAmount: string;
  inTokens: string;
  inSymbol: string;
  inDecimals: number;

  outAddress: Address,
  outSymbol: string;
  outDecimals: number;
  
  quote: Quote | null
}

type TxStep = 'input' | 'preview' | 'submit';

export interface SellPreviewData {
  assetTokensAmount: number,
  borrowBalance: number,
  err: number,
  estimatedLiquidity: number,
  repayAmountRequired: string,
  totalReceivable: string
}

export function Sell(props: SellProps) {
  const [activeStep, setActiveStep] = useState<TxStep>("input");
  const [sellPreviewData, setSellPreviewData] = useState<SellPreviewData>()

  return (
    <Box w='100%'>
      <Button
        isDisabled={props.isDisabled}
        w='full'
        size='lg'
        colorScheme='primary'
        onClick={()=> {
          setActiveStep('preview');
        }}
      >
        Sell Position
      </Button>
      <Modal
        isOpen={(activeStep === 'preview' || activeStep==='submit')}
        onClose={()=> setActiveStep('input')}
        isCentered
        size='sm'>
        <ModalOverlay/>
          <ModalContent px='20px' pb="28px">
            {activeStep === 'preview' && <SellPreview
              onEdit={()=> setActiveStep('input')}
              onSubmit={()=> setActiveStep('submit')}
              handlePreviewData={(data) => {
                setSellPreviewData(data)
              }}
              data={props}
            />}
            {activeStep === 'submit' && <SellSend
              vaultAddress={props.inAddress}
              borrowVaultAddress={props.outAddress}
              inSymbol={props.inSymbol}
              inAmount={props.inAmount}
              inTokens={props.inTokens}
              inDecimals={props.inDecimals}
              sellPreviewData={sellPreviewData}
              outAmount={props.quote? props.quote.quote: "0"}
              fee={props.quote?.fee ? props.quote.fee: '3000'}
              dexId={props.quote? props.quote.dexId : ''}
              quoteJson={props.quote && props.quote.quoteJSON ? props.quote.quoteJSON : null}
              onCancel={()=> setActiveStep('input')}
            />}
          </ModalContent>
      </Modal>
    </Box>
  )
}
