import { PredictionConfig } from "../utils/predictions/interfaces";
import PredictionABI from '../constants/abi/wefiPredictionV3.json';
import { ContractAddressMap } from "./interfaces";

export const blockExplorer = 'https://polygonscan.com';

export const ContractAddresses: ContractAddressMap = {
  "veWEFI": "0xC1704c99278c3e5A91AfB117301eA61B003Aa650",
  "WEFI": "0xfFA188493C15DfAf2C206c97D8633377847b6a52"
}

export const predictionTokensConfig: PredictionConfig[] = [{
  token: {
    isNative: false,
    address: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    symbol: "WBTC",
    decimals: 8,
    pythPriceId: "0xc9d8b075a5c69303365ae23633d4e085199bf5c520a3b90fed1322a0342ffc33",
  },
  contract: "0xC7f2720aaFC991aBbAD66651E28a40711262478B",
  abi: PredictionABI,
  oracle: "0xff1a0f4744e8582DF1aE09D5611b887B6a12925C",
  bufferSeconds: 180,
  intervalSeconds: 300,
  minBetAmount: 0.00001,
  graphEndpoint: ""
}]