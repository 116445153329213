import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { fonts } from '../fonts'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    fontFamily: fonts.body,
    fontWeight: 700,
    color: 'primary.900',
  },
})

const variants = {
  filled: definePartsStyle( {
    field: {borderRadius: '12px'}
  })
}

export const inputStyles = defineMultiStyleConfig({ baseStyle, variants });