import { useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Button, Text, TableContainer, Flex } from '@chakra-ui/react';
import moment from 'moment';
import { formatVolume } from '../../utils/helpers';
import useLeaderboard from '../../hooks/useLeaderboard';

interface User {
  address: string;
  volume: number;
  epochId: number;
  rank: number;
  active?: boolean;
}

interface Data {
  epoch: {
    id: number;
    startTime: number;
    endTime: number;
    isActive: number;
    updatedAt: number;
  } | null;
  users: User[];
  lastEvaluatedKey: User | null;
}

export function Leaderboard() {
  const {data, isLoading, isError}: {data: Data | null, isLoading: boolean, isError: boolean} = useLeaderboard(undefined);
  // const loadMoreData = () => {
  //   const newData = [
  //     { id: '0x1111111111111111111111111111111111111111', volume: 350.123456, epochId: 1, updatedAt: 1721392529 },
  //     { id: '0x2222222222222222222222222222222222222222', volume: 340.654321, epochId: 1, updatedAt: 1721392529  },
  //   ];
  //   // setData((prevData) => [...prevData, ...newData]);
  // };

  if(data===null) {
    if(isLoading) {
      return <Spinner 
        speed='1s'
        emptyColor='primary.100'
        color='white'
        size='sm'
        me='10px'
      />
    }
    if (isError) {
      return <Text>Error! Could not load leaderboard data.</Text>
    }
    return <h3>Please wait.</h3>
  }
  if(data.epoch === null) {
    return <h3>There is no active epoch</h3>
  }

  return (
    <Box>
      <Flex alignItems='flex-end'>
        <Box w='50%'>
          <Text textStyle='heading-2' color='primary.900' >Trading Leaderboard</Text>
          <Text textStyle='body-bold-1'>
            {moment.unix(data.epoch.startTime).utc().format("ll hh:mm A")} to {moment.unix(data.epoch.endTime).utc().format("ll hh:mm A")}
            &nbsp; UTC
          </Text>
        </Box>
        <Box w='50%' textAlign='right'>
          <Text textStyle='labelBold'>Last Updated At</Text>
          <Text textStyle='label'>{moment.unix(data.epoch.updatedAt).utc().format("ll hh:mm A")} UTC</Text>
        </Box>
      </Flex>
      {isLoading && <Spinner mt={5} 
        speed='1s'
        emptyColor='primary.100'
        color='white'
        size='sm'
        me='10px'
      />}
      <TableContainer mt={5} border='1px solid' borderColor='gray.150' borderRadius='16px'>
        <Table size='md' variant="striped">
            <Thead>
              <Tr>
                <Th isNumeric></Th>
                <Th>Address</Th>
                <Th isNumeric>Total Trade Volume</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.users.map((user, i) => {
                return <Tr fontWeight={user.active ? 'bold' : 'normal'} key={user.address}>
                  <Td>#{user.rank}</Td>
                  <Td>{user.address}&nbsp;{user.active && '(You)'}</Td>
                  <Td isNumeric>{formatVolume(user.volume)}</Td>
                </Tr>
              })}
            </Tbody>
        </Table>
      </TableContainer>
      {isLoading && <Spinner mt={4} />}
      {/* {!isLoading && (
        <Box textAlign='center'>
          <Button size='sm' variant='outline' onClick={loadMoreData} mt={5} colorScheme="primary">
            Show More
          </Button>
        </Box>
      )} */}
    </Box>
  );
}