import { HStack, Image, ImageProps } from '@chakra-ui/react';

interface StackedImagesProps extends ImageProps {
  images: string[],
  leftUnits: string
}

export default function StackedImages({images, leftUnits, ...rest}: StackedImagesProps) {
  return (
    <HStack spacing="0px">
      {images.map((image, i)=> <Image 
        key={i}
        src={image}
        _notFirst={{marginLeft: leftUnits}}
        {...rest}
      />)}
    </HStack>
  )
}