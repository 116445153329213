import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0x54ecc8832b65c2db0c53235cde94991c5cdcabf9",
    decimals: 8,
    underlying: "0x66a2a913e447d6b4bf33efbec43aaef87890fbbc",
    uSymbol: "USDC",
    uName: "USD Coin",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e"]
  },
  {
    address: "0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e",
    decimals: 8,
    underlying: "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000",
    uSymbol: "WETH",
    uName: "Wrapped Ether",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x54ecc8832b65c2db0c53235cde94991c5cdcabf9"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  },
];

export const defaultTradePair = "usdc-weth"; 

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0x2588c9214376dDb524DCfa72859B522E745283Bf",
  'Comptroller': "0x301C76e7b60e9824E32991B8F29e1c4a03B4F65b"
}

export const blockExplorer = 'https://eth.bobascan.com';

export const blocksPerDay= 43200;

export const DexQuoters : DexQuoter[] = [
  // {
  //   id: '3',
  //   name: 'KyberSwap Aggregator',
  //   slug: 'kyberswap-aggregator',
  //   type: 'API',
  //   quoteApiUrl: "https://aggregator-api.kyberswap.com/{{chainSlug}}/api/v1/routes?tokenIn={{tokenIn}}&tokenOut={{tokenOut}}&amountIn={{amountIn}}",
  //   buildQuoteApiUrl: "https://aggregator-api.kyberswap.com/{{chainSlug}}/api/v1/route/build"
  // }
  {
    id: '6',
    name: 'Uniswap V3',
    slug: 'uniswap-v3-oku',
    type: 'Contract',
    address: "0x483fc90DDC7aC847D4a752055DCF73483B6d97FD",
    functionName: 'quoteExactInputSingle'
  },
  // {
  //   id: '2',
  //   name: 'OolongSwap',
  //   slug: 'quickswap-v2',
  //   type: 'Contract',
  //   address: "0x17C83E2B96ACfb5190d63F5E46d93c107eC0b514",
  //   functionName: 'getAmountsOut'
  // }
]

export const eventsApiChainSlug='boba';
export const kyberChainSlug="boba";
export const oraclePriceDecimals='8';