import {
  Flex, 
  FlexProps,
  IconButton,
  HStack,
  Image,
  Box,
  Badge,
} from '@chakra-ui/react';
import {
  FiMenu,
} from 'react-icons/fi';

import { ChainSelector } from './ChainSelector';

import Logo from '../images/logo.svg';
import Account from './Account';
import { useAccount, useNetwork } from 'wagmi';
import { WeFiPrice } from './WeFiPrice';

interface MobileProps extends FlexProps {
  collapsed: boolean;
  onOpen?: () => void;
}

export default function Header({ onOpen, collapsed, ...rest }: MobileProps) {
  const {chain} = useNetwork();
  return (
    <Flex
      transition='1s ease'
      ml={{ base: 0, md: collapsed ? 20 : 60 }}
      px={{ base: 5, md: collapsed ? 5 :128 }}
      height='20'
      alignItems='center'
      bg='white'
      borderTopLeftRadius='16px'
      justifyContent={{base: 'flex-start', md: 'flex-end'}}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<FiMenu />}
        me='1'
        size='sm'
      />
      {/* <Box display={{ base: 'flex', md: 'none' }}>
        <Image 
          maxWidth='auto'
          me='3'
          src={Logo}
          height='35px'
        />
      </Box> */}
      <HStack spacing={{ base: '3', md: '6' }}>
        {/* <LanguageSelector/> */}
        <WeFiPrice/>
        {(chain?.testnet || chain?.id === 51) && <Badge px='12px' py='3px' colorScheme='red'>Testnet</Badge>}
        <ChainSelector/>
        <Account/>
      </HStack>
    </Flex>
  );
};