import { Box, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, Link, Icon } from "@chakra-ui/react";
import { FiExternalLink } from "react-icons/fi";
import { ContractTx } from "../utils/events/interfaces";
import { getTokenImage } from "../utils/image";
import moment from "moment";
import { getBlockExplorer } from "../constants";

export default function TransactionHistory({
  txns,
  chainId,
  isCompact
}: {txns: ContractTx[], chainId: number | undefined, isCompact?: boolean}) {
  let expolrer = getBlockExplorer(chainId);
  return (
    <Box>
      {!isCompact && <Text textStyle='heading-3' color='primary.900'>
        TRANSACTION HISTORY
      </Text>}
      <TableContainer>
        <Table variant={isCompact ? 'striped' : 'simple'}>
          <Thead textStyle={isCompact ? 'body-3' : 'body-2'}>
            <Tr>
              <Th>Vault</Th>
              <Th>Txn Type</Th>
              <Th>Value</Th>
              <Th>Txn Date</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody textStyle={isCompact ? 'body-3' : 'body-2'}>
            {txns.map((tx, i)=> {
              return (
                <Tr color={isCompact ? 'primary.900' : 'inherit'}>
                  <Td>
                    <HStack>
                      {tx.symbol && <Image h={isCompact ? '16px' : '24px'} w={ isCompact ? '16px':'24px'} src={getTokenImage(tx.symbol)} />}
                      <Text>{tx.symbol}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {tx.txnType}
                  </Td>
                  <Td>
                    {tx.amount} {tx.symbol}
                  </Td>
                  <Td>
                    {moment.unix(tx.timestamp).format("ll")}
                  </Td>
                  <Td>
                      <Link textStyle="body-2" color="primary.500" isExternal href={`${expolrer}/tx/${tx.txHash}`}>
                        View on explorer <Icon as={FiExternalLink}/>
                      </Link>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}