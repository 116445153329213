import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  UseModalProps,
  HStack,
  Link,
  SimpleGrid,
  Button,
  Img,
  Box,
  Text
} from '@chakra-ui/react';
import { useAccount, useDisconnect } from 'wagmi';
import ImageLogout from '../images/icons/logout.svg';
import AvatarImage from '../images/icons/avatar.svg';
import { shortAddress } from '../utils/helpers';
import { WalletTokenBalances } from './WalletTokenBalances';

export default function AccountDrawer({
  isOpen,
  onClose
}: UseModalProps) {
  const {address, connector} = useAccount();
  const {disconnect}  = useDisconnect();

  return <Drawer
    isOpen={isOpen}
    placement='right'
    onClose={onClose}
  >
    <DrawerOverlay />
    <DrawerContent
      bgColor='#F4F5FB'
      borderRadius='16px'
      mr='12px'
      my='12px'
      width='340px'
      maxW='340px'
    >
      {/* <DrawerCloseButton color='gray.500' /> */}
      <DrawerBody p={0}>
        <SimpleGrid
          columns={[1, null, 2]}
          px='32px'
          py='24px'
          alignItems='flex-end'
          bgColor='white'
          borderRadius='16px'
          borderBottom='1px solid'
          borderColor='gray.150'
        >
          <HStack>
            <Img src={AvatarImage} w='32px' h='32px'/>
            <Box>
              <Text textStyle='cta-2'>{shortAddress(address)}</Text>
              <Text textStyle='body-3' color='gray.500'>{connector?.name}</Text>
            </Box>
          </HStack>
          <Box textAlign='right'>
            <Button
              variant='solid'
              bg='#FFEBEB'
              color='#D63333'
              textStyle='badge'
              size='sm'
              onClick={()=> disconnect()}
            >
              <Img src={ImageLogout} w='12px' h='12px' mr={2}/>
              <Text textStyle='badge'>Disconnect</Text>
            </Button>
          </Box>
        </SimpleGrid>
        <WalletTokenBalances/>
      </DrawerBody>
      <DrawerFooter
        justifyContent='flex-start'
      >
        <HStack
          spacing='32px'
          textStyle='body-3'
          opacity='0.6399999856948853;'
          color='primary.900'
        >
          <Link isExternal href='https://discord.com/invite/B6U6kectbV'>Support</Link>
          <Link isExternal href='https://www.wefi.xyz/terms-and-conditions' textAlign='center'>Terms of Service</Link>
          <Link isExternal href='https://discord.com/invite/B6U6kectbV'>Suggestions</Link>
        </HStack>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
}


