import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e",
    decimals: 8,
    underlying: "0xa8ce8aee21bc2a48a5ef670afcc9274c7bbbc035",
    uSymbol: "USDC",
    uName: "USD Coin",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0x78718e0cada1ca39fa23fc710e4accfd0ff9a5e9"]
  },
 
  {
    address: "0x78718e0cada1ca39fa23fc710e4accfd0ff9a5e9",
    decimals: 8,
    underlying: "0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9",
    uSymbol: "WETH",
    uName: "Wrapped Ether",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  }
];

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0x2588c9214376dDb524DCfa72859B522E745283Bf",
  'Comptroller': "0x0aB6Df42C2D8957F2f138606CCe4a8bA693588B7"
}

export const defaultTradePair  = "usdc-weth";


export const blockExplorer = 'https://zkevm.polygonscan.com';

export const blocksPerDay=43200;

export const DexQuoters : DexQuoter[] = [
  {
    id: '4',
    name: 'QuickSwap V3',
    slug: 'quickswap-v3',
    type: 'Contract',
    address: "0x55BeE1bD3Eb9986f6d2d963278de09eE92a3eF1D",
    functionName: 'quoteExactInputSingle'
  }
]

export const eventsApiChainSlug='polygon-zkevm';
export const oraclePriceDecimals='18';
