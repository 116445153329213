import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';

export const Vaults: VaultInterface[] = [
  {
    address: "0x4a92889c2ba7c10a1407efc619343123d425591b",
    decimals: 8,
    underlying: "0x13955758a6f6e8be65246f3600161380728e9d1a",
    uSymbol: "USDC",
    uName: "USDC",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0x4f390d552af461d12f151677a44351eb93231af0"]
  },
 
  {
    address: "0x4f390d552af461d12f151677a44351eb93231af0",
    decimals: 8,
    underlying: "0x1d4cf861932778fbf52b7696e44b4f724823e9d6",
    uSymbol: "WETH",
    uName: "WETH",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x4a92889c2ba7c10a1407efc619343123d425591b"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  }
];

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0x4239f2CCA78794273d38b5Dc825b3fc02d002B37",
  'Comptroller': "0x965778Be7235913147FaD901e7b69924A8FeaE29"
}

export const defaultTradePair  = "usdc-weth";

export const blockExplorer = 'https://goerli.lineascan.build/';

export const blocksPerDay=5760;

export const DexQuoters : DexQuoter[] = [
  // {
  //   id: '1',
  //   name: 'Uniswap V3',
  //   slug: 'uniswap-v3',
  //   type: 'Contract',
  //   address: "0xedf539058e28E5937dAef3f69cEd0b25fbE66Ae9",
  //   functionName: 'quoteExactInputSingle'
  // }
  {
    id: '2',
    name: 'UniSwap V2',
    slug: 'quickswap-v2',
    type: 'Contract',
    address: "0xB17272A5B0d976DD6627126a609f5E7726EaCe15",
    functionName: 'getAmountsOut'
  }
]

export const eventsApiChainSlug='linea-testnet';

export const faucetURL = "https://www.infura.io/faucet/linea";
export const faucetAddress = "0x953dBaBe407E2093A27a9ebafd1eFD5B2675C37b";