// import { Box, Button, Flex, HStack, Icon, Image, Img, Link, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
// import { LINEA_CHAIN_ID, getBlocksPerDay, getVaults } from "../constants";
// import ImageBanner  from '../images/graphics/voyageBanner.png';
// import ImageBannerMobile  from '../images/graphics/voyageBannerMobile.png';
// import { FiChevronRight } from "react-icons/fi";
// import { useLocation, useNavigate } from "react-router-dom";
// import { getLendingVaults } from "../utils/lending";
// import { useAtom } from "jotai";
// import { atomVaultsSnapshot, atomWeFiPrice } from "../atoms";

export function VoyageBanner({chainId}: {chainId: number|undefined}) {
  return null;
  // let isMobile = useBreakpointValue({base: true, md: false});
  // const {pathname} = useLocation();
  // const  navigate = useNavigate();
  // const [vaultsSnapshot] = useAtom(atomVaultsSnapshot);
  // const [wefiPrice] = useAtom(atomWeFiPrice);

  // const handleLendClick = () => {
  //   window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
  //   if(pathname !== "/") {
  //     navigate("/");
  //   }
  // }

  // const getAPR = () => {
  //   const vaults = getVaults(chainId).filter((vault)=> vault.uSymbol === "WEFI");
  //   if (vaults.length > 0) {
  //     const lendingVaults = getLendingVaults(vaults, vaultsSnapshot, getBlocksPerDay(chainId), wefiPrice);
  //     return `${lendingVaults[0].rewardsApr?.toFixed(0)}%`
  //   } else {
  //     return  "--";
  //   }
  // }
  // if(chainId === LINEA_CHAIN_ID) {
  //   const apr = getAPR();
  //   return <Flex w='100%' direction={{sm: 'column', base: 'column', md: 'row'}} gap='10px'>
  //     <Box w={{sm: '100%', base: '100%', md: '80%'}}>
  //       <Link isExternal href="https://youtu.be/RgEtZTw4AuU" target="_blank">
  //         <Image src={isMobile ? ImageBannerMobile : ImageBanner}/>
  //       </Link>
  //     </Box>
  //     <Box
  //       bgColor="#F4F5FB"
  //       borderRadius='10px'
  //       py={2}
  //       w={{sm: '100%', base: '100%', md: '20%'}}
  //       mb={1}
  //       cursor='pointer'
  //     >
  //       <VStack spacing={0}>
  //         <HStack alignItems='center'>
  //           <Text textStyle='heading-2' color='green.500'>{apr}</Text>
  //           <Text textStyle='body-3' color='gray.500' fontWeight='bold'>Rewards APR</Text>
  //         </HStack>
  //         <Button
  //           onClick={()=> handleLendClick()}
  //           rightIcon={<Icon as={FiChevronRight}/>}
  //           mt={1}
  //           size='sm'
  //           variant='outline'
  //           colorScheme='primary'
  //         >Lend WEFI Tokens</Button>
  //       </VStack>
  //     </Box>
  //   </Flex>
  // } else {
  //   return null;
  // }
}
