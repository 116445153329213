import {
  Box,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  HStack,
  Button,
  Icon
} from '@chakra-ui/react';
import { IconInfo } from './CustomIcons';
import { VaultsSnapshot, atomUserEvents } from '../atoms';
import { ActiveLendingVault, getActiveLendingVaults } from '../utils/lending';
import { getTokenImage } from '../utils/image';
import { VaultInterface } from '../constants/interfaces';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { ModalWithdraw } from './ModalWithdraw';
import { fixed2Decimals } from '../utils/helpers';
import AddToken from './AddToken';

export default function LendingPositions ({
  vaults,
  vaultsSnapshotData,
  blocksPerDay
}: {
  vaults: VaultInterface[];
  vaultsSnapshotData: VaultsSnapshot | null;
  blocksPerDay: number
}) {
  const [activeVault, setActiveVault] = useState<null | ActiveLendingVault>(null);
  const [events] = useAtom(atomUserEvents);
  const activeVaults = getActiveLendingVaults(vaults, vaultsSnapshotData, blocksPerDay, events);
	return (
		<Box>
			<Text textStyle='heading-3' color='primary.900'>
				ACTIVE POSITIONS
			</Text>
			<TableContainer>
				<Table>
					<Thead textStyle='body-2'>
						<Tr>
							<Th>Pool</Th>
							<Th>Supply</Th>
							<Th>Total APY</Th>
							<Th>Earnings</Th>
							<Th position='sticky' right='0px' bg='white' zIndex='0'></Th>
						</Tr>
					</Thead>
					<Tbody>
            {activeVaults.map((vault, i)=> (
              <LendingPostionItem
                key={i}
                onWithdrawClick={()=> setActiveVault(vault)}
                {...vault}
              />
            ))}
					</Tbody>
				</Table>
			</TableContainer>
      {activeVault !== null && <ModalWithdraw
        isOpen={activeVault !== null}
        onClose={()=> setActiveVault(null)}
        vault={activeVault}
      />}
		</Box>        
	)
}

interface LendingPositionItemProps extends ActiveLendingVault {
  onWithdrawClick: ()=> void;
}

function LendingPostionItem({
  underlying, uSymbol, uDecimals, balanceAmount, poolApy, earnings, onWithdrawClick
}: LendingPositionItemProps) {
  return (
    <Tr>
      <Td>
        <HStack>
          <AddToken
            token={{
              address: underlying,
              symbol: uSymbol,
              decimals: uDecimals
            }}
          />
          <Image src={getTokenImage(uSymbol)} w="24px" h="24px"/>
          <Text>{uSymbol} Pool</Text>
        </HStack>
      </Td>
      <Td>
        {fixed2Decimals(balanceAmount, uDecimals, 2)} {uSymbol}
      </Td>
      <Td>
        <Text color='green.500'>{poolApy === 0 ? "---": `${poolApy.toFixed(2)}%`}</Text>
      </Td>
      <Td>
        {earnings ? `$${earnings}` : '--'}
      </Td>
      <Td position='sticky' right='0px' bg='white' zIndex='0'>
        <HStack spacing="16px">
          <Button 
            size='sm'
            variant='ghost'
            colorScheme='primary'
            onClick={()=> onWithdrawClick()}
          >Withdraw</Button>
          {/* <Button
            size='sm'
            variant='outline'
            colorScheme='primary'
          >Supply</Button> */}
        </HStack>
      </Td>
    </Tr>
  )
}