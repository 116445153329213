import { Box, BoxProps, Flex, Img, Text } from "@chakra-ui/react";
import { getTokenImage } from "../utils/image";
import { useEffect, useRef } from "react";
import { TradingWidgetConfig } from "../constants/interfaces";

export function TradingView({
  symbol,
  priceUsd,
  config
}: {
  symbol: string,
  priceUsd: number | undefined,
  config: TradingWidgetProps
}) {
  return <Box>
    <Flex color='primary.900'>
      <Box w='70%' display='flex' flexDir='row'>
        <Img src={getTokenImage(symbol)} w='32px' h='32px' me='12px'/>
        <Text textStyle='heading-3'>{symbol} VAULT</Text>
      </Box>
      <Box w='30%' display='flex' flexDir='row' alignItems='center'>
        <Text me='8px' textStyle='heading-3' fontFamily='body'>${priceUsd?.toFixed(2)}</Text>
        <Text textStyle='body-bold-3' opacity='0.64' me='30px'>Current Price</Text>
      </Box>
    </Flex>
    <TradingViewWidget
      {...config}
      mt='22px'
      fontFamily='body'
      // border='15px solid'
      // borderColor= '#fafafaee'
      // borderRadius='16px'
    />
  </Box>
}

interface TradingWidgetProps extends TradingWidgetConfig, BoxProps {}
let tvScriptLoadingPromise:any;
export function TradingViewWidget({
  source, pair, ...rest
}: TradingWidgetProps) {
  const onLoadScriptRef:any = useRef();

  useEffect(
    ():any => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_585a5') && 'TradingView' in window) {
          let w:any = window;
          new w.TradingView.widget({
            width: '100%',
            height: '338px',
            symbol: `${source}:${pair}`,
            interval: "30",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "en",
            toolbar_bg: "#F4F5FB",
            enable_publishing: false,
            container_id: "tradingview_585a5",
            save_image: false
          });
        }
      }
    },[pair, source]
  );

  return (
    <Box {...rest} className='tradingview-widget-container'>
      <div id='tradingview_585a5' />
    </Box>
  );
}