import { Box, BoxProps, Flex, HStack, Image, Text } from "@chakra-ui/react";
import IndicativeNumber from "../IndicativeNumber";
import { InvestmentVault } from "../../utils/investments";
import { formatVolume } from "../../utils/helpers";
import { getRewardTokenIcon } from "../../utils/image";

export function VaultData({
  chainId,
  data
}: {chainId: number, data: InvestmentVault}) {

  const borrowVault = data.borrowVaults.length > 0 ? data.borrowVaults[0] : null;
  
  return <Flex gap={5} alignItems='center'>
    <DataBlock
      title='price'
      value={`$ ${data.priceUsd?.toFixed(2)}`}
    />
    {borrowVault && <Box py={5}>
      <Text
        mb='2px'
        textStyle='label'
        color='primary.900'
        opacity='0.64'
      >BORROW REWARDS APR</Text>
      <HStack>
        {borrowVault.rewardsApr && borrowVault.rewardsApr.value && <Image src={getRewardTokenIcon(chainId)} h='16px' w='16px'/>}
        <Text color='green.500' textStyle='body-bold-2'>{borrowVault.rewardsApr && borrowVault.rewardsApr.value ? `${borrowVault.rewardsApr.value.toFixed(0)}${borrowVault.rewardsApr.suffix}` : '---'}</Text>
      </HStack>
    </Box>}
    <DataBlock
      title='Borrow Liquidity'
      value={borrowVault ? `$ ${formatVolume(data.borrowVaults[0].liquidityUsd)}`: '---'}
    />
    <DataBlock
      title='leverage interest'
      value={data.interestRates ? data.interestRates : '---'} 
    />
  </Flex>
}

interface DataBlockProps extends BoxProps {
  title: string;
  value: string | number;
  valueColor?: string;
  valueIndicative?: boolean;
}

function DataBlock({
  title, value, valueIndicative, ...rest
}: DataBlockProps) {
  return <Box
    py='5px'
    {...rest}
  >
    <Text
      mb='2px'
      textStyle='label'
      color='primary.900'
      opacity='0.64'
    >{title.toUpperCase()}</Text>
    {valueIndicative ? <IndicativeNumber
      number={typeof value === 'string' ? Number(value) : value}
      textStyle='body-bold-2'
      prefix='$'
    /> : <Text
      textStyle='body-bold-2'
      color='primary.900'
    >{value}</Text>}
  </Box>
}