import { HStack, Img, Text } from "@chakra-ui/react";
import { getTokenImage } from "../utils/image";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { atomWeFiPrice } from "../atoms";

export function WeFiPrice() {
  const [wefiPrice, setWeFiPrice] = useAtom(atomWeFiPrice);

  useEffect(()=> {
    fetch(`https://coins.llama.fi/prices/current/polygon:0xffa188493c15dfaf2c206c97d8633377847b6a52`)
      .then((res)=> res.json())
      .then((resJson) => {
        if(resJson.coins && resJson.coins["polygon:0xffa188493c15dfaf2c206c97d8633377847b6a52"]) {
          setWeFiPrice(resJson.coins["polygon:0xffa188493c15dfaf2c206c97d8633377847b6a52"].price);
        } else {
          console.log("Could not find wefi price in api response");
        }
  
      })
      .catch((err)=> {
        console.log('error fetching wefi price', err)
      })
  }, []);
  if (wefiPrice === null) {
    return null;
  }
  return <HStack textStyle='body-2'>
    <Img src={getTokenImage('wefi')} h='24px' w='24px'/>
    <Text color="gray.500">${wefiPrice.toFixed(3)}</Text>
  </HStack>
}