import { Box, Text, Button, Icon, useBreakpointValue, Link } from "@chakra-ui/react";
import ImageBg from '../images/graphics/spectrum.svg';
import StackedImages from "./StackedImages";
import { useAtom } from "jotai";
import { atomUserCrossChainbalances } from "../atoms"; 
import { useNavigate } from "react-router-dom";
import { getChainIcon } from "../utils/image";
import { PiArrowRightBold } from "react-icons/pi";
import { FiExternalLink } from "react-icons/fi";
 
export default function CrossChainBalance() {
  const [crossChainBalances] = useAtom(atomUserCrossChainbalances);
  const navigate = useNavigate();
  let isMobile = useBreakpointValue({base: true, md: false});

  if (crossChainBalances?.totalUSD) {

    return (
      <Box
        bgImage={isMobile ? '': ImageBg}
        bgPos='right bottom'
        bgRepeat='no-repeat'
        minHeight="140px"
        pos="relative"
        p="24px"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="16px"
      >
        <StackedImages
          images={crossChainBalances.chains.map((id)=> getChainIcon(id))}
          w="32px"
          h="32px"
          leftUnits="-15px"
        />
        <Text mt="16px" textStyle="subheading-3" color="primary.900">
          YOU HAVE <Text display="inline" color="primary.300">{crossChainBalances ? `$${crossChainBalances.totalUSD.toFixed(2)}` : "---"}</Text> WORTH ASSETS
          <br/>
          DISTRIBUTED ACROSS <Text display="inline" color="primary.300">{crossChainBalances ? crossChainBalances.chains.length : "---"} NETWORKS</Text>
        </Text>
        <Button
          size="sm"
          pos={"absolute"}
          right="20px"
          bottom={isMobile ? '-10px': "20px"}
          variant="solid"
          colorScheme={isMobile ? "primary": "whiteAlpha"}
          onClick={()=> navigate('/bridge')}
          // as={Link}
          // isExternal={true}
          // href="https://bridge.wanchain.org"
          // target="_blank"
          rightIcon={<Icon as={PiArrowRightBold} />}
          // rightIcon={<Icon as={FiExternalLink} />}
        >
          Use Bridge
        </Button>
      </Box>
    )
  } else {
    return null;
  }
}