import {
  Box, Slider, SliderMark, SliderTrack, SliderFilledTrack, SliderThumb, Divider, Text, Flex, Badge, HStack
} from '@chakra-ui/react';

interface LeverageSliderProps {
  max: number,
  value: number
  onSliderChange: (leverage: number)=> void
}
export function LeverageSlider({
  max,
  value,
  onSliderChange
}: LeverageSliderProps) {

  const labelStyles = {
    // ml: '-2.5',
    textStyle: 'body-bold-3',
    color: 'primary.900',
    opacity: '0.4'
  }
  const markDividerStyles= (val: number) => {
    const styles = {
      h: '12px',
      mt: '-6px',
      bgColor: '#0429EF',
      w: '2px',
      opacity: '1' 
    }
    if(val >= value) {
      styles.bgColor = '#D8DBEB';
    }
    return styles;
  }

  return (
    <Box pt={6} pb={2}>
      <Slider
        defaultValue={value}
        min={1.1}
        max={max}
        step={0.1}
        colorScheme='primary'
        aria-label='slider-ex-6'
        onChange={(val) => {
          onSliderChange(val);
        }}
      >
        {Array(max).fill('').map((el, i)=> {
          if (max > 5 && i!==0 && (i+1)!==max && (i+1)%3 !==0) {
            return null;
          } else { 
            return  <SliderMark value={i ===0 ? 1.1 : i+1} key={i}>
              <Divider orientation='vertical' {...markDividerStyles(i === 0 ? 1.1 : i+1)} ></Divider>
              <Text {...labelStyles} mt='4px'>{i===0 ? 1.1 : i+1}x</Text>
            </SliderMark>
          }
        })}
        <SliderMark
          value={value}
          textAlign='center'
          bg='white'
          color='primary.900'
          textStyle='body-bold-3'
          border='1px solid'
          borderColor='gray.150'
          borderRadius='4px'
          p='4px'
          mt='-28px'
          ml='-16px'
          w='34px'
          h='25px'
        >
          {value}x
        </SliderMark>
        <SliderTrack 
          h='4px'
          borderRadius='4px'
          backgroundColor='#D8DBEB'
        >
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb
          border='2px solid'
          borderColor='primary.500'
          w='16px'
          h='16px'
        />
      </Slider>
    </Box>
  )
}

export function LeverageSlider2({
  max,
  value,
  onSliderChange
}: LeverageSliderProps) {

  const labelStyles = {
    // ml: '-2.5',
    textStyle: 'body-bold-3',
    color: 'primary.900',
    opacity: '0.4'
  }
  const markDividerStyles= (val: number) => {
    const styles = {
      h: '12px',
      mt: '-6px',
      bgColor: '#0429EF',
      w: '2px',
      opacity: '1' 
    }
    if(val >= value) {
      styles.bgColor = '#D8DBEB';
    }
    return styles;
  }

  return (
    <Flex pb={2}>
      <Box w='80%'>
        <Slider
          defaultValue={value}
          min={1.1}
          max={max}
          step={0.1}
          colorScheme='primary'
          aria-label='slider-ex-6'
          onChangeEnd={(val) => {
            onSliderChange(val);
          }}
        >
          {Array(max).fill('').map((el, i)=> {
            if (max > 5 && i!==0 && (i+1)!==max && (i+1)%3 !==0) {
              return null;
            } else { 
              return  <SliderMark value={i ===0 ? 1.1 : i+1} key={i}>
                <Divider orientation='vertical' {...markDividerStyles(i === 0 ? 1.1 : i+1)} ></Divider>
                <Text {...labelStyles} mt='4px'>{i===0 ? 1.1 : i+1}x</Text>
              </SliderMark>
            }
          })}
          {/* <SliderMark
            value={value}
            textAlign='center'
            bg='white'
            color='primary.900'
            textStyle='body-bold-3'
            border='1px solid'
            borderColor='gray.150'
            borderRadius='4px'
            p='4px'
            mt='-28px'
            ml='-16px'
            w='34px'
            h='25px'
          >
            {value}x
          </SliderMark> */}
          <SliderTrack 
            h='4px'
            borderRadius='4px'
            backgroundColor='#D8DBEB'
          >
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb
            border='2px solid'
            borderColor='primary.500'
            w='16px'
            h='16px'
          />
        </Slider>
      </Box>
      <HStack w='20%' alignSelf='center' justifyContent='flex-end'>
        <Badge
          p={2}
          color='primary.900'
          bg='#F4F5FB'
          borderRadius='8px'
          textStyle='label'
          textTransform='lowercase'
          me={2}
        >
          {value}x
        </Badge>
      </HStack>
    </Flex>
  )
}
