import { Address, useAccount, useContractRead, useNetwork } from "wagmi"
import { getAbi, getContractAddress, getOraclePriceDecimals, getVaults } from "../constants";
import { useAtom } from "jotai";
import { 
  VaultSnapshot,
  VaultsSnapshot,
  atomVaultSnapshotWrite,
  atomRewardsAccruedWrite,
  atomRefetchSnapshot,
  atomRefetchSnapshotWrite,
  atomDefaultChainID
} from "../atoms";
import { useEffect } from "react";
import { zeroAddress } from "viem";
import { fixed2Decimals } from "../utils/helpers";

interface ResponseVault {
  pToken: Address;
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceStored: string;
  supplyRatePerBlock: string;
  borrowRatePerBlock: string;
  buyFactor: string;
  totalCash: string;
  totalBorrows: string;
  totalSupply: string;
  exchangeRateStored: string;
  oraclePrice: string;
  underlyingBalance: string;
  supplySpeed: string;
  borrowSpeed: string;
}

export default function useSnapshotRead() {
  const [ , setSnapshot] = useAtom(atomVaultSnapshotWrite);
  const [ , setCompAccrued] = useAtom(atomRewardsAccruedWrite);
  const [refetch] = useAtom(atomRefetchSnapshot); 
  const [ , setRefetch] = useAtom(atomRefetchSnapshotWrite); 
  const {address, isConnected} = useAccount();
  const {chain} = useNetwork();
  const [defaultChainId] = useAtom(atomDefaultChainID);
 
  const oraclePriceDecimals = getOraclePriceDecimals(isConnected ? chain?.id : defaultChainId);
  const contractAddress = getContractAddress({
    chainId: isConnected ? chain?.id : defaultChainId,
    contractName: "DataUtility"
  });

  const abi = getAbi({
    chainId: isConnected ? chain?.id : defaultChainId,
    abiFile: "DataUtility"
  })
  const vaults = getVaults(isConnected ? chain?.id : defaultChainId );
  const vaultAddresses: Address[] = vaults.map((item)=> item.address);
 
  const data = useContractRead({
    chainId: isConnected ? chain?.id : defaultChainId,
    scopeKey: 'snapshot',
    address: contractAddress,
    abi,
    functionName: "multiGetAccountSnapshot",
    args: [
      vaultAddresses,
      isConnected ? address : zeroAddress
    ],
    // enabled: isConnected && chain?.id !== undefined && !chain.unsupported
  });

  useEffect(()=> {
    if(refetch) {
      data.refetch();
      setRefetch(false);
    }
  }, [refetch, data, setRefetch]);
  
  if (data.data && !data.isError) {
    const response:any = data.data;
    // console.log(response);
    setSnapshot(prepareVaultsSnapshot(response.tokensData, oraclePriceDecimals));
    setCompAccrued(response.compAccrued);
  }
  if (data.isError || chain?.unsupported) {
    setSnapshot(null);
    setCompAccrued("0");
  }
}


const prepareVaultsSnapshot = (data: ResponseVault[], oraclePriceDecimals: string): VaultsSnapshot => {
  let vaultsSnapshot:VaultsSnapshot = {};
  data.forEach((res) => {
    let row: VaultSnapshot = {
      lendTokens: res.balanceOf,
      lendBalance: res.balanceOfUnderlying,
      borrowBalance: res.borrowBalanceStored,
      supplyRatePerBlock: res.supplyRatePerBlock,
      borrowRatePerBlock: res.borrowRatePerBlock,
      buyFactor: res.buyFactor,
      totalCash: res.totalCash,
      totalBorrows: res.totalBorrows,
      totalSupply: res.totalSupply,
      exchangeRateStored: res.exchangeRateStored,
      oraclePrice: fixed2Decimals(res.oraclePrice, oraclePriceDecimals),
      supplySpeed: res.supplySpeed,
      borrowSpeed: res.borrowSpeed,
      underlyingBalance: res.underlyingBalance
    };
    vaultsSnapshot[res.pToken.toLowerCase()] = row;
  });
  return vaultsSnapshot;
}
