import { Flex } from "@chakra-ui/react";
import PortfolioStats from "../../components/PortfolioStats";
import LiquidationStats from "../../components/LiquidationStats";
import OpenLoanPositions from "../../components/OpenLoanPositions";
import ClosedLoanPositions from "../../components/ClosedLoanPositions";
import TransactionHistory from "../../components/TransactionHistory";
import { getTransactions } from "../../utils/events";
import { useAtom } from "jotai";
import { atomDefaultChainID, atomUserEvents, atomVaultsSnapshot } from "../../atoms";
import { useAccount, useNetwork } from "wagmi";
import { getVaults, protocolSupportedChainIds } from "../../constants";
import { getLoanPositions } from "../../utils/loanPositions";
import { useMemo } from "react";
import { getPortfolioStats } from "../../utils/portfolio";
import { UnsupportedNetwork, UnsupportedNetworkProtocolOnly } from "../../components/UnsupportedNetwork";
import { NotConnected } from "../../components/NotConnected";
import { VoyageBanner } from "../../components/VoyageBanner";

export default function PortfolioPage() {
  const [snapshot] = useAtom(atomVaultsSnapshot);
  const {isConnected} = useAccount();
  const {chain} = useNetwork();
  const vaults = getVaults(chain?.id);
  const [events] = useAtom(atomUserEvents);
  const [defaultChainId] = useAtom(atomDefaultChainID);
  const loanPositonsData = useMemo(()=> getLoanPositions(
    vaults.filter((v)=> v.borrowEnabled),
    snapshot, events
  ) , [snapshot, vaults, events]);
  const txns = useMemo(()=> getTransactions(vaults, events), [vaults, events]);
  const portfolioStats = useMemo(()=> getPortfolioStats(vaults, snapshot, events), [vaults, snapshot, events]);

  if(isConnected) {
    if(chain?.unsupported) {
      return <UnsupportedNetwork/>
    } else if(!protocolSupportedChainIds.includes(chain ? chain.id : 0)) {
      return <UnsupportedNetworkProtocolOnly/>
    }
  }

  if(!isConnected) {
    return <NotConnected/>
  }


  return (
    <Flex direction='column' gap='80px'>
      <VoyageBanner chainId={isConnected ? chain?.id : defaultChainId}/>
      <Flex direction='column' bgColor='gray.100'  borderRadius='16px'>
        <PortfolioStats 
          totalBorrows={portfolioStats.totalBorrowedUsd}
          totalValue={portfolioStats.totalAssetsUsd}
          pnlUsd={portfolioStats.pnlUsd}
        />
        {portfolioStats.liquidationScore > 0 && <LiquidationStats
          totalValue={`$ ${portfolioStats.totalAssetsUsd.toFixed(2)}`}
          borrowAmount={`$ ${portfolioStats.totalBorrowedUsd.toFixed(2)}`}
          liquidationPoint={`$ ${portfolioStats.liquidationPoint.toFixed(2)}`}
          liquidationScore={portfolioStats.liquidationScore}
          liquidationRisk={portfolioStats.liquidationRisk}
        />}
      </Flex>
      <OpenLoanPositions data={loanPositonsData}/>
      <ClosedLoanPositions data={loanPositonsData.closed} />
      <TransactionHistory txns={txns} chainId={chain?.id}/>
    </Flex>
  )
}