import { VaultsSnapshot } from "../atoms";
import { VaultInterface } from "../constants/interfaces";
import { ContractEvent } from "./events/interfaces";
import { getUSDPrice, isZero } from "./helpers";
import { getOverallInvestmentStats } from "./investments";
import { getSupplyPnL } from "./lending";

export interface PortfolioStats {
  totalAssetsUsd: number;
  totalBorrowedUsd: number;
  liquidationPoint: number;
  liquidationScore: number; //on scale of 100;
  liquidationRisk: 'High' | 'Moderate' | 'Low';
  pnlUsd: number;
}
export function getPortfolioStats(
  vaults: VaultInterface[],
  snapshot: VaultsSnapshot | null,
  events: ContractEvent[]
): PortfolioStats {
  const data: PortfolioStats = {
    totalAssetsUsd: 0,
    totalBorrowedUsd: 0,
    liquidationPoint: 0,
    liquidationScore: 0,
    liquidationRisk: 'Low',
    pnlUsd: 0
  }
  if(vaults.length === 0 || snapshot === null) {
    return data;
  }

  vaults.forEach((vault)=> {
    const vaultSnapshot = snapshot[vault.address.toLowerCase()];
    const balance = vaultSnapshot.lendBalance;
    const borrowBalance = vaultSnapshot.borrowBalance;
    if(!isZero(balance)) {
      data.totalAssetsUsd += Number(getUSDPrice(vaultSnapshot.oraclePrice, balance, vault.uDecimals));
    }
    if(!isZero(borrowBalance)) {
      data.totalBorrowedUsd += Number(getUSDPrice(vaultSnapshot.oraclePrice, borrowBalance, vault.uDecimals));
    }
  });

  if(data.totalBorrowedUsd > 0) {
    data.liquidationPoint = (data.totalBorrowedUsd / 0.9);
    data.liquidationScore = (data.liquidationPoint/data.totalAssetsUsd) * 100;
    if(data.liquidationScore>80) {
      data.liquidationRisk='High';
    } else if (data.liquidationScore >= 60 && data.liquidationScore <=80) {
      data.liquidationRisk = 'Moderate';
    } else {
      data.liquidationRisk = "Low";
    }
  }

  if(events.length > 0) {
    const investmentStats = getOverallInvestmentStats(vaults, snapshot, events);
    const supplyStats = getSupplyPnL(vaults.filter((vault)=>  vault.lendEnabled), snapshot, events);
    const borrowInterest = data.totalBorrowedUsd - investmentStats.totalBorrowedUsd;
    data.pnlUsd = supplyStats.pnlUsd + investmentStats.pnlUsd - borrowInterest;
  }

  return data;
}