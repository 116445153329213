import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  BoxProps,
  FlexProps,
  Img,
  Divider,
  HStack,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import React, { ReactText, useEffect, useRef, useState } from 'react';
import Logo from '../images/logo.svg';
import LogoW from '../images/wicon.png';
import {
  IconBarsCircle,
} from './CustomIcons';
import { PiCaretDownBold, PiCaretRightBold, PiChartLineBold, PiCoinBold, PiGameControllerBold, PiPottedPlantBold, PiUsersThreeBold, PiVaultBold } from 'react-icons/pi';
import { BiNetworkChart, BiSolidChevronLeft, BiSolidChevronRight } from 'react-icons/bi';
import { GiTwoCoins } from 'react-icons/gi';
import GamePng from '../images/graphics/game.png';
import { FiChevronsLeft, FiChevronsRight, FiExternalLink } from 'react-icons/fi';
import {TbSquareRoundedChevronLeftFilled, TbSquareRoundedChevronRightFilled} from 'react-icons/tb';

interface LinkItemProps {
  name: string;
  icon: any;
  to: string;
  isExternal: boolean;
  hasSubItems: boolean;
  items: LinkItemProps[]
}

const LinkItems: Array<LinkItemProps> = [
  
  { name: 'Lend & Earn', to: '/', icon: PiPottedPlantBold, isExternal: false, hasSubItems: false, items: [] },
  { name: 'Leverage Vaults', to: '/leverage-vaults', icon: PiVaultBold, isExternal: false, hasSubItems: false, items: []  },
  { name: 'Portfolio', to: '/portfolio', icon: IconBarsCircle, isExternal: false, hasSubItems: false, items: [] },
  { name: 'Staking', to: '/stake-wefi', icon: GiTwoCoins, isExternal: false, hasSubItems: false, items: [] },
  // { name: 'DAO', to:'', icon: PiUsersThreeBold, isExternal: false, hasSubItems: true,
  //     items: [
  //       { name: 'Stake WeFi', to: '/ve-stake', icon: GiTwoCoins, isExternal: false, hasSubItems: false, items: [] },
  //       { name: 'Vote on Snapshot', to: 'https://snapshot.org/#/wefi.xyz', icon: FiExternalLink, isExternal: true, hasSubItems: false, items: [] }
  //     ]
  // },
  // { name: 'Bridge', to:'', icon: BiNetworkChart, isExternal: false, hasSubItems: true,
  //     items: [
  //       { name: 'Bridge', to: '/bridge', icon: BiNetworkChart, isExternal: false, hasSubItems: false, items: [] },
  //       { name: 'WanChain Bridge', to: 'https://bridge.wanchain.org', icon: FiExternalLink, isExternal: true, hasSubItems: false, items: [] },
  //       { name: 'WEFI Token', to: 'https://bridge.wefi.xyz', icon: PiCoinBold, isExternal: true, hasSubItems: false, items: [] },
  //     ]
  // },
  // { name: 'Games', to:'', icon: PiGameControllerBold, isExternal: false, hasSubItems: true,
  //     items: [
  //       { name: 'Prediction', to: '/prediction', icon: PiChartLineBold, isExternal: false, hasSubItems: false, items: [] }
  //     ]
  // }
  // { name: 'Rewards', to: '/rewards', icon: IconCrown, isExternal: false, hasSubItems: false, items: [] },
  // { name: 'Swap', to: '/swap', icon: PiSwapBold, isExternal: false, hasSubItems: false, items: [] },
  // { name: 'Get Help', to: '/help', icon: IconStarCircle }
];

interface SidebarProps extends BoxProps {
  pathname: string,
  collapsed: boolean,
  mobileView: boolean,
  onClose: ()=> void;
  setCollapsed?: (v: boolean)=> void;
}

export default function SideBar(
  { onClose, mobileView, pathname, collapsed,setCollapsed, ...rest }
  : SidebarProps) {

  const isCollapsed= !mobileView && collapsed;
  return (
    <Box
      transition='1s ease'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w={{ base: 'full', md: isCollapsed ? 20 : 60 }}
      pos='fixed'
      h='full'
      {...rest}>
      <Flex mb='24px' h='20' alignItems='center' mx='8' justifyContent='space-between'>
        {!isCollapsed && <Img src={Logo}/>}
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        <Icon
          cursor='pointer'
          // color='primary.900' opacity={0.64}
          color='#5578F5' 
          as={collapsed ? TbSquareRoundedChevronRightFilled : TbSquareRoundedChevronLeftFilled}
          display={{ base: 'none', md: 'flex' }}
          onClick={()=> {if (setCollapsed !== undefined) {setCollapsed(!collapsed)}}}
        />
      </Flex>     

      {LinkItems.map((link) => {
        if (link.hasSubItems) {
          return <NavDropDown isCollapsed={isCollapsed} pathname={pathname} {...link} onClose={()=> onClose()}/>
        } else {
        return (
          <NavItem  
          isCollapsed={isCollapsed}
          key={link.name}
          isSelected={link.to==='/' ? pathname === link.to : pathname.includes(link.to)}
          onClose={()=> onClose()}
          {...link}
          >
            {link.name}
          </NavItem>)
        }
      })}
      {/* <Box
        px='32px' 
        mt='24px'
      >
        <Link as={RouterLink} to='prediction'>
          <Img src={GamePng} w='100%' />
        </Link>
      </Box> */}
      <Box
        opacity='0.64'
        color='primary.800'
        px='32px' 
        mt='24px'
      >
        {/* <Divider/>
        <HStack spacing='8px' my='24px'>
          <Icon as={IconSun} />
          <ColorModeSwitcher/>
          <Icon as={IconMoon} />
        </HStack> */}
      
        {!isCollapsed && <Divider my={5}/>}
        {!isCollapsed && <VStack mt='24px' spacing='12px' alignItems='flex-start' textStyle='body-3'>
          <HStack spacing='20px'>
            <Link isExternal href='https://github.com/wefi-xyz/'>Github</Link>
            <Link isExternal href='https://blog.paxo.finance/'>Medium</Link>
            <Link isExternal href='https://docs.wefi.xyz/'>Docs</Link>
          </HStack>
          <HStack spacing='20px'>
            <Link isExternal href='https://twitter.com/wefi_xyz'>Twitter</Link>
            <Link isExternal href='https://discord.com/invite/B6U6kectbV'>Discord</Link>
            <Link isExternal href='https://t.me/wefi_xyz'>Telegram</Link>
          </HStack>
        </VStack>}
      </Box>
      {/* <VStack mt='24px' mx='20px' spacing='13px'  borderRadius='12px' alignItems='flex-start' bg='white'>
        <Box px='12px' pt='20px'>
          <Text textStyle='subheading-3' color='primary.900'>
            YOUR ASSETS ARE INSURED
          </Text>
          <Text mt='8px' textStyle='body-3' color='#5A69B6'>
            WeFi has partnered with top insurers to ensure your funds are secured.
          </Text>
        </Box>
        <Img src={ImgBuilding} borderRadius='12px' />
      </VStack> */}
    </Box>
  );
};

interface NavItemProps extends LinkItemProps, FlexProps {
  isCollapsed: boolean;
  isSelected: boolean;
  onClose: ()=> void;
  children: ReactText;
}

const NavItem = ({
  isCollapsed, to, icon, isExternal, children, isSelected, onClose, ...rest }: NavItemProps) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      isExternal={isExternal}
      onClick={()=> onClose()}
    >
      <Flex
        align='center'
        p='4'
        mx='4'
        role='group'
        cursor='pointer'
        _hover={{
          color: 'primary.500'
        }}
        layerStyle={isSelected? 'navItemSelected': 'navItemBase'}
        textStyle={isSelected ? 'subheading-1': 'subheading-2'}
        {...rest}>
        {icon && (
          <Icon
            mr={isCollapsed ? '0': '4'}
            fontSize='16'
            as={icon}
          />
        )}
        {!isCollapsed && children}
      </Flex>
    </Link>
  );
}

interface NavDropDownProps extends LinkItemProps {
  isCollapsed: boolean,
  pathname: string;
  onClose: ()=> void;
}

const NavDropDown = ({
  isCollapsed, icon, name, items, pathname, onClose
}: NavDropDownProps) => {
  const [expand, setExpand] = useState(false);
  const isItemSelected = items.map((item)=> item.to).includes(pathname);
  const ref = useRef(null);
  useEffect(()=> {
    setExpand(isItemSelected);
  }, [isItemSelected]);
  useOutsideClick({
    ref: ref,
    handler: ()=> {
      if(!isItemSelected) {
        setExpand(false);
      }
    }
  });
  return <div ref={ref}>
    <Flex
      onClick={()=> setExpand(!expand)}
      align='center'
      p='4'
      mx='4'
      role='group'
      cursor='pointer'
      _hover={{
        color: 'primary.500'
      }}
      // layerStyle={isSelected? 'navItemSelected': 'navItemBase'}
      layerStyle={'navItemBase'}
      // textStyle={isSelected ? 'subheading-1': 'subheading-2'}
      textStyle={'subheading-2'}
    >
      <Icon
        mr='4'
        fontSize='16'
        as={icon}
      />
      {!isCollapsed && name}
      {!isCollapsed && <Icon
        ms='4'
        fontSize='12' as={ expand ? PiCaretDownBold : PiCaretRightBold} 
      />}
    </Flex>
    {expand && <Box bgColor="#f9fbff">
      {items.map((link) => {
        return (
          <NavItem isCollapsed={isCollapsed} onClose={()=> onClose()} key={link.name} {...link} isSelected={pathname === link.to}>
            {link.name}
          </NavItem>
        )
      })}
    </Box>}
  </div>
}

