import { atomChainTVLWrite, atomDefaultChainID } from "../atoms";
import { useAtom } from "jotai";
import { useNetwork } from "wagmi";
import { useEffect } from "react";

export default function useTvlRead() {
  const {chain} = useNetwork();
  const [, saveTvl] = useAtom(atomChainTVLWrite);
  const [defaultChainId] = useAtom(atomDefaultChainID);

  useEffect(()=> {
    const fetchData = (chainId: number | undefined) => {
      fetch(`https://api.wefi.xyz/tvl/${chainId}`)
      .then((res)=> res.json())
      .then((resJson) => {
        if(resJson.success && "totalTradeVolume" in resJson.response) {
          if(resJson.response.totalTradeVolume > 0){
            saveTvl({
              [chainId ? chainId : ""]: resJson.response.totalTradeVolume
            })
          }
        }
      })
      .catch((err)=> {
        console.log("error while fetching txns", err);
      })
    }

    if(!chain?.id){
      fetchData(defaultChainId);
    }

    if ((chain?.id && !chain?.unsupported && !chain?.testnet)) {
      fetchData(chain?.id);
    } 
    
  }, [chain, defaultChainId])

}