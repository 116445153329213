import { Flex, Text,Box } from "@chakra-ui/react";
import { ChainSelector } from "./ChainSelector";

export function NotConnected() {
  return (
    <Flex justifyContent='center'>
      <Box 
        w={{base: 'full', sm: '100%', md: '50%'}}
        border='1px solid'
        borderColor='gray.150'
        borderRadius='16px'
        p='30px'
      >
        <Text textStyle='subheading-1' color='primary.900' >CONNECT YOUR WALLET</Text>
        <Text textStyle='body-2' color='primary.900' opacity='0.64' >View details on this page by connecting your wallet.</Text>
      </Box>
    </Flex>
  )
}