import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { TradePair } from "../../routes/leverageVaults/LeverageVaulltsPageNew";
import StackedImages from "../StackedImages";
import { getTokenImage } from "../../utils/image";
import { IconAngleDown } from "../CustomIcons";
import { VaultInterface } from "../../constants/interfaces";
import { useState } from "react";
import DrawerPairs from "./DrawerPairs";

export default function TradeSelector (
  {
    chainId,
    blocksPerDay,
    tradePair,
    vaults
  }:
  {
    chainId: number,
    blocksPerDay: number,
    tradePair: TradePair,
    vaults: VaultInterface[]
  }
) {

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  
  return <VStack cursor='pointer' justifyContent='center' onClick={()=> setDrawerOpen(!isDrawerOpen)}>
     <StackedImages 
      height='25px'
      leftUnits="-5px"
      images={[getTokenImage(tradePair.borrowVault.uSymbol), getTokenImage(tradePair.investmentVault.uSymbol)]}
    />
    <HStack alignItems='center'>
    {/* <StackedImages 
      height='25px'
      leftUnits="-5px"
      images={[getTokenImage(tradePair.borrowVault.uSymbol), getTokenImage(tradePair.investmentVault.uSymbol)]}
    /> */}
      <Text textStyle='subheading-1' color='primary.900'>
      {tradePair.investmentVault.uSymbol}&nbsp;/&nbsp;{tradePair.borrowVault.uSymbol}
      </Text>
      <IconAngleDown fontSize='25px'/>
    </HStack>
    <DrawerPairs
      activePair={tradePair}
      chainId={chainId}
      blocksPerDay={blocksPerDay}
      vaults={vaults}
      isOpen={isDrawerOpen}
      onClose={()=> setDrawerOpen(false)}
    />
  </VStack>
}