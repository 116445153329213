import { DexQuoter, DexSlug, DexType } from "../constants/interfaces";
import { useContractReads } from "wagmi";
import { Address } from "viem";
import { getDexABI } from "../constants";
import { useApiQuoters, QuoteApiParams, ApiQuote } from "./useApiQuoters";
import { decimal2Fixed, fixed2Decimals } from "../utils/helpers";

export interface Quote {
  dexId: string,
  dexSlug: DexSlug,
  dexName: string,
  dexType: DexType,
  quote: string
  quoteJSON?: any,
  fee?: string
}


export function useDexQuotesRead(
  quoters: DexQuoter[],
  amountIn: string,
  tokenIn:Address ,
  tokenOut:Address,
  recipient: Address,
  chainId: number | undefined,
  onUpdate: (quotes: Quote[], type: DexType)=> void
) {
  const contractQuoters = quoters.filter((item)=> item.type === 'Contract');
  const apiQuoters = quoters.filter((item)=> item.type === 'API');

  const prepareDexConfigs = () => {
    return contractQuoters.map((item)=> {
      let config = {
        address: item.address,
        abi: getDexABI(item.slug),
        functionName: item.functionName,
        args: getDexArgs(item.slug),
        chainId: chainId
      }
      return config;
    });
  }
  const getDexArgs = (slug: DexSlug) => {
    if(slug === 'uniswap-v3') {
      return [ tokenIn, tokenOut, "3000", amountIn, "0"];
    }  else if(slug === 'quickswap-v3') {
      return [ tokenIn, tokenOut, amountIn, '0' ];
    } else if (slug === 'quickswap-v2') {
      return [ amountIn, [ tokenIn, tokenOut ] ];
    } else if(slug === 'mute-io') {
      return [ amountIn, tokenIn, tokenOut ]
    } else if (slug === 'xswap') {
      return [ amountIn, [ tokenIn, tokenOut ] ];
    } else if (slug ==='uniswap-v3-oku') {
      return [ {tokenIn, tokenOut, fee:'500', amountIn, sqrtPriceLimitX96: '0'} ];
    } else {
      return [];
    }
  }

  const prepareApiQuoters = (): QuoteApiParams=> {
    return {
      tokenIn: tokenIn.toString(),
      tokenOut: tokenOut.toString(),
      amountIn: amountIn,
      chainSlug: 'linea',
      apiQuoters: apiQuoters,
      recipient
    }
  }

  const{isFetching, isRefetching, isLoading} =  useContractReads({
    contracts: prepareDexConfigs(),
    onSuccess: (data) => {
      onUpdate(prepareQuotes(data), 'Contract');
    },
    watch: true
  })

  const {isLoading: isApiLoading} = useApiQuoters({
    params: prepareApiQuoters(),
    onSuccess: (apiQuotes: ApiQuote[]) => {
      onUpdate(prepareApiQuotes(apiQuotes), 'API');
    }
  });
  
  const prepareQuotes = (contractsRes: any) :Quote[] => {
    let data:Quote[] = [];
    contractsRes.forEach((res:any, i: number)=> {
      if(res.status === 'success') {
        let quote: string  = "0";
        let fee: string = '3000';
        const quoter = contractQuoters[i];
        if(quoter.slug === 'uniswap-v3') {
          quote = res.result.toString();
        }
        if(quoter.slug === 'quickswap-v3') {
          quote = res.result[0].toString();
          fee = res.result[1].toString();
        }
        if(quoter.slug === 'quickswap-v2') {
          quote = res.result[1].toString();
        }
        if(quoter.slug === 'mute-io') {
          quote = res.result[0];
        }
        if(quoter.slug === 'xswap') {
          quote = res.result[1];
        }
        if(quoter.slug === 'uniswap-v3-oku') {
          quote = res.result[0].toString();
          fee = "500";
        }
        data.push({
          dexId: quoter.id,
          dexType: contractQuoters[i].type,
          dexSlug: quoter.slug,
          dexName: quoter.name,
          quote,
          fee
        })
      } else {
        console.log("error", res);
      }
    });
    if(data.length > 1) {
      data = data.sort((a, b)=> Number(b.quote)- Number(a.quote))
    }
    return data;
  }

  const prepareApiQuotes = (apiQuotes: ApiQuote[]) :Quote[] => {
    let data:Quote[] = [];
    apiQuotes.forEach((res)=> {
      const dex = apiQuoters.find((item)=> item.slug ===  res.slug);
      if(dex && res.status === 'success') {
        let quote: string  = res.quote;
        let quoteJson: any = res.quoteJson;
        data.push({
          dexId: dex.id,
          dexSlug: res.slug,
          dexType: dex.type,
          dexName: dex.name,
          quote,
          quoteJSON: quoteJson
        })
      }
    });
    if(data.length > 1) {
      data = data.sort((a, b)=> Number(b.quote)- Number(a.quote))
    }
    return data;
  }
 
  return isFetching || isLoading || isRefetching || isApiLoading;
}
