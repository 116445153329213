import { Flex } from "@chakra-ui/react";
import { Leaderboard } from "../../components/leaderboard/Leaderboard";
import { useAccount, useNetwork } from "wagmi";
import { UnsupportedNetwork } from "../../components/UnsupportedNetwork";
import { useAtom } from "jotai";
import { atomDefaultChainID } from "../../atoms";

export default function LeaderboardPage() {
  //chain validation
  const {isConnected} = useAccount();
  const {chain} = useNetwork();
  const [defaultChain] = useAtom(atomDefaultChainID);
  const chainId = isConnected ? chain?.id : defaultChain;
  if (chainId !== 288) {
    return <UnsupportedNetwork
    text1={`Trading Leaderboard is available on Boba Network.`}
    text2='Please switch to Boba Network.'
  />
  } 
  return <Flex direction='column' gap='40px'>
    <Leaderboard/>
  </Flex>
}