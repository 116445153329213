import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  list: {
    // this will style the MenuList component
    px: '24px',
    py: '12px',
    borderRadius: '20px',
    border: '1px solid rgba(3, 17, 92, 0.12)',
    boxShadow: '2px 4px 12px 1px rgba(3, 17, 92, 0.12)'
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    color: 'gray.500',
    py: '12px',
    px: '0px',
    fontSize: '16px',
    fontWeight: 700,
    _notLast: {
      borderBottom: '1px solid #EBECF5',
    },
    _hover: {
      bgColor: "white",
      color: "primary.500"
    }
  },
//   groupTitle: {
//     // this will style the text defined by the title prop
//     // in the MenuGroup and MenuOptionGroup components
//     textTransform: 'uppercase',
//     color: 'white',
//     textAlign: 'center',
//     letterSpacing: 'wider',
//     opacity: '0.7',
//   },
//   command: {
//     // this will style the text defined by the command
//     // prop in the MenuItem and MenuItemOption components
//     opacity: '0.8',
//     fontFamily: 'mono',
//     fontSize: 'sm',
//     letterSpacing: 'tighter',
//     pl: '4',
//   },
//   divider: {
//     // this will style the MenuDivider component
//     my: '4',
//     borderColor: 'white',
//     borderBottom: '2px dotted',
//   },
})
// export the base styles in the component theme
export const menuStyles = defineMultiStyleConfig({ baseStyle })