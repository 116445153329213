import { PredictionConfig } from '../utils/predictions/interfaces';
import {ContractAddressMap, DexQuoter, VaultInterface} from './interfaces';
import PredictionV2ABI from './abi/predictionV2.json';

export const Vaults: VaultInterface[] = [
  {
    address: "0x54ecc8832b65c2db0c53235cde94991c5cdcabf9",
    decimals: 8,
    underlying: "0x176211869ca2b568f2a7d4ee941e073a821ee1ff",
    uSymbol: "USDC",
    uName: "USD Coin",
    uDecimals: 6,
    lendEnabled: true,
    borrowEnabled: true,
    leverage: 5,
    buyEnabled: false,
    isStable: true,
    buyAssets: ["0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e", "0x48014ede3ec0cfa64dfb50eb7a2e74e4ad536cb3"],
    minLendAmountRequired: 25
  },
  {
    address: "0xf9a65e52dec5b525a44d0bd2986a97ff3bd72d13",
    decimals: 8,
    underlying: "0x60892e742d91d16be2cb0ffe847e85445989e30b",
    uSymbol: "WEFI",
    uName: "WEFI",
    uDecimals: 18,
    lendEnabled: true,
    borrowEnabled: false,
    leverage: 5,
    buyEnabled: false,
    isStable: false,
    buyAssets: [],
  },
  {
    address: "0xce39ec58257c790f622fa7dfd5b4cc372f41ed9e",
    decimals: 8,
    underlying: "0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f",
    uSymbol: "WETH",
    uName: "Wrapped Ether",
    uDecimals: 18,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x54ecc8832b65c2db0c53235cde94991c5cdcabf9"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'ETHUSD'
    }
  },
  {
    address: "0x48014ede3ec0cfa64dfb50eb7a2e74e4ad536cb3",
    decimals: 8,
    underlying: "0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4",
    uSymbol: "WBTC",
    uName: "Wrapped BTC",
    uDecimals: 8,
    lendEnabled: false,
    borrowEnabled: false,
    buyEnabled: true,
    borrowAssets: ["0x54ecc8832b65c2db0c53235cde94991c5cdcabf9"],
    tradingWidgetConfig: {
      source: 'COINBASE',
      pair: 'WBTCUSD'
    }
  }
];

export const defaultTradePair = "usdc-weth"; 

export const ContractAddresses: ContractAddressMap = {
  'DataUtility': "0x13955758a6F6E8Be65246F3600161380728E9D1a",
  'Comptroller': "0x301C76e7b60e9824E32991B8F29e1c4a03B4F65b"
}

export const blockExplorer = 'https://lineascan.build';

export const blocksPerDay=14400;

export const DexQuoters : DexQuoter[] = [
  {
    id: '3',
    name: 'KyberSwap Aggregator',
    slug: 'kyberswap-aggregator',
    type: 'API',
    quoteApiUrl: "https://aggregator-api.kyberswap.com/{{chainSlug}}/api/v1/routes?tokenIn={{tokenIn}}&tokenOut={{tokenOut}}&amountIn={{amountIn}}",
    buildQuoteApiUrl: "https://aggregator-api.kyberswap.com/{{chainSlug}}/api/v1/route/build"
  }
]

export const eventsApiChainSlug='linea-mainnet';
export const kyberChainSlug="linea";
export const oraclePriceDecimals='18';


export const predictionTokensConfig: PredictionConfig[] = [{
  token: {
    isNative: true,
    address: undefined,
    symbol: "ETH",
    decimals: 18,
    pythPriceId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
  },
  contract: "0x2f9585028Ea034d80F28842aFE0b2dF8025802Ea",
  abi: PredictionV2ABI,
  oracle: "0x",
  bufferSeconds: 180,
  intervalSeconds: 300,
  minBetAmount: 0.0001,
  graphEndpoint: ""
}]
