import { Button, Box, Icon, Img, Text, Spinner, useToast, Link } from "@chakra-ui/react";
import { useAccount, useNetwork, useWaitForTransaction } from "wagmi";

import ModalWalletConnect from "./ModalWalletConnect";
import { useEffect, useState } from "react";
import { shortAddress } from "../utils/helpers";
import { IconAngleDown } from './CustomIcons';
import AvatarImage from '../images/icons/avatar.svg';
import AccountDrawer from "./AccountDrawer";
import useEventsFetch from "../hooks/useEventsFetch";
import useCrossChainBalanceRead from "../hooks/useCrossChainBalanceRead";
import { atomPendingTx, atomPendingTxWrite, atomPendingHash, atomPendingHashWrite, atomUserEventsMerge, atomRefetchSnapshot } from "../atoms";
import { useAtom } from 'jotai';
import { FiExternalLink } from "react-icons/fi";
import { getBlockExplorer } from "../constants";
import useTvlRead from "../hooks/useTvlRead";

export default function Account() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const {isConnected, address } = useAccount();
  const {chain} = useNetwork();
  const [pendingTx] = useAtom(atomPendingTx);
  const [ , setTx] = useAtom(atomPendingTxWrite);
  const [pendingHash] = useAtom(atomPendingHash);
  const [ , setHash] = useAtom(atomPendingHashWrite);
  const [, setUserEventsMerge] = useAtom(atomUserEventsMerge);
  const [, setRefetchSnapshot] = useAtom(atomRefetchSnapshot);
  const toast = useToast();

  useCrossChainBalanceRead()
  useEventsFetch();
  useTvlRead();
  useEffect(()=> {
    if(!isConnected) {
      setModalOpen(true);
    }
  }, []);
  
  useEffect(()=> {
    if(isConnected) {
      setModalOpen(false);
    }
  }, [isConnected])


  useWaitForTransaction({
    hash: pendingTx? pendingTx[0].transactionHash : pendingHash? pendingHash : undefined,
    onSuccess: (data)=> {
      if(data.status === "success") {
        if(pendingTx){
          setUserEventsMerge(pendingTx);
        }
      }
      setRefetchSnapshot(true);
      const hashStr = pendingTx ? pendingTx[0].transactionHash.toString() : pendingHash?.toString();
      toast({
        title: 'Txn Executed',
        description: <Link textStyle='body-bold-2' isExternal href={`${getBlockExplorer(chain?.id)}/tx/${hashStr}`}>
          {shortAddress(hashStr)} <Icon as={FiExternalLink}/>
        </Link>,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
      setTx(null);
      setHash(null);
    }
  });
  
  if (isConnected) {
    return (
      <Box>
        {(pendingTx || pendingHash) ? <Button
          variant='solid'
          colorScheme='primary'
          onClick={()=> setDrawerOpen(true)}
        >
          <Spinner 
            speed='1s'
            emptyColor='primary.100'
            color='white'
            size='sm'
            me='10px'
          />
          <Text textStyle="body-2">1 Txn Ongoing</Text>
          <Icon h='20px' w='20px' as={IconAngleDown} />
        </Button> : <Button 
          variant="outline"
          color="gray.500"
          onClick={()=> setDrawerOpen(true)}
        >
          <Img src={AvatarImage} w="24px" h="24px" mr={2}/>
          <Text textStyle="body-2">{shortAddress(address)}</Text>
          <Icon h='20px' w='20px' as={IconAngleDown} />
        </Button>}
        <AccountDrawer
          isOpen={isDrawerOpen}
          onClose={()=> setDrawerOpen(false)}
        />
      </Box>
    )
  } else {
    return (
      <Box>
        <Button
          onClick={()=> setModalOpen(true)}
          variant='solid'
          colorScheme='primary'
        >
          Connect Wallet
        </Button>
        <ModalWalletConnect 
          isOpen={isModalOpen}
          onClose={()=> setModalOpen(false)}
        />
      </Box>
    )
  }
}