import { atom } from "jotai";
import { Address } from "viem";
import { ContractEvent } from "../utils/events/interfaces";
import { BetInfo, RoundResponse } from "../utils/predictions/interfaces";

export interface VaultSnapshot {
  lendTokens: string;
  lendBalance: string;
  borrowBalance: string;
  supplyRatePerBlock: string;
  borrowRatePerBlock: string;
  buyFactor: string;
  totalCash: string;
  totalBorrows: string;
  totalSupply: string;
  exchangeRateStored: string;
  oraclePrice: string;
  supplySpeed: string;
  borrowSpeed: string;
  underlyingBalance: string;
}
export interface VaultsSnapshot {
  [key: Address | string]: VaultSnapshot
}

export const atomVaultsSnapshot= atom <VaultsSnapshot | null> (null);
export const  atomVaultSnapshotWrite= atom(
  null,
  (get, set, update: VaultsSnapshot | null) => {
    set(atomVaultsSnapshot, update);
  }
)

export const atomRewardsAccrued = atom<string>("0");
export const atomRewardsAccruedWrite = atom(
  null,
  (get, set, update:string) => {
    set(atomRewardsAccrued, update);
  }
)

export const atomRefetchSnapshot = atom<boolean>(false);
export const atomRefetchSnapshotWrite = atom(
  null,
  (get, set, update:boolean) => {
    set(atomRefetchSnapshot, update);
  }
)

export const atomUserEvents = atom<ContractEvent[]>([]);
export const atomUserEventsWrite = atom(
  null,
  (get, set, update:ContractEvent[]) => {
    set(atomUserEvents, update);
  }
)

export const atomUserEventsMerge = atom(
  null,
  (get, set, update:ContractEvent[]) => {
    set(atomUserEvents, [...get(atomUserEvents) , ...update]);
  }
)

export const atomDefaultChainID = atom<number>(288);
export const atomDefaultChainIDWrite = atom(
  null,
  (get, set, update: number) => {
    set(atomDefaultChainID, update);
  }
)


export const atomRefetchEvents = atom<boolean>(false);
export const atomRefetchEventsWrite = atom(
  null,
  (get, set, update:boolean) => {
    set(atomRefetchEvents, update);
  }
)

export interface CrossChainBalances {
  totalUSD : number;
  assets: string[];
  chains: number[];
}

export const atomUserCrossChainbalances = atom<CrossChainBalances | null>(null);
export const atomUserCrossChainbalancesWrite = atom(
  null,
  (get, set, update:CrossChainBalances | null) => {
    set(atomUserCrossChainbalances, update);
  }
)

export const atomPendingTx= atom <ContractEvent[] | null> (null);
export const  atomPendingTxWrite= atom(
  null,
  (get, set, update: ContractEvent[] | null) => {
    set(atomPendingTx, update);
  }
)

// for networks subgraph events are not available
export const atomPendingHash= atom <Address | null> (null);
export const  atomPendingHashWrite= atom(
  null,
  (get, set, update: Address | null) => {
    set(atomPendingHash, update);
  }
)


export interface TVL {
  [key: number] : number
}
export const atomChainTVL= atom <TVL> ({});
export const  atomChainTVLWrite= atom(
  null,
  (get, set, update: TVL) => {
    set(atomChainTVL, {...get(atomChainTVL), ...update});
  }
)

//prediction Game
export const atomUserRounds= atom <string[]> ([]);
export const  atomUserRoundsWrite= atom(
  null,
  (get, set, update: string) => {
    set(atomUserRounds, [...get(atomUserRounds), update]);
  }
)

export const atomUserPartipatedRounds= atom <RoundResponse[]> ([]);
export const  atomUserPartipatedRoundsWrite= atom(
  null,
  (get, set, update: RoundResponse[]) => {
    set(atomUserPartipatedRounds, [...update]);
  }
)

export const atomUserBetInfo= atom <BetInfo[]> ([]);
export const  atomUserBetInfoWrite= atom(
  null,
  (get, set, update: BetInfo[]) => {
    set(atomUserBetInfo, update);
  }
)

export const atomWeFiPrice = atom<number>(0.095);

export const atomIsXdcGasSent = atom<null | boolean>(null);

export const atomMenuCollapsed = atom<boolean>(false);