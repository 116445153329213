import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { XDC_ID } from "../constants";
import HowToImage  from '../images/graphics/guide.png';
import FXDSwapImage  from '../images/graphics/fxdswap.png';
import XDCBridgeImage from '../images/graphics/xdcbridge.png';


export function XdcBanner({chainId}: {chainId: number|undefined}) {
  if(chainId === XDC_ID) {
    return <Flex w='100%' direction={{sm: 'column', base: 'column', md: 'row'}} gap='10px'>
       <Box w={{sm: '100%', base: '100%', md: '33%'}}>
        <Link isExternal href="https://medium.com/@wefi_xyz/how-to-guide-for-participating-in-wefi-x-xdc-incentivised-mainnet-program-on-intract-84c26b30d822" target="_blank">
          <Image src={HowToImage}/>
        </Link>
      </Box>
      <Box w={{sm: '100%', base: '100%', md: '33%'}}>
        <Link isExternal href="https://bridge.wanchain.org/" target="_blank">
          <Image src={XDCBridgeImage}/>
        </Link>
      </Box>
      <Box w={{sm: '100%', base: '100%', md: '33%'}}>
        <Link isExternal href="https://v3.xspswap.finance/#/swap" target="_blank">
          <Image src={FXDSwapImage}/>
        </Link>
      </Box>
     
    </Flex>
  } else {
    return null;
  }
}
